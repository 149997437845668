import React, { useEffect, useRef, useState } from "react";
import { FlightModalDestination } from "../style";
import {
      AirlineCode,
      AirlineContent,
      AirlineInfo,
      HotelLocation,
} from "../../../components/form/HotelInput/style";
import axios from "axios";
import toast from "react-hot-toast";
import { FormControl } from "react-bootstrap";
import CheckError from "../../../components/form/CheckError";

const FlightDestinationItem = ({
      destinations,
      inputField,
      filteredField,
      handleChange,
      checkingValue,
}) => {
      const [status, setStatus] = useState(false);
      const [inputValue, setInputValue] = useState("");
      const [destinationList, setDestinationList] = useState([]);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);
      const [errorStatus, setErrorStatus] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const handleTyped = async () => {
            if (inputValue == "") {
                  return;
            }
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations?search=${inputValue}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDestinationList(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            handleTyped();
            setErrorStatus(false);
      }, [inputValue]);

      const currentItem = destinationList?.find(
            (item) => item.code === filteredField
      );

      const handleInputChange = (value) => {
            handleChange(value?.code, inputField);
      };

      useEffect(() => {
            setDestinationList(destinations);
      }, [destinations]);

      useEffect(() => {
            if (checkingValue && filteredField) {
                  if (checkingValue === filteredField) {
                        setErrorStatus(true);
                        setErrorMessage("Please select different to and from");
                  } else {
                        setErrorStatus(false);
                  }
            } else {
                  setErrorStatus(false);
            }
      }, [filteredField]);

      const inputRef = useRef(null);

      const handleClick = () => {
            if (inputRef.current) {
                  inputRef.current.select();
            }
      };

      // Effect to clear or reset input value when dropdown is closed
      useEffect(() => {
            if (!status) {
                  setInputValue(
                        currentItem
                              ? `${currentItem?.city} (${currentItem?.code})`
                              : ""
                  );
            }
      }, [status]);

      return (
            <>
                  <FlightModalDestination
                        padding="10px 15px"
                        border="1px solid #e2e2e2"
                        onClick={() => setStatus(!status)}
                        ref={formGroupRef}
                  >
                        <div>
                              {!errorStatus ? (
                                    <FormControl
                                          type="text"
                                          ref={inputRef}
                                          onChange={(event) =>
                                                setInputValue(
                                                      event.target.value
                                                )
                                          }
                                          onClick={handleClick}
                                          value={
                                                inputValue ||
                                                (currentItem &&
                                                      `${currentItem?.city} (${currentItem?.code})`)
                                          }
                                    />
                              ) : (
                                    <CheckError
                                          errorMessage={errorMessage}
                                          errorStatus={errorStatus}
                                    >
                                          <FormControl
                                                type="text"
                                                ref={inputRef}
                                                onChange={(event) =>
                                                      setInputValue(
                                                            event.target.value
                                                      )
                                                }
                                                onClick={handleClick}
                                                value={
                                                      inputValue ||
                                                      (currentItem &&
                                                            `${currentItem?.city} (${currentItem?.code})`)
                                                }
                                          />
                                    </CheckError>
                              )}
                        </div>
                        {status && destinationList?.length > 0 && (
                              <HotelLocation width="320px" ref={locationRef}>
                                    <ul>
                                          {destinationList.map(
                                                (item, index) => (
                                                      <li
                                                            onClick={() =>
                                                                  handleInputChange(
                                                                        item
                                                                  )
                                                            }
                                                            key={index}
                                                            className={
                                                                  currentItem?.city ===
                                                                  item?.city
                                                                        ? "active"
                                                                        : ""
                                                            }
                                                      >
                                                            <AirlineContent>
                                                                  <AirlineInfo>
                                                                        <div className="airline__destination">
                                                                              {
                                                                                    item.city
                                                                              }
                                                                              {
                                                                                    ", "
                                                                              }
                                                                              {
                                                                                    item.country
                                                                              }
                                                                        </div>
                                                                  </AirlineInfo>
                                                                  <AirlineCode>
                                                                        {
                                                                              item.code
                                                                        }
                                                                  </AirlineCode>
                                                            </AirlineContent>
                                                            <div className="airline__name text-muted">
                                                                  {item.name}
                                                            </div>
                                                      </li>
                                                )
                                          )}
                                    </ul>
                              </HotelLocation>
                        )}
                  </FlightModalDestination>
            </>
      );
};

export default FlightDestinationItem;
