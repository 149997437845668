import React from "react";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { TravellersDetail } from "../TravellersDetailBox/styles";
import {
      FlightDetails,
      TravellerAccordionForm,
} from "../../../../frontend/checkout/styles";
import Select from "react-select";
import FrequentForm from "../FrequentForm";
import { countryList, inFantList, titleList } from "../../../../data/dashboard";
import { format } from "date-fns";

const TravellerDetailList = ({
      travellers,
      handleInputChange,
      handleTitle,
      searchQuery,
      marginBottom,
}) => {
      const checkTitle = (title) => {
            if (title === "CHILD" || title === "INFANT") {
                  return inFantList;
            } else {
                  return titleList;
            }
      };

      const checkYear = (title) => {
            let limit;
            if (title === "CHILD") {
                  limit = 12;
            } else if (title === "INFANT") {
                  limit = 2;
            }
            const currentYear = new Date().getFullYear();
            return `${currentYear - limit}-01-01`;
      };

      return (
            <>
                  {travellers?.map((item, index) => (
                        <>
                              <FlightDetails style={{ marginBottom }}>
                                    <TravellersDetail>
                                          <div className="travellers__heading">
                                                <div className="travellers__title">
                                                      {item?.ptName}:{" "}
                                                      <span>
                                                            {item?.pt ===
                                                            "ADULT" ? (
                                                                  <>
                                                                        {travellers[
                                                                              index
                                                                        ]
                                                                              ?.title ||
                                                                        travellers[
                                                                              index
                                                                        ]
                                                                              ?.firstName ||
                                                                        travellers[
                                                                              index
                                                                        ]
                                                                              ?.lastName
                                                                              ? `${travellers[index]?.title} ${travellers[index]?.firstName} ${travellers[index]?.lastName}`
                                                                              : `(12+ yrs)`}
                                                                  </>
                                                            ) : item?.pt ===
                                                              "CHILD" ? (
                                                                  <>
                                                                        {travellers[
                                                                              index
                                                                        ]
                                                                              ?.title ||
                                                                        travellers[
                                                                              index
                                                                        ]
                                                                              ?.firstName ||
                                                                        travellers[
                                                                              index
                                                                        ]
                                                                              ?.lastName
                                                                              ? `${travellers[index]?.title} ${travellers[index]?.firstName} ${travellers[index]?.lastName}`
                                                                              : `(2 - 12 yrs)`}
                                                                  </>
                                                            ) : (
                                                                  item?.pt ===
                                                                        "INFANT" && (
                                                                        <>
                                                                              {travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.title ||
                                                                              travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.firstName ||
                                                                              travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.lastName
                                                                                    ? `${travellers[index]?.title} ${travellers[index]?.firstName} ${travellers[index]?.lastName}`
                                                                                    : `(0 - 2yrs)`}
                                                                        </>
                                                                  )
                                                            )}
                                                      </span>
                                                </div>
                                                <div className="travellers__Detail">
                                                      <FormControl placeholder="Search travellers by name/number" />
                                                </div>
                                          </div>
                                    </TravellersDetail>

                                    <TravellerAccordionForm padding="0">
                                          <Row className="g-3">
                                                <Col lg={2}>
                                                      <FormLabel>
                                                            Title
                                                      </FormLabel>
                                                      <Select
                                                            required={true}
                                                            name="title"
                                                            options={checkTitle(
                                                                  item?.pt
                                                            )}
                                                            onChange={(
                                                                  value,
                                                                  event
                                                            ) =>
                                                                  handleTitle(
                                                                        value.value,
                                                                        "title",
                                                                        index,
                                                                        event
                                                                  )
                                                            }
                                                            value={checkTitle(
                                                                  item?.pt
                                                            )?.find(
                                                                  (option) =>
                                                                        option.value ===
                                                                        travellers[
                                                                              index
                                                                        ]?.title
                                                            )}
                                                      />
                                                </Col>
                                                <Col lg={5}>
                                                      <FormLabel>
                                                            First Name
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            name="firstName"
                                                            onChange={(event) =>
                                                                  handleInputChange(
                                                                        index,
                                                                        event,
                                                                        "",
                                                                        "",
                                                                        ""
                                                                  )
                                                            }
                                                            required={true}
                                                            value={
                                                                  travellers[
                                                                        index
                                                                  ]?.firstName
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={5}>
                                                      <FormLabel>
                                                            Last Name
                                                      </FormLabel>
                                                      <FormControl
                                                            name="lastName"
                                                            onChange={(event) =>
                                                                  handleInputChange(
                                                                        index,
                                                                        event,
                                                                        "",
                                                                        "",
                                                                        ""
                                                                  )
                                                            }
                                                            required={true}
                                                            value={
                                                                  travellers[
                                                                        index
                                                                  ]?.lastName
                                                            }
                                                      />
                                                </Col>
                                                {(travellers[index]?.pt ===
                                                      "CHILD" ||
                                                      travellers[index]?.pt ===
                                                            "INFANT") && (
                                                      <Col lg={5}>
                                                            <FormLabel>
                                                                  Date of Birth
                                                            </FormLabel>
                                                            <FormControl
                                                                  type="date"
                                                                  required={
                                                                        true
                                                                  }
                                                                  name="dateOfBirth"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        handleInputChange(
                                                                              index,
                                                                              event,
                                                                              "",
                                                                              "",
                                                                              ""
                                                                        )
                                                                  }
                                                                  min={checkYear(
                                                                        travellers[
                                                                              index
                                                                        ]?.pt
                                                                  )}
                                                                  max={format(
                                                                        new Date(),
                                                                        "yyyy-MM-dd"
                                                                  )}
                                                                  value={
                                                                        travellers[
                                                                              index
                                                                        ]
                                                                              ?.dateOfBirth
                                                                  }
                                                            />
                                                      </Col>
                                                )}
                                                {travellers[index]?.pt ===
                                                      "ADULT" && (
                                                      <Col lg={5}>
                                                            <FormLabel>
                                                                  Nationality
                                                            </FormLabel>
                                                            <Select
                                                                  options={
                                                                        countryList
                                                                  }
                                                                  name="nationality"
                                                                  required={
                                                                        true
                                                                  }
                                                                  onChange={(
                                                                        value,
                                                                        event
                                                                  ) =>
                                                                        handleTitle(
                                                                              value.value,
                                                                              "nationality",
                                                                              index,
                                                                              event
                                                                        )
                                                                  }
                                                                  value={countryList?.find(
                                                                        (
                                                                              option
                                                                        ) =>
                                                                              option.value ===
                                                                              travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.nationality
                                                                  )}
                                                            />
                                                      </Col>
                                                )}
                                                {!searchQuery?.isDomestic && (
                                                      <>
                                                            <Col lg={4}>
                                                                  <FormLabel>
                                                                        Passport
                                                                        Number
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        type="text"
                                                                        name="passportNumber"
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              handleInputChange(
                                                                                    index,
                                                                                    event,
                                                                                    "",
                                                                                    "",
                                                                                    ""
                                                                              )
                                                                        }
                                                                        value={
                                                                              travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.passportNumber
                                                                        }
                                                                  />
                                                            </Col>
                                                            <Col lg={4}>
                                                                  <FormLabel>
                                                                        Passport
                                                                        Expiry
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        type="date"
                                                                        name="passportExpiry"
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              handleInputChange(
                                                                                    index,
                                                                                    event,
                                                                                    "",
                                                                                    "",
                                                                                    ""
                                                                              )
                                                                        }
                                                                        value={
                                                                              travellers[
                                                                                    index
                                                                              ]
                                                                                    ?.passportExpiry
                                                                        }
                                                                  />
                                                            </Col>
                                                      </>
                                                )}
                                                <FrequentForm
                                                      handleInputChange={
                                                            handleInputChange
                                                      }
                                                      travellers={travellers}
                                                      index={index}
                                                />
                                          </Row>
                                    </TravellerAccordionForm>
                              </FlightDetails>
                        </>
                  ))}
            </>
      );
};

export default TravellerDetailList;
