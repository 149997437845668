import styled from "styled-components";

export const FormWrapper = styled.div`
      position: relative;
      width: ${(props) => props.width};
      margin-right: ${(props) => (props.padding ? props.padding : "25px")};
      margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};

      @media screen and (max-width: 600px) {
            width: 50%;

            &:nth-child(even) {
                  padding-right: 0;
            }
      }
      label {
            font-size: 14px;
            font-weight: 600;
      }

      input {
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            border: 0;
            border: ${(props) => (props.border ? props.border : "")};
            background-color: ${(props) =>
                  props.backgroundNone ? "transparent" : "#f0f0f0"};
            border-radius: 8px;
            padding: ${(props) =>
                  props.inputPadding ? props.inputPadding : "0.8rem 1rem"};
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 0px 1px;

            &::placeholder {
                  font-size: 13px;
                  font-weight: 400;
                  color: ${(props) => props.theme.textColor};
            }
            &:disabled {
                  background: ${(props) => props.theme.disableColor} !important;
                  color: #7c7f82;
            }
      }
`;
export const FormLabel = styled.div``;

export const FormIcon = styled.div`
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(9%);
`;
