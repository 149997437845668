import React, { useState } from "react";

import {
      LoginAction,
      LoginButton,
      LoginButtonContainer,
      LoginFormContainer,
} from "./styles";
import SignupForm from "./SignupForm";
import LoginForm from "./LoginForm";
import AuthLayout from "./AuthLayout";

const Login = () => {
      const [activeForm, setActiveForm] = useState("login");

      const handleRegister = () => {
            setActiveForm("register");
      };

      const handleLogin = (value) => {
            setActiveForm(value);
      };

      return (
            <>
                  <AuthLayout>
                        <LoginAction>
                              <LoginButtonContainer activeForm={activeForm}>
                                    <LoginButton
                                          onClick={() => handleLogin("login")}
                                          className="p-3"
                                    >
                                          Login
                                    </LoginButton>
                                    <LoginButton
                                          onClick={handleRegister}
                                          className="p-3"
                                    >
                                          Sign Up
                                    </LoginButton>
                              </LoginButtonContainer>
                        </LoginAction>
                        <LoginFormContainer>
                              <LoginForm
                                    activeForm={activeForm}
                                    handleLogin={handleLogin}
                              />
                              <SignupForm activeForm={activeForm} />
                        </LoginFormContainer>
                  </AuthLayout>
            </>
      );
};

export default Login;
