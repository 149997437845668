import styled from "styled-components";

export const TravellerModal = styled.div`
      color: ${(props) => props.theme.primary};
      text-decoration: underline;
      transition: all 0.3s ease-in;
      cursor: pointer;
      font-size: ${(props) => props.fontSize};

      &:hover {
            text-decoration: none;
      }
`;
