import styled from "styled-components";

export const FlightPriceFilterContainer = styled.div`
      border-top: 1px dashed ${(props) => props.theme.primary};
      margin-bottom: 20px;
      padding-top: 15px;
`;

export const FlightPriceFilterContent = styled.div`
      display: flex;
      align-items: center;
      gap: 20px;
`;

export const FlightPriceFilterItem = styled.div`
      background-color: ${(props) => props.theme.white};
      padding: 7px 14px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active {
            border-bottom: 2px solid ${(props) => props.theme.primary};
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
            cursor: auto;

            .price__filter-icon {
                  color: ${(props) => props.theme.primary};

                  svg {
                        fill: ${(props) => props.theme.primary};
                  }
            }
      }

      &:hover {
            box-shadow: ${(props) => props.theme.primary} 0px 0px 0px 1px;
      }

      .price__filter-icon {
            font-size: 32px;
            line-height: 1;
      }

      .price__filter-duration {
            font-size: 13px;
      }

      .price__filter-title {
            font-size: 14px;
            font-weight: 500;
      }
`;
