import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import {
      AccountInfoIcon,
      ContactInfoIcon,
      PersonalInfoIcon,
      UserIcon,
} from "../../icons";
import { Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { AccountItem, AccountLink } from "./style";
import axios from "axios";
import AccountInfoItem from "./AccountInfoItem";
import { AccountContainer } from "./AccountInfoItem/style";
import ProfileUpload from "./ProfileUpload";

const MyAccount = () => {
      const [loading, setLoading] = useState(false);
      const [profile, setProfile] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProfile(response.data.agent);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardLayout title="My Account">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <UserIcon />
                                          </MenuIcon>
                                          My Account
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="20px 10px">
                                    <Tab.Container
                                          id="left-tabs-example"
                                          defaultActiveKey="first"
                                    >
                                          <Row>
                                                <Col sm={3}>
                                                      <Nav
                                                            variant="pills"
                                                            className="flex-column"
                                                      >
                                                            <AccountItem>
                                                                  <AccountLink eventKey="first">
                                                                        <AccountInfoIcon />
                                                                        Account
                                                                        Information
                                                                  </AccountLink>
                                                            </AccountItem>
                                                            <AccountItem>
                                                                  <AccountLink eventKey="second">
                                                                        <PersonalInfoIcon />
                                                                        Personal
                                                                        Information
                                                                  </AccountLink>
                                                            </AccountItem>
                                                            <AccountItem>
                                                                  <AccountLink eventKey="contact">
                                                                        <ContactInfoIcon />
                                                                        Contact
                                                                        Information
                                                                  </AccountLink>
                                                            </AccountItem>
                                                      </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                      <Tab.Content>
                                                            <Tab.Pane eventKey="first">
                                                                  {!loading ? (
                                                                        <>
                                                                              <AccountContainer>
                                                                                    <AccountInfoItem
                                                                                          title="Company Name"
                                                                                          value={
                                                                                                profile
                                                                                                      ?.company
                                                                                                      ?.company_name
                                                                                          }
                                                                                    />
                                                                                    <AccountInfoItem
                                                                                          title="User Name"
                                                                                          value={
                                                                                                profile
                                                                                                      ?.personal
                                                                                                      ?.user_name
                                                                                          }
                                                                                    />
                                                                              </AccountContainer>
                                                                              <ProfileUpload />
                                                                        </>
                                                                  ) : (
                                                                        <div className="py-5 text-center">
                                                                              <Spinner />
                                                                        </div>
                                                                  )}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="second">
                                                                  <AccountContainer>
                                                                        <AccountInfoItem
                                                                              title="First Name"
                                                                              value={
                                                                                    profile
                                                                                          ?.personal
                                                                                          ?.first_name
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="Last Name"
                                                                              value={
                                                                                    profile
                                                                                          ?.personal
                                                                                          ?.last_name
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="PAN (Permanent Account Number)"
                                                                              value={
                                                                                    profile
                                                                                          ?.company
                                                                                          ?.pan_no
                                                                              }
                                                                        />
                                                                  </AccountContainer>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="contact">
                                                                  <AccountContainer>
                                                                        <AccountInfoItem
                                                                              title="Address"
                                                                              value={
                                                                                    profile
                                                                                          ?.company
                                                                                          ?.office_address
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="City"
                                                                              value={
                                                                                    profile
                                                                                          ?.company
                                                                                          ?.city
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="State"
                                                                              value={
                                                                                    profile
                                                                                          ?.company
                                                                                          ?.state
                                                                              }
                                                                        />
                                                                  </AccountContainer>
                                                                  <AccountContainer>
                                                                        <AccountInfoItem
                                                                              title="Email"
                                                                              value={
                                                                                    profile
                                                                                          ?.personal
                                                                                          ?.email
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="TEL. No."
                                                                              value={
                                                                                    profile
                                                                                          ?.personal
                                                                                          ?.phone_number
                                                                              }
                                                                        />
                                                                        <AccountInfoItem
                                                                              title="Mobile No."
                                                                              value={
                                                                                    profile
                                                                                          ?.personal
                                                                                          ?.mobile_number ||
                                                                                    "-"
                                                                              }
                                                                        />
                                                                  </AccountContainer>{" "}
                                                                  <AccountContainer>
                                                                        <AccountInfoItem
                                                                              title="PIN Code"
                                                                              value={
                                                                                    profile?.pincode
                                                                              }
                                                                        />
                                                                  </AccountContainer>
                                                            </Tab.Pane>
                                                      </Tab.Content>
                                                </Col>
                                          </Row>
                                    </Tab.Container>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default MyAccount;
