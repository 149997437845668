import { Link } from "react-router-dom";
import styled from "styled-components";

export const PackageCard = styled(Link)``;

export const PackageWrapper = styled.div`
      /* border: 1px solid #dee2e6; */
      padding: 16px;
      display: block;
      max-width: 100%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
            rgba(0, 0, 0, 0.23) 0px 3px 6px;
      background-color: ${(props) => props.theme.white};

      &:hover {
            img {
                  transform: scale(1.2);
            }
      }
`;
export const PackageImage = styled.div`
      overflow: hidden;
      height: 180px;

      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.1);
            transition: transform 0.3s linear 0s;
      }
`;
export const PackageContent = styled.div`
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      color: ${(props) => props.theme.textColor};
`;

export const PackageTitle = styled.h3`
      font-size: ${(props) => props.fontSize || "20px"};
      text-transform: capitalize;
      font-weight: 400;
      margin: 0;
      transition: all 0.4s ease-in-out;

      &:hover {
            color: ${(props) => props.theme.primary};
      }
`;

export const PackageText = styled.p`
      margin-bottom: 0;
      font-size: ${(props) => props.fontSize || "20px"};
      font-weight: 400;
`;

export const SliderTitle = styled.div`
      background-color: ${(props) => props.theme.primary};
      color: white; /* White text */
      padding: 8px 20px;
      font-size: 16px;
      position: relative;
      z-index: 9;
      margin-bottom: 3rem;
      width: fit-content;

      &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -20px;
            top: auto;
            transform: rotate(0deg);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 40px solid #243d7c;
            z-index: -1;
      }
`;
