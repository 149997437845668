import React from "react";
import { CommonButtonContainer } from "../CommonButton/styles";
import { Button, Spinner } from "react-bootstrap";

const CustomButton = ({
      type,
      title,
      display,
      marginTop,
      marginBottom,
      padding,
      fontSize,
      width,
      loading,
}) => {
      return (
            <>
                  <CommonButtonContainer
                        style={{ marginTop, marginBottom, padding, fontSize }}
                        width={width}
                  >
                        <Button
                              type={type}
                              className="custom-button"
                              style={{ display, fontSize, padding }}
                        >
                              {loading ? <Spinner /> : title}
                        </Button>
                  </CommonButtonContainer>
            </>
      );
};

export default CustomButton;
