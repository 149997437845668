import React, { useEffect, useState } from "react";
import { DashboardCardBody } from "../../layouts/style";
import { Col, FormControl, FormLabel, Row, Spinner } from "react-bootstrap";
import { FilterButton } from "../style";
import axios from "axios";
import { Form } from "react-bootstrap";

const DepositFilter = ({ setDeposits, setLoading, type }) => {
      const [loadingData, setLoadingData] = useState(false);
      const [startingDate, setStartingDate] = useState(new Date());
      const [endingDate, setEndingDate] = useState(new Date());

      const convertDate = (dateStr) => {
            const date = new Date(dateStr);

            const formattedDate = date.toISOString().split("T")[0];
            return formattedDate;
      };

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/${type}?startingDate=${startingDate}&endingDate=${endingDate}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setDeposits(response.data.deposits);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            setLoadingData(true);
            await axios
                  .get(
                        `${
                              process.env.REACT_APP_SECRET_KEY
                        }/api/agent/${type}?startingDate=${convertDate(
                              startingDate
                        )}&endingDate=${convertDate(endingDate)}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setDeposits(response.data.deposits);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
            setLoadingData(false);
      };

      return (
            <>
                  <DashboardCardBody
                        padding="0px"
                        margin="0px 10px 20px"
                        width="auto"
                        className="rounded"
                  >
                        <Form onSubmit={handleForm}>
                              <Row className="g-3 align-items-center">
                                    <Col lg={3}>
                                          <FormLabel>START DATE:</FormLabel>
                                          <FormControl
                                                type="date"
                                                onChange={(event) =>
                                                      setStartingDate(
                                                            event.target.value
                                                      )
                                                }
                                                value={convertDate(
                                                      startingDate
                                                )}
                                          />
                                    </Col>
                                    <Col lg={3}>
                                          <FormLabel>END DATE:</FormLabel>
                                          <FormControl
                                                type="date"
                                                onChange={(event) =>
                                                      setEndingDate(
                                                            event.target.value
                                                      )
                                                }
                                                value={convertDate(endingDate)}
                                          />
                                    </Col>
                                    <Col lg={3} className="text-start">
                                          <FilterButton
                                                type="submit"
                                                className="mt-4"
                                          >
                                                {loadingData ? (
                                                      <Spinner />
                                                ) : (
                                                      "Search"
                                                )}
                                          </FilterButton>
                                    </Col>
                              </Row>
                        </Form>
                  </DashboardCardBody>
            </>
      );
};

export default DepositFilter;
