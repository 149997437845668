import React, { useEffect, useRef, useState } from "react";
import {
      FlightModalItem,
      FlightModalOption,
      FlightModalOptions,
} from "../style";
import { FormControl } from "react-bootstrap";
import CheckTooltip from "../../../components/form/CheckTooltip";

const options = [
      {
            title: "Regular",
            value: "REGULAR",
            message: "",
      },
      {
            title: "Student",
            value: "STUDENT",
            message: "Only students above 12 years of age are eligible for special fares and/or additional baggage allowances. Carrying valid student ID cards and student visas (where applicable) is mandatory, else the passenger may be denied boarding or asked to pay for extra baggage.",
      },
      {
            title: "Senior Citizen",
            value: "SENIOR_CITIZEN",
            message: "Only senior citizens above the age of 60 years can avail this special fare. It is mandatory to produce proof of Date of Birth at the airport, without which prevailing fares will be charged.",
      },
];

const FlightModalType = ({ searchDetail, handleChange }) => {
      const [status, setStatus] = useState(false);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const handleInputChange = (value) => {
            setStatus(false);
            handleChange(value, "pft");
      };

      const checkValue = () => {
            return options?.find((item) => item.value === searchDetail?.pft);
      };

      return (
            <>
                  <FlightModalItem
                        width="12%"
                        onClick={() => setStatus(!status)}
                  >
                        <FlightModalOptions ref={formGroupRef}>
                              <div>
                                    <FormControl
                                          type="text"
                                          name="returnStatus"
                                          value={
                                                checkValue()
                                                      ? checkValue().title
                                                      : "Regular"
                                          }
                                          readOnly
                                    />
                              </div>
                              {status && (
                                    <FlightModalOption ref={locationRef}>
                                          {options?.map((item, index) =>
                                                item.message ? (
                                                      <CheckTooltip
                                                            placement="left"
                                                            errorMessage={
                                                                  item.message
                                                            }
                                                      >
                                                            <li
                                                                  className={
                                                                        item.value ===
                                                                        searchDetail?.pft
                                                                              ? "selected"
                                                                              : ""
                                                                  }
                                                                  key={index}
                                                                  onClick={() =>
                                                                        handleInputChange(
                                                                              item?.value
                                                                        )
                                                                  }
                                                            >
                                                                  <div>
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </div>
                                                            </li>
                                                      </CheckTooltip>
                                                ) : (
                                                      <>
                                                            <li
                                                                  className={
                                                                        item.value ===
                                                                        searchDetail?.pft
                                                                              ? "selected"
                                                                              : ""
                                                                  }
                                                                  key={index}
                                                                  onClick={() =>
                                                                        handleInputChange(
                                                                              item?.value
                                                                        )
                                                                  }
                                                            >
                                                                  {item.title}
                                                            </li>
                                                      </>
                                                )
                                          )}
                                    </FlightModalOption>
                              )}
                        </FlightModalOptions>
                  </FlightModalItem>
            </>
      );
};

export default FlightModalType;
