import { Button } from "react-bootstrap";
import styled from "styled-components";

export const FlightPriceContainer = styled.div`
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      padding-top: 16px;
      align-items: center;
      border-top: 1px dashed ${(props) => props.theme.primary};
      border-bottom: ${(props) =>
            props.returnStatus ? "1px dashed" + props.theme.primary : ""};
      padding-bottom: ${(props) => (props.returnStatus ? "16px" : "")};
`;

export const FlightPriceGrid = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      width: 100%;
`;

export const FlightPriceContent = styled.div`
      display: flex;
      gap: 10px;
      align-items: ${(props) => props.alignItems || "center"};
      width: ${(props) => props.width};

      &::-webkit-scrollbar {
            display: none;
      }

      button {
            font-size: 10px;
            padding: 0;
            height: auto;
            background-color: transparent;
            border: 1px solid ${(props) => props.theme.primary};
            min-width: max-content;
            margin-bottom: 5px;
            padding: 2.5px 5px;
            color: ${(props) => props.theme.primary};
            border-radius: 5px;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;

            &.active,
            &:hover {
                  background-color: ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const FlightPriceItem = styled.div`
      background-color: #f5f5f5;
      padding: 2px 10px;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      scroll-snap-align: start;
      flex-shrink: 0;
      padding-bottom: 5px;

      &.active {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primary};
      }

      .price {
            font-weight: 600;
            margin-bottom: 1px;

            span {
                  font-size: 12px;
            }
      }

      .summary {
            font-size: 9px;
            display: flex;
            align-items: center;
            gap: 10px;
      }

      input {
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: -1;
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
      }
`;

export const FlightDetailButton = styled(Button)`
      font-size: ${(props) => props.fontSize || "14px"};
      color: #224082;
      text-transform: uppercase;
      min-width: fit-content;
      text-align: center;

      &.active,
      &:hover {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
      }
`;
