import React, { createContext, useState } from "react";

export const FormContext = createContext();

const FormProvider = ({ children }) => {
      const [formData, setFormData] = useState({
            country: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
      });
      
      return (
            <>
                  <FormContext.Provider value={{ formData, setFormData }}>
                        {children}
                  </FormContext.Provider>
            </>
      );
};

export default FormProvider;
