import React, { useEffect, useRef, useState } from "react";
import {
      FlightModalItem,
      FlightModalOption,
      FlightModalOptions,
} from "../style";
import { FormControl } from "react-bootstrap";
import { addDays, format } from "date-fns";

const options = [
      {
            title: "One Way",
            value: "false",
      },
      {
            title: "Round Trip",
            value: "true",
      },
];

const FlightModalItems = ({ searchDetail, handleChange }) => {
      const [status, setStatus] = useState(false);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const newDate = addDays(searchDetail?.departDate, 1);

      const convertNumberDate = (date) => {
            return format(date, "yyyy-MM-dd");
      };

      const handleInputChange = (value) => {
            setStatus(false);
            handleChange(value, "returnStatus");
            if (value == "true") {
                  handleChange(convertNumberDate(newDate), "returnDate");
            } else {
                  handleChange("", "returnDate");
            }
      };

      return (
            <>
                  <FlightModalItem
                        width="10%"
                        onClick={() => setStatus(!status)}
                  >
                        <FlightModalOptions ref={formGroupRef}>
                              <div>
                                    <FormControl
                                          type="text"
                                          name="returnStatus"
                                          value={
                                                searchDetail?.returnStatus ===
                                                "false"
                                                      ? "One Way"
                                                      : "Round Way"
                                          }
                                          readOnly
                                    />
                              </div>

                              {status && (
                                    <FlightModalOption ref={locationRef}>
                                          {options?.map((item, index) => (
                                                <li
                                                      className={
                                                            item.value ===
                                                            searchDetail?.returnStatus
                                                                  ? "selected"
                                                                  : ""
                                                      }
                                                      key={index}
                                                      onClick={() =>
                                                            handleInputChange(
                                                                  item?.value
                                                            )
                                                      }
                                                >
                                                      {item.title}
                                                </li>
                                          ))}
                                    </FlightModalOption>
                              )}
                        </FlightModalOptions>
                  </FlightModalItem>
            </>
      );
};

export default FlightModalItems;
