import React, { useState } from "react";
import { TravellerModal } from "../../../flight_reviews/FlightReviewDetail/TravellerReviewDetail/style";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { FlightReviewTable } from "../../../flight_reviews/FlightReviewDetail/style";
import {
      BaggageIcon,
      MealIcon,
      SeatIcon,
      ServiceIcon,
} from "../../../../../icons";
import PriceAddItem from "./PriceAddItem";
import { FlightModal } from "../PriceFlightModal/style";

const PriceAddView = () => {
      const [show, setShow] = useState(false);
      const travellerDetails = JSON.parse(localStorage.getItem("travellers"));
      const services = JSON.parse(localStorage.getItem("services"));

      const findTraveller = (ptName) => {
            return travellerDetails?.find((item) => item.ptName === ptName);
      };

      return (
            <>
                  <TravellerModal onClick={() => setShow(true)}>
                        View Details
                  </TravellerModal>
                  <FlightModal
                        top="18px"
                        show={show}
                        size="lg"
                        onHide={() => setShow(false)}
                  >
                        <ModalHeader closeButton>Add Ons</ModalHeader>
                        <ModalBody>
                              {travellerDetails?.length > 0 &&
                                    travellerDetails?.map((item, index) => (
                                          <FlightReviewTable
                                                border={false}
                                                listStyle="auto"
                                                key={index}
                                                className="pt-0 mt-0 pb-0 mb-0"
                                          >
                                                <ul className="w-100">
                                                      {Object.keys(
                                                            services
                                                      )?.map((service) => (
                                                            <>
                                                                  <li className="w-100">
                                                                        <div>
                                                                              {`${
                                                                                    findTraveller(
                                                                                          item?.ptName
                                                                                    )
                                                                                          ?.firstName
                                                                              } ${
                                                                                    findTraveller(
                                                                                          item?.ptName
                                                                                    )
                                                                                          ?.lastName
                                                                              }`}
                                                                        </div>
                                                                        <PriceAddItem
                                                                              title={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Meal
                                                                                          ?.name
                                                                              }
                                                                              amount={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Meal
                                                                                          ?.amount
                                                                              }
                                                                              service={
                                                                                    service
                                                                              }
                                                                              icon={
                                                                                    <MealIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                              }
                                                                        />

                                                                        <PriceAddItem
                                                                              title={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Baggage
                                                                                          ?.name
                                                                              }
                                                                              amount={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Baggage
                                                                                          ?.amount
                                                                              }
                                                                              service={
                                                                                    service
                                                                              }
                                                                              icon={
                                                                                    <BaggageIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                              }
                                                                        />

                                                                        <PriceAddItem
                                                                              title={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.OtherService
                                                                                          ?.name
                                                                              }
                                                                              amount={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.OtherService
                                                                                          ?.amount
                                                                              }
                                                                              service={
                                                                                    service
                                                                              }
                                                                              icon={
                                                                                    <ServiceIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                              }
                                                                        />

                                                                        <PriceAddItem
                                                                              title={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Seat
                                                                                          ?.name
                                                                              }
                                                                              amount={
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Seat
                                                                                          ?.amount
                                                                              }
                                                                              service={
                                                                                    service
                                                                              }
                                                                              icon={
                                                                                    <SeatIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                              }
                                                                        />
                                                                  </li>
                                                            </>
                                                      ))}
                                                </ul>
                                          </FlightReviewTable>
                                    ))}
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default PriceAddView;
