import React, { useEffect, useRef, useState } from "react";
import { FlightModalDestination, FlightModalItem } from "../style";
import {
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
} from "../../../components/Frontend/home/TravelerBox/styles";
import CustomClass from "../../../components/custom/CustomClass";

const options = [
      { value: "ECONOMY", label: "Economy" },
      { value: "PREMIUM_ECONOMY", label: "Premium Economy" },
      { value: "BUSINESS", label: "Business" },
      { value: "FIRST", label: "First" },
];

const FlightModalTraveller = ({ searchDetail, handleChange }) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();

      const totalTraveller = () => {
            return (
                  parseInt(searchDetail?.adult) +
                  parseInt(searchDetail?.children) +
                  parseInt(searchDetail?.infants)
            );
      };

      const handleClick = () => {
            setShow(true);
      };

      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutside);

            return () => {
                  document.removeEventListener("click", handleOutside);
            };
      }, []);

      const increment = (key) => {
            if (key === "infants") {
                  if (
                        parseInt(searchDetail?.infants) <
                        parseInt(searchDetail?.adult)
                  ) {
                        handleChange(
                              parseInt(searchDetail?.infants) + 1,
                              "infants"
                        );
                  }
            } else if (key === "adults") {
                  if (parseInt(searchDetail?.adult) >= 1) {
                        handleChange(
                              parseInt(searchDetail?.adult) + 1,
                              "adult"
                        );
                  }
            } else {
                  if (
                        parseInt(searchDetail?.children) <
                        parseInt(searchDetail?.adult) * 2
                  ) {
                        handleChange(
                              parseInt(searchDetail?.children) + 1,
                              "children"
                        );
                  }
            }
      };

      const decrement = (key) => {
            if (key === "adults") {
                  if (parseInt(searchDetail?.adult) > 1) {
                        handleChange(
                              parseInt(searchDetail?.adult) - 1,
                              "adult"
                        );
                  }
            } else if (key === "children") {
                  if (parseInt(searchDetail?.children) > 0) {
                        handleChange(
                              parseInt(searchDetail?.children) - 1,
                              "children"
                        );
                  }
            } else {
                  if (
                        parseInt(searchDetail?.infants) >=
                        parseInt(searchDetail?.adult)
                  ) {
                        handleChange(
                              parseInt(searchDetail?.infants) - 1,
                              "infants"
                        );
                  }
            }
      };

      const currentItem = options?.find(
            (item) => item?.value === searchDetail?.cabinClass
      );

      return (
            <>
                  <FlightModalItem
                        width="12%"
                        onClick={handleClick}
                        ref={divRef}
                  >
                        <FlightModalDestination width="15%">
                              {totalTraveller()}
                        </FlightModalDestination>
                        <FlightModalDestination width="10%">
                              |
                        </FlightModalDestination>
                        <FlightModalDestination width="75%">
                              <div>{currentItem?.label}</div>
                        </FlightModalDestination>

                        <FormAdditional
                              top="55px"
                              left="0"
                              className={show ? "show" : ""}
                        >
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Adults
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                Above 12 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("adults")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {parseInt(searchDetail?.adult)}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("adults")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Children
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                2 - 12 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("children")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {parseInt(
                                                      searchDetail?.children
                                                )}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("children")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Infants
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                Below 2 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("infants")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {searchDetail?.infants}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("infants")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <CustomClass
                                          options={options}
                                          searchDetail={searchDetail}
                                          handleChange={handleChange}
                                    />
                              </FormAdditionalHeader>
                        </FormAdditional>
                  </FlightModalItem>
            </>
      );
};

export default FlightModalTraveller;
