import styled from "styled-components";

export const MenuIcon = styled.div`
      padding: 2.5px;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: rgba(255, 255, 255, 0.1); */
`;

export const MenuButton = styled.button`
      .menu__button-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 54px);
            padding-right: 10px;

            .spinner-border {
                  height: 1rem !important;
                  width: 1rem !important;
                  border-width: 1px !important;
            }
      }
`;
