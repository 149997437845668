import React from "react";
import PDF417 from "pdf417-generator";

import { FlightTable, ThirdContent } from "../style";
import { format } from "date-fns";

const TicketPassengerDetail = ({ order, travellerInfos, tripInfos }) => {
      const checkBaggage = (index) => {
            return tripInfos[0]?.sI[index]?.bI?.tI[index]?.fd?.bI;
      };

      const barCode = (value, index) => {
            const canvases = document.querySelectorAll(".barcode" + index); // Select all canvases with class "barcode"

            canvases.forEach((canvas) => {
                  if (canvas) {
                        PDF417.draw(value, canvas);
                  }
            });
      };

      const checkPassengerType = (value) => {
            if (value === "ADULT") {
                  return "Adult";
            } else if (value === "CHILD") {
                  return "Child";
            } else if (value === "INFANT") {
                  return "Infant";
            }
      };

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "dd MMM yyyy");
      };

      return (
            <>
                  <div className="card-third mb-3">
                        <div className="card-body">
                              <p className="third-title mb-1">
                                    <strong>Passenger Details (</strong> Phone:{" "}
                                    {order?.deliveryInfo?.contacts} &nbsp;
                                    &nbsp; &nbsp; &nbsp; Email:{" "}
                                    {order?.deliveryInfo?.emails}
                                    <strong>)</strong>{" "}
                              </p>
                              <div className="table-header">
                                    <FlightTable
                                          length={travellerInfos?.length}
                                          className="table"
                                    >
                                          <thead className="header-third">
                                                <tr>
                                                      <th className="text-muted">
                                                            Ticket No.
                                                      </th>
                                                      <th className="xyz justify-content-start text-muted">
                                                            Passenger / Baggage
                                                            Details
                                                      </th>
                                                      <th className="text-muted">
                                                            Status
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {travellerInfos?.map(
                                                      (item, index) => (
                                                            <tr key={index}>
                                                                  <td>
                                                                        <div>
                                                                              {tripInfos[0]?.sI?.map(
                                                                                    (
                                                                                          airline,
                                                                                          airlineKey
                                                                                    ) => (
                                                                                          <>
                                                                                                <div
                                                                                                      key={
                                                                                                            airlineKey
                                                                                                      }
                                                                                                >
                                                                                                      {
                                                                                                            item
                                                                                                                  ?.pnrDetails[
                                                                                                                  `${airline?.da?.code}-${airline?.aa?.code}`
                                                                                                            ]
                                                                                                      }
                                                                                                </div>
                                                                                          </>
                                                                                    )
                                                                              )}
                                                                        </div>
                                                                  </td>
                                                                  <td>
                                                                        <ThirdContent className="third-content">
                                                                              <div className="canvas">
                                                                                    {barCode(
                                                                                          `${
                                                                                                item?.lN
                                                                                          }/${
                                                                                                item?.fN
                                                                                          } ${
                                                                                                item
                                                                                                      ?.pnrDetails[
                                                                                                      `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                                                                                                ]
                                                                                          } ${
                                                                                                tripInfos[0]
                                                                                                      ?.sI[0]
                                                                                                      ?.fD
                                                                                                      ?.aI
                                                                                                      ?.code
                                                                                          }-${
                                                                                                tripInfos[0]
                                                                                                      ?.sI[0]
                                                                                                      ?.fD
                                                                                                      ?.fN
                                                                                          } ${convertDate(
                                                                                                tripInfos[0]
                                                                                                      ?.sI[0]
                                                                                                      ?.dt
                                                                                          )} ${
                                                                                                tripInfos[0]
                                                                                                      ?.sI[0]
                                                                                                      ?.da
                                                                                                      ?.code
                                                                                          }-${
                                                                                                tripInfos[0]
                                                                                                      ?.sI[0]
                                                                                                      ?.aa
                                                                                                      ?.code
                                                                                          }`,
                                                                                          index
                                                                                    )}
                                                                                    <canvas
                                                                                          className={`barcode${index}`}
                                                                                    ></canvas>
                                                                              </div>
                                                                              <div className="passenger-details">
                                                                                    <strong>
                                                                                          {`${item?.ti} ${item?.fN} ${item?.lN}`}{" "}
                                                                                    </strong>
                                                                                    <span>
                                                                                          {checkPassengerType(
                                                                                                item?.pt
                                                                                          )}
                                                                                    </span>
                                                                                    {item?.pt !==
                                                                                          "INFANT" && (
                                                                                          <>
                                                                                                <br />
                                                                                                <div className="third-content__baggage">
                                                                                                      <div>
                                                                                                            Hand
                                                                                                            Baggage:{" "}
                                                                                                            {tripInfos[0]
                                                                                                                  ?.sI
                                                                                                                  ?.length ===
                                                                                                            1 ? (
                                                                                                                  tripInfos[0]?.sI?.map(
                                                                                                                        (
                                                                                                                              airline,
                                                                                                                              airlineIndex
                                                                                                                        ) =>
                                                                                                                              checkBaggage(
                                                                                                                                    airlineIndex
                                                                                                                              )
                                                                                                                                    ?.iB
                                                                                                                  )
                                                                                                            ) : (
                                                                                                                  <>
                                                                                                                        {tripInfos[0]?.sI?.map(
                                                                                                                              (
                                                                                                                                    airline,
                                                                                                                                    airlineIndex
                                                                                                                              ) => (
                                                                                                                                    <>
                                                                                                                                          {airlineIndex >
                                                                                                                                                0 &&
                                                                                                                                                " || Return: " +
                                                                                                                                                      airlineIndex}
                                                                                                                                          {
                                                                                                                                                checkBaggage(
                                                                                                                                                      airlineIndex
                                                                                                                                                )
                                                                                                                                                      ?.iB
                                                                                                                                          }
                                                                                                                                    </>
                                                                                                                              )
                                                                                                                        )}
                                                                                                                  </>
                                                                                                            )}
                                                                                                      </div>
                                                                                                      <div>
                                                                                                            CheckIn
                                                                                                            Baggage:{" "}
                                                                                                            {tripInfos[0]
                                                                                                                  ?.sI
                                                                                                                  ?.length ===
                                                                                                            1 ? (
                                                                                                                  tripInfos[0]?.sI?.map(
                                                                                                                        (
                                                                                                                              airline,
                                                                                                                              airlineIndex
                                                                                                                        ) =>
                                                                                                                              checkBaggage(
                                                                                                                                    airlineIndex
                                                                                                                              )
                                                                                                                                    ?.cB
                                                                                                                  )
                                                                                                            ) : (
                                                                                                                  <>
                                                                                                                        {tripInfos[0]?.sI?.map(
                                                                                                                              (
                                                                                                                                    airline,
                                                                                                                                    airlineIndex
                                                                                                                              ) => (
                                                                                                                                    <>
                                                                                                                                          {airlineIndex >
                                                                                                                                                0 &&
                                                                                                                                                " || Return: " +
                                                                                                                                                      airlineIndex}
                                                                                                                                          {
                                                                                                                                                checkBaggage(
                                                                                                                                                      airlineIndex
                                                                                                                                                )
                                                                                                                                                      ?.cB
                                                                                                                                          }
                                                                                                                                    </>
                                                                                                                              )
                                                                                                                        )}
                                                                                                                  </>
                                                                                                            )}
                                                                                                      </div>
                                                                                                </div>
                                                                                          </>
                                                                                    )}
                                                                              </div>
                                                                        </ThirdContent>
                                                                  </td>
                                                                  <td>
                                                                        Confirmed
                                                                  </td>
                                                            </tr>
                                                      )
                                                )}
                                          </tbody>
                                    </FlightTable>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default TicketPassengerDetail;
