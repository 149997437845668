import styled from "styled-components";

export const FlightConfirmationHeader = styled.div`
      display: flex;
      gap: 10px;

      .confirmation__header-img {
            height: 60px;
            width: 60px;

            img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
            }
      }

      .confirmation__header-title {
            font-size: 24px;
            font-weight: 500;
      }

      .confirmation__header-subtitle {
            font-size: 16px;
            color: #666;

            span {
                  color: ${(props) => props.theme.primary};
                  font-weight: 600;
            }
      }
`;

export const FlightConfirmationDownload = styled.div`
      background-color: ${(props) => props.theme.white};
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      margin-bottom: 1.5rem;
      border-radius: 12px 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
`;
