import styled from "styled-components";
import { CommonContainer } from "../../../../styles/components/common/CommonStyle";
import { Swiper } from "swiper/react";

export const PackageBoxContainer = styled(CommonContainer)`
      overflow: hidden;
`;

export const PackageSwiper = styled(Swiper)`
	padding: 30px;
	margin: -30px;
`;
