import styled from "styled-components";

export const FlightInfoContainer = styled.div`
      padding: ${(props) => props.padding || "20px 10px"};
      background: white;
      margin-bottom: 15px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      &:hover {
            background-color: ${(props) =>
                  props.status ? "#FFFFFF" : "#eef4fb"};
      }
`;

export const FlightInfoContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const FlightInfoLogo = styled.div`
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: 2.5px;
      height: 34px;
      width: 34px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
            width: ${(props) => props.width || "35px"};
            height: ${(props) => props.height || "35px"};
            border-radius: 5px;
      }
`;

export const FlightInfoMultipleLogo = styled.div`
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: 2.5px;
      height: 34px;
      width: 34px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
            /* border-radius: 5px; */
            border-width: 1px;
            border-color: #f5f5f5;
            border-style: solid;
            background-color: white;

            &:first-child {
                  width: ${(props) => props.width || "18px"};
                  height: ${(props) => props.height || "18px"};
                  position: absolute;
                  top: 0;
                  left: 0;
            }

            &:last-child {
                  width: ${(props) => props.width || "18px"};
                  height: ${(props) => props.height || "18px"};
                  position: absolute;
                  bottom: 0;
                  right: 0;
            }
      }
`;

export const FlightInfoTitle = styled.div`
      display: flex;
      align-items: center;

      h4 {
            font-size: 14px;
            font-weight: 600;
      }
`;
export const FlightInfoTime = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;

      div:first-child {
            font-weight: ${(props) => props.fontWeight || "600"};
      }
`;
export const FlightInfoDuration = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 13px;

      div:first-child {
            font-weight: ${(props) => props.fontWeight || "600"};
            border-bottom: 1px solid #777;
      }
`;

export const FlightInfoPrice = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;

      .flight__info-price {
            display: flex;
            align-items: end;
            gap: 8px;
            font-weight: 600;
      }
      .flight__info-net {
            font-size: 13px;
            display: block;
      }
`;

export const FlightInfoButton = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
`;
