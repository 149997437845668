import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardBankTable,
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardNote,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { BankIcon } from "../../icons";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const BankList = () => {
      const [loading, setLoading] = useState(false);
      const [banks, setBanks] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/agent/banks`, {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  .then((response) => {
                        setBanks(response.data.banks);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardLayout title="Bank Detail">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <BankIcon />
                                          </MenuIcon>
                                          Bank Detail
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardNote>
                                    <strong>Note</strong> : Do not deposit cash
                                    in any of our bank account and make all your
                                    deposits in favour of "Aircasa"
                              </DashboardCardNote>
                              <DashboardCardBody>
                                    <DashboardBankTable>
                                          <thead>
                                                <tr>
                                                      <th>S.N</th>
                                                      <th>Bank Name</th>
                                                      <th>Address</th>
                                                      <th>Account Name</th>
                                                      <th>Account No.</th>
                                                      <th>IFSC Code</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      banks?.length > 0 ? (
                                                            banks?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                item?.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {item?.bank_name ||
                                                                                                "-"}
                                                                                    </td>
                                                                                    <td>
                                                                                          {item?.address ||
                                                                                                "-"}
                                                                                    </td>
                                                                                    <td>
                                                                                          {item?.account_name ||
                                                                                                "-"}
                                                                                    </td>
                                                                                    <td>
                                                                                          {item?.account_number ||
                                                                                                "-"}
                                                                                    </td>
                                                                                    <td>
                                                                                          {item?.ifsc_code ||
                                                                                                "-"}
                                                                                    </td>
                                                                              </tr>
                                                                              <tr>
                                                                                    <td
                                                                                          colSpan="100%"
                                                                                          className="list-td"
                                                                                    >
                                                                                          <p>
                                                                                                {`${
                                                                                                      item?.note &&
                                                                                                      "Note:-"
                                                                                                }${
                                                                                                      item?.note ||
                                                                                                      "-"
                                                                                                }`}
                                                                                          </p>
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="100%"
                                                                              className="list-td text-center"
                                                                        >
                                                                              <p>
                                                                                    No
                                                                                    Bank
                                                                                    Found
                                                                              </p>
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan="100%"
                                                                        className="list-td text-center"
                                                                  >
                                                                        <Spinner />
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </DashboardBankTable>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default BankList;
