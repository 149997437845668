import { Link } from "react-router-dom";
import styled from "styled-components";

export const HotelPackageTitle = styled.div`
      background-color: #05050583;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: ${(props) => props.theme.white};
      width: 50%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
`;

export const HotelPackageItem = styled(Link)`
      display: block;
      position: relative;

      &:hover {
            ${HotelPackageTitle} {
                  background-color: ${(props) => props.theme.primary};
            }

            img {
                  transform: scale(1.2);
            }
      }
`;

export const HotelPackageImage = styled.div`
      height: 280px;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
      }
`;
