import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import {
      FormGroupBody,
      FormGroupInput,
      FormGroupSection,
      FormGroupUpload,
      FormImageContainer,
      FormImageGallery,
      FormImageRemove,
} from "./styles";
import { CloseIcon, UploadIcon } from "../../../icons";

const FormImage = ({
      title,
      required,
      setImage,
      setImageUrl,
      imageUrl,
      grid,
      imageType,
      height,
      classes,
}) => {
      const [fileItem, setFileItem] = useState();
      const handleImage = (file) => {
            if (file) {
                  setImage(file);
                  setFileItem(file);
                  if (file?.type === "application/pdf") {
                        setImageUrl("/images/icons/pdf.png");
                  } else {
                        setImageUrl(URL.createObjectURL(file));
                  }
            }
      };

      const handleRemoveImage = () => {
            setImage("");
            setImageUrl("");
      };

      return (
            <>
                  <Col lg={classes || 4}>
                        <Form.Group>
                              <FormGroupBody>
                                    {title && (
                                          <Form.Label>
                                                {title}{" "}
                                                {required && (
                                                      <span className="text-danger">
                                                            *
                                                      </span>
                                                )}
                                          </Form.Label>
                                    )}
                                    <FormGroupSection>
                                          <FormGroupUpload
                                                role="button"
                                                tabIndex="0"
                                                height={height}
                                          >
                                                <FormGroupInput
                                                      accept={`${
                                                            imageType
                                                                  ? imageType
                                                                  : ".png,.jpg,.jpeg,.pdf"
                                                      }`}
                                                      onChange={(event) =>
                                                            handleImage(
                                                                  event.target
                                                                        .files[0]
                                                            )
                                                      }
                                                      type="file"
                                                      autoComplete="off"
                                                      tabIndex="-1"
                                                />
                                                <UploadIcon />
                                                <p className="mb-0">
                                                      <span className="text-accent font-semibold">
                                                            Upload an image or
                                                            drag and drop{" "}
                                                      </span>

                                                      <br />
                                                      {imageType ? (
                                                            <span>PNG, JPG</span>
                                                      ) : (
                                                            <span>
                                                                  PNG, JPG, PDF
                                                            </span>
                                                      )}
                                                </p>
                                          </FormGroupUpload>

                                          {imageUrl && (
                                                <FormImageGallery
                                                      style={{
                                                            gridTemplateColumns:
                                                                  grid &&
                                                                  `repeat(${grid}, 1fr)`,
                                                      }}
                                                >
                                                      <FormImageContainer>
                                                            <img
                                                                  src={imageUrl}
                                                            />
                                                      </FormImageContainer>
                                                      <FormImageRemove
                                                            onClick={() =>
                                                                  handleRemoveImage()
                                                            }
                                                      >
                                                            <CloseIcon />
                                                      </FormImageRemove>
                                                      {fileItem && (
                                                            <>
                                                                  <div>
                                                                        {
                                                                              fileItem?.name
                                                                        }
                                                                  </div>
                                                            </>
                                                      )}
                                                </FormImageGallery>
                                          )}
                                    </FormGroupSection>
                              </FormGroupBody>
                        </Form.Group>
                  </Col>
            </>
      );
};

export default FormImage;
