import React, { useContext, useEffect, useState } from "react";
import {
      FlightDetailContainer,
      FlightDetailInfo,
} from "../../../Dashboard/FlightDetails/style";
import { DoubleIcon } from "../../../../icons";
import CommonButton from "../../../Common/CommonButton";
import { useNavigate } from "react-router-dom";
import {
      FlightFooterButton,
      FlightReviewHeader,
      FlightReviewTable,
} from "./style";
import FlightReviewPayment from "../FlightReviewPayment";
import axios from "axios";
import toast from "react-hot-toast";
import TravellerReviewDetail from "./TravellerReviewDetail";
import PriceAddOn from "../../checkout/PriceSummaryBox/PriceAddOn";
import UserContext from "../../../../context/UserContext";

const formatDateToDayMonth = (dateString) => {
      const date = new Date(dateString);

      return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
      });
};

const FlightReviewDetail = ({
      currentOption,
      setCurrentOption,
      options,
      setOptions,
      reviewId,
      tripInfos,
      bookingId,
      totalAmount,
      searchQuery,
}) => {
      const userCtx = useContext(UserContext);

      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);
      const navigate = useNavigate();
      const [walletStatus, setWalletStatus] = useState(false);
      const [travellerDetail, setTravellerDetail] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleBack = () => {
            setLoadingBack(true);
            let optionItem = currentOption.index - 1;
            setTimeout(() => {
                  setLoadingBack(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      const travellers = JSON.parse(localStorage.getItem("travellers"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      const gstDetails = JSON.parse(localStorage.getItem("gstDetails"));
      const services = JSON.parse(localStorage.getItem("services"));
      const flightDetails = searchQuery?.routeInfos[0];

      const totalTraveller =
            searchQuery?.paxInfo?.ADULT +
            searchQuery?.paxInfo?.CHILD +
            searchQuery?.paxInfo?.INFANT;

      const handleSetTravellerDetails = () => {
            let details = "";

            tripInfos?.forEach((item) => {
                  item?.sI?.forEach((segmentId) => {
                        const detail = `${segmentId?.fD?.aI?.code}-${
                              segmentId?.fD?.eT
                        } ${segmentId?.da?.cityCode}-${
                              segmentId?.aa?.cityCode
                        } ${formatDateToDayMonth(segmentId?.dt)}, `;
                        details += detail;
                  });
            });

            setTravellerDetail(details);
      };

      const checkService = () => {
            return services[
                  `${flightDetails?.fromCityOrAirport?.cityCode}-${flightDetails?.toCityOrAirport?.cityCode}`
            ];
      };

      const handleOption = async () => {
            setLoading(true);
            let travellerDetails;

            if (!(userCtx?.profile?.balance >= totalAmount)) {
                  toast.error("Insufficient Balance");
                  setLoading(false);
                  return;
            }

            if (travellers) {
                  travellerDetails = travellers?.map((item) => {
                        const baggageCode = checkService()
                              ? checkService()[item?.ptName]?.Baggage?.code
                              : false;
                        const mealCode = checkService()
                              ? checkService()[item?.ptName]?.Meal?.code
                              : false;
                        const seatCode = checkService()
                              ? checkService()[item?.ptName]?.Seat?.code
                              : false;

                        return {
                              ti: item?.title,
                              fN: item?.firstName,
                              lN: item?.lastName,
                              pt: item?.pt,

                              ...(baggageCode && {
                                    ssrBaggageInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: baggageCode,
                                          },
                                    ],
                              }),
                              ...(mealCode && {
                                    ssrMealInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: mealCode,
                                          },
                                    ],
                              }),
                              ...(seatCode && {
                                    ssrSeatInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: seatCode,
                                          },
                                    ],
                              }),
                              ...((item?.pt === "CHILD" ||
                                    item?.pt === "INFANT") && {
                                    dob: item?.dateOfBirth,
                              }),
                              ...(!searchQuery?.isDomestic && {
                                    pNum: item?.passportNumber,
                                    eD: item?.passportExpiry,
                              }),
                        };
                  });
            }

            const data = {
                  bookingId: bookingId,
                  travellerInfo: travellerDetails,
                  paymentInfos: [
                        {
                              amount: totalAmount,
                        },
                  ],
                  ...(gstDetails && {
                        gstInfo: {
                              gstNumber: gstDetails?.registrationNumber,
                              email: gstDetails?.companyEmail,
                              registeredName: gstDetails?.companyName,
                              mobile: gstDetails?.companyPhone,
                              address: gstDetails?.companyAddress,
                        },
                  }),
                  deliveryInfo: {
                        emails: [contactDetails?.emailAddress],
                        contacts: [contactDetails?.phoneNumber],
                  },
            };

            const response = await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/air/book`,
                        data,
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data) {
                  const bookingData = {
                        booking_id: bookingId,
                        name: `${travellers[0]?.title} ${travellers[0]?.firstName} ${travellers[0]?.lastName}`,
                        email: contactDetails?.email,
                        summary: travellerDetail + " x " + totalTraveller,
                        amount: totalAmount,
                        order_type: "AIR",
                        status: "Success",
                  };

                  if (response.data.status?.success === true) {
                        await axios
                              .post(
                                    `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings`,
                                    bookingData,
                                    {
                                          headers: {
                                                Authorization: `Bearer ${token}`,
                                          },
                                    }
                              )
                              .then((response) => {
                                    if (response.data.result === "success") {
                                          setLoading(false);
                                          navigate(
                                                `/flights/confirmation/${response?.data?.bookingId}`
                                          );
                                    }
                              })
                              .catch((error) => {
                                    toast.error(error.message);
                              });
                  }
            }
            setLoading(false);
      };

      useEffect(() => {
            handleSetTravellerDetails();
      }, [tripInfos]);

      return (
            <>
                  <FlightDetailContainer className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                              <FlightReviewHeader>
                                    <div>
                                          Travellers Details ({" "}
                                          {travellers?.length} )
                                    </div>
                              </FlightReviewHeader>
                              <TravellerReviewDetail />
                        </div>
                        <FlightDetailInfo
                              marginBottom="0"
                              paddingBottom="0"
                              className="mt-0"
                              border="0"
                        >
                              {travellers?.length > 0 &&
                                    travellers?.map((item, index) => (
                                          <FlightReviewTable border={false}>
                                                <div>{++index}. </div>
                                                <div>
                                                      {`${item?.title} ${item?.firstName} ${item?.lastName}`}
                                                </div>
                                          </FlightReviewTable>
                                    ))}
                        </FlightDetailInfo>
                  </FlightDetailContainer>

                  <PriceAddOn />

                  <FlightReviewPayment
                        totalAmount={totalAmount}
                        setWalletStatus={setWalletStatus}
                  />

                  <FlightFooterButton className="price__button  mt-4  mt-0">
                        <CommonButton
                              title={"Back"}
                              leftIcon={
                                    <DoubleIcon
                                          transform="rotate(180deg)"
                                          color="#FFF"
                                    />
                              }
                              padding={"8px 16px"}
                              type="button"
                              button={true}
                              handleClick={handleBack}
                              loading={loadingBack}
                        />
                        <CommonButton
                              title={"Pay Now"}
                              icon={<DoubleIcon color="#FFF" />}
                              padding={"8px 30px"}
                              type="submit"
                              button={true}
                              handleClick={handleOption}
                              loading={loading}
                        />
                  </FlightFooterButton>
            </>
      );
};

export default FlightReviewDetail;
