import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardNote,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { ChangePasswordIcon } from "../../icons";
import { Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import { FilterButton } from "../bank_deposits/style";
import axios from "axios";
import toast from "react-hot-toast";

const ChangePassword = () => {
      const [loading, setLoading] = useState(false);
      const [password, setPassword] = useState("");
      const [oldPassword, setOldPassword] = useState("");
      const [confirmPassword, setConfirmPassword] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  password: password,
                  oldpassword: oldPassword,
                  password_confirmation: confirmPassword,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/change-password`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setOldPassword("");
                              setPassword("");
                              setConfirmPassword("");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.oldpassword) {
                                    toast.error(
                                          response.data.message.oldpassword
                                    );
                              }
                              if (response.data.message.password) {
                                    toast.error(response.data.message.password);
                              }
                              if (response.data.message.password_confirmation) {
                                    toast.error(
                                          response.data.message
                                                .password_confirmation
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <DashboardLayout title="Change Password">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <ChangePasswordIcon />
                                          </MenuIcon>
                                          Change Your Password
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardNote>
                                    PLEASE ENTER THE OLD PASSWORD AND NEW
                                    PASSWORD, AFTER THE SUCCESSFULLY CHANGED
                                    YOUR PASSWORD YOU MUST LOGIN AGAIN !!
                              </DashboardCardNote>
                              <DashboardCardBody>
                                    <Form onSubmit={handleSubmit}>
                                          <Row className="g-3">
                                                <Col
                                                      md={{
                                                            span: 4,
                                                            offset: 4,
                                                      }}
                                                      className="off"
                                                >
                                                      <FormControl
                                                            type="password"
                                                            onChange={(event) =>
                                                                  setOldPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                            value={oldPassword}
                                                            placeholder="Old Password"
                                                      />
                                                </Col>
                                                <Col
                                                      md={{
                                                            span: 4,
                                                            offset: 4,
                                                      }}
                                                >
                                                      <FormControl
                                                            type="password"
                                                            value={password}
                                                            onChange={(event) =>
                                                                  setPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                            placeholder="New Password"
                                                      />
                                                </Col>
                                                <Col
                                                      md={{
                                                            span: 4,
                                                            offset: 4,
                                                      }}
                                                >
                                                      <FormControl
                                                            type="password"
                                                            value={
                                                                  confirmPassword
                                                            }
                                                            onChange={(event) =>
                                                                  setConfirmPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                            placeholder="Confirm Password"
                                                      />
                                                </Col>
                                                <Col
                                                      md={{
                                                            span: 4,
                                                            offset: 4,
                                                      }}
                                                      className="text-end"
                                                >
                                                      <FilterButton>
                                                            {loading ? (
                                                                  <Spinner />
                                                            ) : (
                                                                  "SUBMIT"
                                                            )}
                                                      </FilterButton>
                                                </Col>
                                          </Row>
                                    </Form>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default ChangePassword;
