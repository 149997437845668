export const policies = [
      {
            title: "Use your Airline PNR for all communication you have directly with the airline & us about your booking.",
      },
      {
            title: "A printed copy of the E-Ticket or E-Ticket display on Phone / tablet must be presented at the time of check-in and to get access to Airport Terminal.",
      },
      {
            title: "Check-in starts 3 hours before schedule departure and closes upto 60 minutes prior to the departure time. We recommend you report at the check-in counter at least 2 hours prior to the departure time (Check-in times can be Airport/Country specific, in case of International Journey, it’s recommended to recheck specific requirements).",
      },
      {
            title: "Carry a valid Government issued Photo identification, you will need it as a proof of identity while checking-in, for Infants, it is mandatory to carry the DOB Certificate.",
      },
      {
            title: "For International Flights, carrying of Passport and related Visa / travel documents is mandatory.",
      },
      {
            title: "Partial Cancellation not allowed for Round trip fares & tickets booked under friends and family fare.",
      },
      {
            title: "Due to security reasons, Passengers flying from Jammu & Srinagar are not allowed to carry any hand baggage.",
      },
      {
            title: "We won’t be responsible for any delay or cancellation of flight from Airlines end for any reason, and if you miss any connecting flight because of any delay.",
      },
      {
            title: "Please reconfirm your flight departure time at least 24 hours prior to scheduled departure by checking the flight status, at times airline might send you communication of flight cancellation by sms, which you might have missed or message could be undelivered due to network issues, so to avoid last minute hassles it's recommended to recheck flight status.",
      },
      {
            title: "Please reconfirm Airport Terminal (as it might have changed after you booked the ticket) so the one printed on your ticket might actually have changed.",
      },
      {
            title: "Carriage and other facilities provided by the Airlines are subject to their Terms & Conditions.",
      },
      {
            title: "All Time indicated are the local times (in 24 hrs format) at the relevant airport.",
      },
      {
            title: "To Cancel / Reschedule or amend your booking, please log in to the MyTrip Section of the website.",
      },
      {
            title: "Cancellation / Reissue are subject to Airline Policies which might change from time to time.",
      },
      {
            title: "WEB CHECK-IN IS NOW MANDATORY. CHECK-IN ONLINE ON AIRLINE WEBSITE.",
      },
      {
            title: "WEB CHECK-IN WILL CLOSE 60 MINS PRIOR TO DEPARTURE.",
      },
      {
            title: "Power banks/portable mobile chargers are allowed ONLY in Hand-Baggage and NOT in Checked-in Baggage.",
      },
      {
            title: "Checked-in Baggage with these items will NOT BE LOADED on the flights due to security reasons.",
      },
];
