import React, { useEffect, useState } from "react";
import { PackageBoxContainer, PackageSwiper } from "./styles";
import { Container } from "react-bootstrap";
import CommonTitle from "../../../Common/CommonTitle";
import PackageItem from "./PackageItem";

import axios from "axios";
import { Skeleton } from "@mui/material";
import { indianPackageList, packageList } from "../../../../data/banners";
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { SliderTitle } from "./PackageItem/styles";

const PackageBox = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/packages`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <PackageBoxContainer>
                        <Container>
                              <CommonTitle
                                    title={"Explore our holiday packages"}
                              />
                              <div>
                                    <div>
                                          <SliderTitle>
                                                International Holiday Packages
                                          </SliderTitle>
                                    </div>
                                    <PackageSwiper
                                          spaceBetween={10}
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                          }}
                                          breakpoints={{
                                                640: {
                                                      slidesPerView: 2,
                                                      spaceBetween: 20,
                                                },
                                                768: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 40,
                                                },
                                                1024: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 20,
                                                },
                                          }}
                                          pagination={{
                                                clickable: true,
                                          }}
                                          navigation={true}
                                          modules={[
                                                Autoplay,
                                                Pagination,
                                                Navigation,
                                          ]}
                                    >
                                          {packageList.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                      <PackageItem
                                                            data={item}
                                                      />
                                                </SwiperSlide>
                                          ))}
                                    </PackageSwiper>
                              </div>
                              <div className="mt-5">
                                    <div>
                                          <SliderTitle>
                                                Indian Holiday Packages
                                          </SliderTitle>
                                    </div>
                                    <PackageSwiper
                                          spaceBetween={10}
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                          }}
                                          breakpoints={{
                                                640: {
                                                      slidesPerView: 2,
                                                      spaceBetween: 20,
                                                },
                                                768: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 40,
                                                },
                                                1024: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 20,
                                                },
                                          }}
                                          pagination={{
                                                clickable: true,
                                          }}
                                          navigation={true}
                                          modules={[
                                                Autoplay,
                                                Pagination,
                                                Navigation,
                                          ]}
                                    >
                                          {indianPackageList.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                      <PackageItem
                                                            data={item}
                                                      />
                                                </SwiperSlide>
                                          ))}
                                    </PackageSwiper>
                                    {/* {!loading ? (    
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                          </>
                                    )} */}
                              </div>
                        </Container>
                  </PackageBoxContainer>
            </>
      );
};

export default PackageBox;
