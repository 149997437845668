import React, { useEffect, useRef, useState } from "react";
import { FormWrapper } from "../styles";
import { Form } from "react-bootstrap";

import { DayPicker } from "react-day-picker";
import { addDays, format } from "date-fns";
import "react-day-picker/dist/style.css";
import { FlightModalOptionContent } from "../../../frontend/flight/style";

const FormDate = ({
      label,
      value,
      onChange,
      placeholder,
      width,
      padding,
      marginRight,
      backgroundNone,
      inputPadding,
      border,
      required,
      defaultValue,
      returnStatus,
      returnDate,
      setReturnDate,
}) => {
      const [status, setStatus] = useState(false);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      const [selectedDate, setSelectedDate] = useState(value);

      const convertDate = (date) => {
            return format(date, "EEE, dd MMM");
      };

      const convertNumberDate = (date) => {
            return format(date, "yyyy-MM-dd");
      };

      const handleDate = (item) => {
            setStatus(false);
            if (item) {
                  setSelectedDate(item);
                  onChange(convertNumberDate(item));
                  if (convertNumberDate(item) >= returnDate) {
                        setReturnDate(
                              convertNumberDate(
                                    addDays(convertNumberDate(item), 1)
                              )
                        );
                  }
            }
      };

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      return (
            <>
                  <FormWrapper
                        width={width}
                        padding={padding}
                        marginRight={marginRight}
                        backgroundNone={backgroundNone}
                        inputPadding={inputPadding}
                        border={border}
                  >
                        <Form.Group ref={formGroupRef}>
                              {label && <Form.Label>{label}</Form.Label>}
                              <Form.Control
                                    type="text"
                                    value={convertDate(selectedDate)}
                                    onClick={() => setStatus(true)}
                                    onChange={onChange}
                                    placeholder={placeholder}
                                    disabled={!returnStatus}
                                    required={required}
                              />
                        </Form.Group>
                        {status && (
                              <FlightModalOptionContent
                                    returnStatus={returnStatus}
                                    ref={locationRef}
                              >
                                    <DayPicker
                                          mode="single"
                                          selected={selectedDate}
                                          onSelect={handleDate}
                                          disabled={{
                                                before:
                                                      defaultValue ||
                                                      new Date(),
                                          }}
                                    />
                                    {/* <button
                                          onClick={() =>
                                                setSelectedMonth(currentDate)
                                          }
                                          type="button"
                                    >
                                          Go to Today
                                    </button> */}
                              </FlightModalOptionContent>
                        )}
                  </FormWrapper>
            </>
      );
};

export default FormDate;
