import React from "react";
import { MenuItemContainer } from "./styles";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({ title, slug, onClick, icon, disabled }) => {
      const location = useLocation()?.pathname;
      return (
            <>
                  <MenuItemContainer disabled={disabled} onClick={onClick}>
                        <Link
                              to={slug}
                              className={`${
                                    location === slug ? "active" : ""
                              } ${disabled === true ? "disabled" : ""}`}
                        >
                              {icon}
                              {title}
                        </Link>
                  </MenuItemContainer>
            </>
      );
};

export default MenuItem;
