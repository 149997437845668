import styled from "styled-components";

export const InputSliderContainer = styled.div`
      .range-slider {
            height: 3px;
            .range-slider__thumb {
                  height: 14px;
                  width: 14px;
                  background: ${(props) => props.theme.black};
            }
            .range-slider__range {
                  background: ${(props) => props.theme.black};
            }
      }
`;

export const PriceRange = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      font-size: 13px;
      width: 100%;
`;
export const MinValueBox = styled.div``;
export const MaxValueBox = styled.div``;
