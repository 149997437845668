import React from "react";
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { BannerImage, BannerSwiper, BannerTitle } from "./style";

const BannerSlider = () => {
      return (
            <>
                  <BannerSwiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                        }}
                        pagination={false}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper w-100"
                  >
                        <SwiperSlide>
                              <BannerImage>
                                    <img src="/banner-1.png" alt="" />
                              </BannerImage>
                              <BannerTitle>
                                    Experience the seamless booking you deserve
                                    the Aircasa.
                              </BannerTitle>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BannerImage>
                                    <img src="/banner-2.png" alt="" />
                              </BannerImage>
                              <BannerTitle>
                                    Unlock exclusive deal-breaker rates for
                                    flights and hotel bookings with Aircasa
                              </BannerTitle>
                        </SwiperSlide>
                  </BannerSwiper>
            </>
      );
};

export default BannerSlider;
