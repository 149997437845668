import styled from "styled-components";

export const FilterReturnItem = styled.div`
      border: 1px solid #e2e2e2;
      padding: 5px;
      border-radius: 5px;
      text-align: center;

      &.active {
            background-color: ${(props) => props.theme.primary};
      }

      img {
            width: 32px;
            height: 32px;
      }
`;
