import React, { useEffect, useState } from "react";
import "./Oneway.css";
import html2pdf from "html2pdf.js";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { CardDescription, TicketContainer } from "./style";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { Spinner } from "react-bootstrap";
import { format } from "date-fns";
import TicketFlightDetail from "./TicketFlightDetail";
import TicketPassengerDetail from "./TicketPassengerDetail";
import { policies } from "../../data/policy";

const formatDateTime = (dateString) => {
      const date = new Date(dateString);

      // Format date parts
      const day = String(date.getDate()).padStart(2, "0"); // Day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (0-based index, so +1)
      const year = date.getFullYear(); // Year

      // Format time parts
      const hours = String(date.getHours()).padStart(2, "0"); // Hours
      const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes
      const seconds = String(date.getSeconds()).padStart(2, "0"); // Seconds

      // Combine into desired format
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const OnewayTicket = () => {
      const { bookingId } = useParams();
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      const markupPrice = searchParams.get("markup");

      const [loading, setLoading] = useState(false);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const [isButtonVisible, setIsButtonVisible] = useState(true);

      const handlePrint = () => {
            setIsButtonVisible(false); // Hide before generating PDF

            const element = document.getElementById("pdf-content");
            const options = {
                  padding: 1,
                  filename: "one-way-ticket.pdf",
                  image: { type: "jpeg", quality: 0.98 },
                  html2canvas: { scale: 2 },
                  jsPDF: {
                        unit: "in",
                        format: "letter",
                        orientation: "portrait",
                  },
            };

            if (element) {
                  html2pdf()
                        .set(options)
                        .from(element)
                        .save()
                        .then(() => {
                              setIsButtonVisible(true); // Show the button again after the PDF is saved
                        })
                        .catch((err) => {
                              console.error("PDF generation error:", err);
                              setIsButtonVisible(true); //show the button again even if there's an error
                        });
            } else {
                  console.error("Element not found!");
                  setIsButtonVisible(true); // Show the button again if element is not found
            }
      };

      const loadData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                        {
                              bookingId: bookingId,
                              requirePaxPricing: true,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.status?.success === true) {
                              setOrder(response.data.order);
                              setTripInfos(
                                    response.data.itemInfos?.AIR?.tripInfos
                              );
                              setTotalPriceInfo(
                                    response.data.itemInfos?.AIR?.totalPriceInfo
                                          ?.totalFareDetail
                              );
                              setTravellerInfos(
                                    response.data.itemInfos?.AIR?.travellerInfos
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <TicketContainer padding="100px">
                  <div className="container-fluid my-4" id="pdf-content">
                        <h2 className="main-title text-center">
                              Ticket Confirmation
                        </h2>
                        {!loading ? (
                              <div className="main-card">
                                    <div className="card-body-first mb-0">
                                          <div className="company-info">
                                                <h5 className="card-title">
                                                      PAREEK TOURS AND TRAVELS
                                                </h5>
                                                <p className="card-text">
                                                      MAIN MARKET NEAR CLOCK
                                                      TOWER,
                                                      <br />
                                                      Sardarshahar, <br />{" "}
                                                      Rajasthan - 331403
                                                      <br />
                                                      <strong>
                                                            Email:{" "}
                                                            {
                                                                  order
                                                                        ?.deliveryInfo
                                                                        ?.emails
                                                            }
                                                      </strong>
                                                      <br />
                                                      <strong>
                                                            Mobile No:{" "}
                                                            {
                                                                  order
                                                                        ?.deliveryInfo
                                                                        ?.contacts
                                                            }
                                                      </strong>
                                                </p>
                                          </div>

                                          <div className="pnr-section">
                                                <p className="airline-name">
                                                      {
                                                            tripInfos[0]?.sI[0]
                                                                  ?.fD?.aI?.name
                                                      }
                                                </p>
                                                <img
                                                      src={`/images/AirlinesLogo/${tripInfos[0]?.sI[0]?.fD?.aI?.code}.png`}
                                                      alt="IndiGo Logo"
                                                      className="airline-logo"
                                                />
                                                <div className="pnr-info">
                                                      <h6 className="pnr">
                                                            T1GRVJ
                                                      </h6>
                                                      <p className="pnr-label">
                                                            Airline PNR
                                                      </p>
                                                </div>
                                          </div>

                                          <div className="reference-info">
                                                <p>
                                                      Reference Number:{" "}
                                                      <strong>FB777F9</strong>
                                                </p>
                                                <p>
                                                      Issued On:{" "}
                                                      <strong>
                                                            {order &&
                                                                  formatDateTime(
                                                                        order?.createdOn
                                                                  )}
                                                      </strong>
                                                </p>
                                                <img
                                                      src="/barcode.jpg"
                                                      alt="QR Code"
                                                      className="qr-code"
                                                />
                                          </div>
                                    </div>

                                    {/* second table */}
                                    <TicketFlightDetail tripInfos={tripInfos} />

                                    {/* third table */}
                                    <TicketPassengerDetail
                                          order={order}
                                          tripInfos={tripInfos}
                                          travellerInfos={travellerInfos}
                                    />

                                    <div className=" mb-4">
                                          <div className="card-body">
                                                <h5 className="card-title">
                                                      Payment Details
                                                </h5>
                                                <div className="payment-details">
                                                      <div className="payment-row">
                                                            <span>
                                                                  Base Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.BF
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      <div className="payment-row">
                                                            <span>
                                                                  Taxes and Fees
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.TAF +
                                                                                    parseFloat(
                                                                                          markupPrice
                                                                                    )
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      <div className="payment-row gross-fare">
                                                            <span>
                                                                  Gross Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.TF +
                                                                                    parseFloat(
                                                                                          markupPrice
                                                                                    )
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <CardDescription>
                                          <div className="card-body">
                                                <h5 className="card-title">
                                                      Additional Information
                                                </h5>
                                                <ul className="card-desc-content">
                                                      {policies?.map(
                                                            (item, index) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </li>
                                                            )
                                                      )}
                                                      <li>
                                                            <strong>
                                                                  Disclaimer:
                                                            </strong>{" "}
                                                            No meal will be
                                                            served on flights
                                                            that have less than
                                                            2 hours of flight
                                                            duration.
                                                      </li>
                                                </ul>
                                          </div>
                                    </CardDescription>
                                    {isButtonVisible && (
                                          <button
                                                className="btn-print my-4"
                                                onClick={handlePrint}
                                          >
                                                Print
                                          </button>
                                    )}
                              </div>
                        ) : (
                              <div className="text-center">
                                    <Spinner />
                              </div>
                        )}
                  </div>
            </TicketContainer>
      );
};

export default OnewayTicket;
