import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CheckTooltip = ({ placement, errorMessage, children }) => {
      return (
            <>
                  <OverlayTrigger
                        placement={placement || "bottom"}
                        overlay={<Tooltip>{errorMessage}</Tooltip>}
                  >
                        {children}
                  </OverlayTrigger>
            </>
      );
};

export default CheckTooltip;
