import React from "react";
import { TravellersForm } from "../../../../frontend/checkout/styles";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import Select from "react-select";
import { countryCodeList } from "../../../../data/dashboard";
import { TravellersTitle } from "../TravellersDetailBox/styles";
import { event } from "jquery";

const ContactInfo = ({
      contactDetails,
      handleContactDetails,
      isValid,
      setIsValid,
}) => {
      const handlePhoneNumberChange = (event) => {
            const { value } = event.target;

            // Allow only digits and limit to 10 characters
            if (/^\d{0,10}$/.test(value)) {
                  handleContactDetails(event);

                  // Validate if it has exactly 10 digits
                  setIsValid(value.length === 10);
            }
      };

      return (
            <>
                  <TravellersTitle className="mb-3 d-flex gap-2">
                        <h3>Contact Information</h3>
                        <span>
                              Your ticket will be sent to this email address
                        </span>
                  </TravellersTitle>
                  <TravellersForm padding="0" className="g-3 ">
                        <Row className="g-3">
                              <Col lg={3}>
                                    <FormLabel>Country Code</FormLabel>
                                    <Select
                                          options={countryCodeList}
                                          onChange={(value, event) =>
                                                handleContactDetails(
                                                      event,
                                                      "SELECT",
                                                      value.value
                                                )
                                          }
                                          required={true}
                                          value={countryCodeList?.find(
                                                (option) =>
                                                      option.value ===
                                                      contactDetails?.countryCode
                                          )}
                                    />
                              </Col>
                              <Col lg={5}>
                                    <FormLabel>Email Address</FormLabel>
                                    <FormControl
                                          type="email"
                                          name="emailAddress"
                                          onChange={handleContactDetails}
                                          value={contactDetails?.emailAddress}
                                          required={true}
                                    />
                              </Col>
                              <Col lg={4}>
                                    <FormLabel>Phone Number</FormLabel>
                                    <FormControl
                                          type="text"
                                          name="phoneNumber"
                                          onChange={(event) =>
                                                handlePhoneNumberChange(event)
                                          }
                                          value={contactDetails?.phoneNumber}
                                          required={true}
                                    />
                                    {!isValid &&
                                          contactDetails?.phoneNumber.length >
                                                0 && (
                                                <p style={{ color: "red" }}>
                                                      Phone number must be
                                                      exactly 10 digits.
                                                </p>
                                          )}
                              </Col>
                        </Row>
                  </TravellersForm>
            </>
      );
};

export default ContactInfo;
