import React from "react";

import AuthLayout from "../AuthLayout";
import { LoginFormContainer } from "../styles";

const PasswordReset = () => {
      return (
            <>
                  <AuthLayout>
                        <LoginFormContainer>Something</LoginFormContainer>
                  </AuthLayout>
            </>
      );
};

export default PasswordReset;
