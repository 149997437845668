import React, { useState } from "react";
import MenuItem from "../MenuItem";
import {
      AccountStatementIcon,
      BankIcon,
      ChangePasswordIcon,
      DepositRequestIcon,
      MyBookingIcon,
      UserIcon,
} from "../../../../icons";
import { MenuContainer } from "./style";
import { Navigate } from "react-router-dom";

const MenuList = () => {
      const [loading, setLoading] = useState(false);
      const login = localStorage.getItem("isLoggedIn");

      const handleLogout = () => {
            setLoading(true);

            setTimeout(() => {
                  localStorage.clear();
                  setLoading(false);
            }, 3000);
      };

      if (login === null) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <MenuContainer>
                        <ul>
                              <MenuItem
                                    title="My Account"
                                    link="/my-account"
                                    icon={<UserIcon />}
                              />
                              <MenuItem
                                    title="My Bookings"
                                    link="/my-bookings"
                                    icon={<MyBookingIcon />}
                              />
                              <MenuItem
                                    title="Wallet Manage"
                                    link="/wallet-manage"
                                    icon={<DepositRequestIcon />}
                              />
                              <MenuItem
                                    title="Account Statement"
                                    link="/account-statement"
                                    icon={<AccountStatementIcon />}
                              />
                              <MenuItem
                                    title="Bank Detail"
                                    link="/bank-detail"
                                    icon={<BankIcon />}
                              />
                              <MenuItem
                                    title="Change Password"
                                    link="/change-password"
                                    icon={<ChangePasswordIcon />}
                              />
                              {/* <MenuItem
                                    title="Logout"
                                    button={true}
                                    type="button"
                                    handleClick={handleLogout}
                                    loading={loading}
                                    classes="border-0"
                                    icon={<LogoutIcon />}
                              /> */}
                        </ul>
                  </MenuContainer>
            </>
      );
};

export default MenuList;
