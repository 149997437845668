import React from "react";

const BankIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        account_balance
                  </span>
            </>
      );
};

export default BankIcon;
