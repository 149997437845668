import React, { useState } from "react";
import { CloseButton, ModalBody } from "react-bootstrap";
import { FlightModal, FlightTab, FlightTabs } from "./style";
import FlightDetailBox from "../../FlightDetailBox";
import PriceRuleDetail from "../PriceRuleDetail";

const PriceFlightModal = ({ tripInfos }) => {
      const [show, setShow] = useState(false);
      return (
            <>
                  <div className="price__modal" onClick={() => setShow(true)}>
                        Flight Details
                  </div>
                  <FlightModal size="lg" show={show}>
                        <ModalBody>
                              <CloseButton onClick={() => setShow(false)} />
                              <FlightTabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    number={2 + (tripInfos?.length - 1)}
                              >
                                    <FlightTab
                                          eventKey="home"
                                          title="Flight Details"
                                    >
                                          <FlightDetailBox
                                                tripInfos={tripInfos}
                                                marginBottom={"20px"}
                                          />
                                    </FlightTab>
                                    {tripInfos?.map((item, index) => (
                                          <FlightTab
                                                eventKey={`profile${index}`}
                                                title={`Fare Rules (${
                                                      item?.sI[0]?.da?.code
                                                } - ${
                                                      item?.sI[
                                                            item?.sI?.length - 1
                                                      ]?.aa?.code
                                                })`}
                                          >
                                                <PriceRuleDetail
                                                      fareId={
                                                            item
                                                                  ?.totalPriceList[0]
                                                      }
                                                      leavingFrom={
                                                            item?.sI[0]?.da
                                                                  ?.code
                                                      }
                                                      destination={
                                                            item?.sI[
                                                                  item?.sI
                                                                        ?.length -
                                                                        1
                                                            ]?.aa?.code
                                                      }
                                                />
                                          </FlightTab>
                                    ))}
                              </FlightTabs>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default PriceFlightModal;
