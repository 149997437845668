import { Link } from "react-router-dom";
import styled from "styled-components";

export const BookingItemContainer = styled.div`
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      margin-top: calc(-1 * var(--bs-gutter-y));
      margin-right: calc(-0.5 * var(--bs-gutter-x));
      margin-left: calc(-0.5 * var(--bs-gutter-x));
`;

export const BookingItemCard = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      width: calc(50% - 10px);
      height: 100%;

      &:last-child {
            margin-bottom: 0;
      }
`;

export const BookingItemHeader = styled.div`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      height: 40px;
      padding: 2px 8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .first__width {
            width: 33.33%;
      }

      .second__width {
            width: 33.33%;
      }
      .third__width {
            width: 33.33%;
      }
`;

export const BookingItemBody = styled.div`
      padding: 10px;
      display: flex;
      font-size: 15px;
      gap: 10px;
      height: 100%;

      .first__width {
            width: 33.33%;
      }

      .second__width {
            width: 33.33%;
      }
      .third__width {
            width: 33.33%;
      }
`;

export const BookingItemPnrBox = styled.div`
      width: 100%;
      height: 100%;
      background-color: #f5f5f5;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      .text-muted {
            font-size: 14px;
      }
`;

export const BookingItemFooter = styled.div`
      background-color: #f4f4f4;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
`;

export const BookingItemButton = styled(Link)`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      padding: 5px 10px;
      border-radius: 2.5px;
      font-size: 13px;
      text-transform: uppercase;
`;
