import React from "react";
import {
      FlightTripPriceContainer,
      FlightTripPriceContent,
      FlightTripPriceItem,
      PriceItemContent,
      PriceItemInfo,
      RefundIconContainer,
      SummaryIconContainer,
} from "./style";
import { FormControl } from "react-bootstrap";
import {
      calculateTotalAmount,
      calculateTotalNetAmount,
      convertAmount,
      convertFloat,
} from "../../../../hooks/CurrentData";
import { BaggageIcon, LaunchIcon } from "../../../../icons";

const FlightTripPrice = ({
      totalPriceList,
      setFareId,
      returnStatus,
      fareId,
      moreFare,
      searchDetail,
      netFare,
      fullFare,
}) => {
      const handlePrice = (item) => {
            setFareId(item);
      };

      const checkCabinClassPrice = () => {
            let filteredData = totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );

            return filteredData.sort(
                  (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
            );
      };

      return (
            <>
                  <FlightTripPriceContainer returnStatus={returnStatus}>
                        <FlightTripPriceContent returnStatus={returnStatus}>
                              {checkCabinClassPrice()
                                    ?.slice(
                                          0,
                                          moreFare ? totalPriceList?.length : 2,
                                          0
                                    )
                                    ?.map((item, index) => (
                                          <FlightTripPriceItem
                                                className={
                                                      item?.id === fareId?.id
                                                            ? "active"
                                                            : ""
                                                }
                                                key={index}
                                                onClick={() =>
                                                      handlePrice(item)
                                                }
                                                returnStatus={returnStatus}
                                                // meal={item?.fd?.ADULT?.mI}
                                          >
                                                <FormControl
                                                      type="radio"
                                                      value={item?.id}
                                                      name="option"
                                                      checked={
                                                            item?.id ===
                                                            fareId?.id
                                                                  ? true
                                                                  : false
                                                      }
                                                />
                                                <PriceItemContent>
                                                      <div>
                                                            <div className="price">
                                                                  <span>
                                                                        {`${
                                                                              item?.fareIdentifier ===
                                                                              "OFFER_FARE_WITH_PNR"
                                                                                    ? "Series Fare"
                                                                                    : item?.fareIdentifier
                                                                        }`}
                                                                  </span>
                                                            </div>
                                                            <div className="summary">
                                                                  <SummaryIconContainer
                                                                        refundStatus={
                                                                              item
                                                                                    ?.fd
                                                                                    ?.ADULT
                                                                                    ?.mI
                                                                        }
                                                                  >
                                                                        <LaunchIcon
                                                                              height="12px"
                                                                              width="12px"
                                                                              color={
                                                                                    item?.id ===
                                                                                    fareId?.id
                                                                                          ? "white"
                                                                                          : ""
                                                                              }
                                                                        />
                                                                  </SummaryIconContainer>
                                                                  <SummaryIconContainer
                                                                        refundStatus={
                                                                              item
                                                                                    ?.fd
                                                                                    ?.ADULT
                                                                                    ?.bI
                                                                                    ?.cB
                                                                        }
                                                                  >
                                                                        <BaggageIcon
                                                                              height="12px"
                                                                              width="12px"
                                                                              color={
                                                                                    item?.id ===
                                                                                    fareId?.id
                                                                                          ? "white"
                                                                                          : ""
                                                                              }
                                                                        />
                                                                  </SummaryIconContainer>
                                                                  <RefundIconContainer
                                                                        refundStatus={
                                                                              item
                                                                                    ?.fd
                                                                                    ?.ADULT
                                                                                    ?.rT
                                                                        }
                                                                  >
                                                                        R
                                                                  </RefundIconContainer>
                                                            </div>
                                                      </div>
                                                      <PriceItemInfo
                                                            color={
                                                                  item?.id ===
                                                                  fareId?.id
                                                            }
                                                      >
                                                            <div>
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              calculateTotalAmount(
                                                                                    item?.fd,
                                                                                    searchDetail,
                                                                                    fullFare
                                                                              )
                                                                        )
                                                                  )}
                                                            </div>
                                                            {netFare && (
                                                                  <div className="price__net ">
                                                                        NET ₹{" "}
                                                                        {convertAmount(
                                                                              convertFloat(
                                                                                    calculateTotalNetAmount(
                                                                                          item?.fd,
                                                                                          searchDetail
                                                                                    )
                                                                              )
                                                                        )}
                                                                  </div>
                                                            )}
                                                      </PriceItemInfo>
                                                </PriceItemContent>
                                          </FlightTripPriceItem>
                                    ))}
                        </FlightTripPriceContent>
                  </FlightTripPriceContainer>
            </>
      );
};

export default FlightTripPrice;
