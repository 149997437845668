import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardNote,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { DepositRequestIcon } from "../../icons";
import DepositForm from "./DepositForm";
import { MenuOptionButton } from "./style";

const DepositRequest = () => {
      return (
            <>
                  <DashboardLayout title="New Deposit Request">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <DepositRequestIcon />
                                          </MenuIcon>
                                          New Deposit Request
                                    </DashboardCardTitle>
                                    <MenuOptionButton to="/view-deposit">
                                          View Deposit Request
                                    </MenuOptionButton>
                              </DashboardCardHeader>
                              <DashboardCardNote>
                                    Please do not send Deposit Request, if you
                                    have deposited the amount using Top-up.
                                    Top-up deposits are credited instantly in
                                    your account.
                                    <p className="mt-2">
                                          <strong>
                                                Note: Please be informed that
                                                effective 1st april 2017 the
                                                daily limit of cash deposit
                                                under a single pan would be a
                                                maximum of inr 1.99 lacs. in the
                                                event of any travel agency
                                                partner depositing more than inr
                                                1.99 lacs in a day, identifiable
                                                under a single pan, the full
                                                amount shall be retained by us.
                                                for any further clarification
                                                please contact us 0141-4041650.
                                          </strong>
                                    </p>
                              </DashboardCardNote>
                              <DashboardCardBody padding="10px 10px 30px">
                                    <DepositForm />
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default DepositRequest;
