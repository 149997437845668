import React from "react";
import { convertAmount, convertFloat } from "../../../../../hooks/CurrentData";

const PriceAddItem = ({ service, title, amount, icon }) => {
      return (
            <>
                  {title && (
                        <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-flex align-items-center">
                                    {icon}
                                    {service}
                                    {": "}
                                    {title}
                              </div>
                              <div>₹ {convertAmount(convertFloat(amount))}</div>
                        </div>
                  )}
            </>
      );
};

export default PriceAddItem;
