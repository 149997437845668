import React from "react";
import { PackageBoxContainer, PackageSwiper } from "../styles";
import { Container } from "react-bootstrap";
import CommonTitle from "../../../../Common/CommonTitle";
import { hotelPackageList } from "../../../../../data/banners";
import { SwiperSlide } from "swiper/react";
import {
      PackageCard,
      PackageImage,
      PackageWrapper,
} from "../PackageItem/styles";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {
      HotelPackageImage,
      HotelPackageItem,
      HotelPackageTitle,
} from "./style";

const HotelPackage = () => {
      return (
            <>
                  <PackageBoxContainer className="pt-0">
                        <Container>
                              <CommonTitle title={"Top Hotel Collections"} />
                              <PackageSwiper
                                    spaceBetween={20}
                                    loop={true}
                                    autoplay={{
                                          delay: 2500,
                                          disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                          640: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                          },
                                          768: {
                                                slidesPerView: 4,
                                                spaceBetween: 20,
                                          },
                                          1024: {
                                                slidesPerView: 4,
                                                spaceBetween: 30,
                                          },
                                    }}
                                    pagination={{
                                          clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                              >
                                    {hotelPackageList.map((item, index) => (
                                          <SwiperSlide key={index}>
                                                <HotelPackageItem>
                                                      <HotelPackageImage>
                                                            <img
                                                                  className=""
                                                                  src={
                                                                        item?.image
                                                                  }
                                                                  width="100%"
                                                                  height="100%"
                                                                  alt={
                                                                        item?.title
                                                                  }
                                                            />
                                                      </HotelPackageImage>
                                                      <HotelPackageTitle>
                                                            {item?.title}
                                                      </HotelPackageTitle>
                                                </HotelPackageItem>
                                          </SwiperSlide>
                                    ))}
                              </PackageSwiper>
                        </Container>
                  </PackageBoxContainer>
            </>
      );
};

export default HotelPackage;
