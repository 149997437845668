import styled from "styled-components";

export const FlightItemContainer = styled.div`
      padding-bottom: 30px;

      &:last-child {
            padding-bottom: 0;
      }
`;
export const FlightItemTitle = styled.h3`
      font-size: 15px;
      margin-bottom: 15px;
      font-weight: 600;
`;
export const FlightItemItem = styled.li`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      label {
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 10px;
      }
`;
export const FlightItemWrapper = styled.ul``;
export const FlightItemValue = styled.div`
      font-size: 13px;
      color: #666666;
`;
