import styled from "styled-components";

export const FrontImage = styled.div`
      position: absolute;
      height: 40px;
      top: 20px;
      left: 20px;
      z-index: 99999;

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
      }
`;

