import React from "react";
import {
      DashboardListContainer,
      DashboardListTitle,
} from "../../../Dashboard/DashboardList/styles";
import { DepartureListWrapper } from "../../../Dashboard/Departure/style";
import { FilterReturnItem } from "./style";

const FilterReturn = ({ returnAirlines, handleClick }) => {
      return (
            <>
                  <DashboardListContainer>
                        <DashboardListTitle>Return Special</DashboardListTitle>
                        <DepartureListWrapper
                              display="grid"
                              className="p-0 mb-0"
                        >
                              {returnAirlines?.map((item, index) => (
                                    <FilterReturnItem
                                          key={index}
                                          className={
                                                item.checked ? "active" : ""
                                          }
                                          onClick={() => handleClick(index)}
                                    >
                                          <div>
                                                <img
                                                      src={`/images/AirlinesLogo/${item?.code}.png`}
                                                      alt={item?.name}
                                                />
                                          </div>
                                    </FilterReturnItem>
                              ))}
                        </DepartureListWrapper>
                  </DashboardListContainer>
            </>
      );
};

export default FilterReturn;
