import styled from "styled-components";

export const TooltipWrapper = styled.div`
      position: relative;
`;

export const TooltipBox = styled.div`
      visibility: ${(props) => (props.visible ? "visible" : "hidden")};
      background-color: #af5e5f;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      top: ${(props) => (props.placement ? "5px" : "125%")};
      left: ${(props) => (props.placement ? "" : "50%")};
      transform: ${(props) =>
            props.placement ? "translateX(0%)" : "translateX(-50%)"};
      opacity: ${(props) => (props.visible ? 1 : 0)};
      transition: opacity 0.3s;
      white-space: nowrap;
      right: ${(props) => (props.placement ? "calc(100% + 10px)" : "")};

      &::after {
            content: "";
            position: absolute;
            bottom: ${(props) => (props.placement ? "35%" : "100%")};
            left: ${props => props.placement ? "100%" : "50%"};
            transform: ${props => props.placement ? "translateX(0%)" : "translateX(-50%)"};
            border-width: 5px;
            border-style: solid;
            border-color: ${props => props.placement ? "transparent transparent  transparent #af5e5f" : "transparent transparent #af5e5f transparent"};
      }
`;
