import React, { useState } from "react";
import {
      FlightPriceFilterContainer,
      FlightPriceFilterContent,
      FlightPriceFilterItem,
} from "./style";
import { BoltIcon } from "../../../icons";
import {
      calculateTimeDifference,
      convertAmount,
      convertFloat,
} from "../../../hooks/CurrentData";

const FlightPriceFilter = ({ filteredFlights, setFilteredFlights }) => {
      const [currentFilter, setCurrentFilter] = useState("Cheapest");
      const fastestFlight =
            filteredFlights &&
            filteredFlights
                  ?.slice() // Make a copy of the flights array to avoid mutating the original
                  .sort((a, b) => {
                        // Calculate the total duration of flight A
                        const departureTimeA = new Date(a.sI[0]?.dt); // First segment's departure time
                        const arrivalTimeA = new Date(
                              a.sI[a.sI.length - 1]?.at
                        ); // Last segment's arrival time
                        const durationA =
                              (arrivalTimeA - departureTimeA) / (1000 * 60); // Convert ms to minutes

                        // Calculate the total duration of flight B
                        const departureTimeB = new Date(b.sI[0]?.dt);
                        const arrivalTimeB = new Date(
                              b.sI[b.sI.length - 1]?.at
                        );
                        const durationB =
                              (arrivalTimeB - departureTimeB) / (1000 * 60);

                        return durationA - durationB; // Ascending order by total duration (fastest first)
                  });

      const cheapestFlights =
            filteredFlights &&
            filteredFlights
                  ?.slice() // Copy the flights array
                  .sort((a, b) => {
                        const priceA =
                              a.totalPriceList[0]?.fd?.ADULT?.fC?.TF || 0; // Extract the first flight price
                        const priceB =
                              b.totalPriceList[0]?.fd?.ADULT?.fC?.TF || 0; // Handle cases with undefined prices by falling back to 0

                        return priceA - priceB; // Ascending order by price
                  });

      const handleFilter = (title) => {
            if (title === "Cheapest") {
                  setFilteredFlights(cheapestFlights);
            }
            if (title === "Fastest") {
                  setFilteredFlights(fastestFlight);
            }
            setCurrentFilter(title);
      };

      const getFlightDuration = (flight) => {
            const firstSegment = flight.sI[0];
            const lastSegment = flight.sI[flight.sI.length - 1];
            const duration = calculateTimeDifference(
                  firstSegment?.dt,
                  lastSegment?.at
            );
            return `${duration.hours}h ${duration.minutes}m`;
      };

      const fastestFlightPriceList = fastestFlight[0]?.totalPriceList?.filter(
            (item) => {
                  return item?.fd?.ADULT?.fC?.TF; // Ensure TF exists
            }
      );

      const minTF = Math.min(
            ...fastestFlightPriceList?.map(
                  (item) => item?.fd?.ADULT?.fC?.TF || Infinity
            )
      );

      return (
            <>
                  <FlightPriceFilterContainer>
                        <FlightPriceFilterContent>
                              <FlightPriceFilterItem
                                    className={
                                          currentFilter === "Cheapest"
                                                ? "active"
                                                : ""
                                    }
                                    onClick={() => handleFilter("Cheapest")}
                              >
                                    <div className="price__filter-icon">₹</div>
                                    <div className="price__filter-info">
                                          <div className="price__filter-title">
                                                Cheapest
                                          </div>
                                          <div className="price__filter-duration text-muted">
                                                Duration:{" "}
                                                {getFlightDuration(
                                                      cheapestFlights[0]
                                                )}
                                          </div>
                                    </div>
                              </FlightPriceFilterItem>
                              <FlightPriceFilterItem
                                    className={
                                          currentFilter === "Fastest"
                                                ? "active"
                                                : ""
                                    }
                                    onClick={() => handleFilter("Fastest")}
                              >
                                    <div className="price__filter-icon">
                                          <BoltIcon />
                                    </div>
                                    <div className="price__filter-info">
                                          <div className="price__filter-title">
                                                Fastest
                                          </div>
                                          <div className="price__filter-duration text-muted">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(minTF)
                                                )}{" "}
                                                ・ Duration:{" "}
                                                {getFlightDuration(
                                                      fastestFlight[0]
                                                )}
                                          </div>
                                    </div>
                              </FlightPriceFilterItem>
                        </FlightPriceFilterContent>
                  </FlightPriceFilterContainer>
            </>
      );
};

export default FlightPriceFilter;
