import React from "react";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightAirlineTitle,
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
      FlightDetailTitle,
      FlightLayoverContainer,
      FlightLayoverContent,
      FlightLayoverLine,
      FlightLayoverTitle,
      FlightRefund,
} from "./style";
import { FlightInfoLogo } from "../FlightInfo/styles";
import { format } from "date-fns";

const FlightDetails = ({ items }) => {
      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE - dd MMM yyyy");
      };

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const calculateTimeDifference = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return calculateDuration(totalMinutes);
      };

      return (
            <>
                  <FlightDetailContainer>
                        <FlightDetailCategory>
                              Departing Flight
                        </FlightDetailCategory>
                        <FlightDetailInfo>
                              {items?.sI?.map((item, index) => (
                                    <>
                                          {index === 0 && (
                                                <FlightDetailTitle>
                                                      {item?.da?.city} -{" "}
                                                      {
                                                            items?.sI[
                                                                  items?.sI
                                                                        ?.length -
                                                                        1
                                                            ]?.aa?.city
                                                      }{" "}
                                                      |{" "}
                                                      <span>
                                                            {convertDate(
                                                                  item?.at
                                                            )}
                                                      </span>
                                                </FlightDetailTitle>
                                          )}
                                          <FlightDetailAirline>
                                                <FlightAirline>
                                                      <FlightInfoLogo>
                                                            <img
                                                                  src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                  alt={
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            />
                                                      </FlightInfoLogo>
                                                      <FlightAirlineTitle>
                                                            <div>
                                                                  {
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            </div>
                                                            <span>
                                                                  {
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.code
                                                                  }{" "}
                                                                  {item?.fD?.fN}
                                                            </span>
                                                            {/* <span>ECONOMY</span> */}
                                                      </FlightAirlineTitle>
                                                </FlightAirline>
                                                <FlightAirlineTime>
                                                      <div>
                                                            {formatTime(
                                                                  item?.dt
                                                            )}
                                                      </div>
                                                      <span>
                                                            {item?.da?.city} (
                                                            {item?.da?.code})
                                                      </span>
                                                      <span>
                                                            {convertDate(
                                                                  item?.dt
                                                            )}
                                                      </span>
                                                      <span>
                                                            {item?.da?.terminal}
                                                      </span>
                                                </FlightAirlineTime>
                                                <FlightRefund>
                                                      <div className="flight__duration">
                                                            {`${
                                                                  calculateDuration(
                                                                        item?.duration
                                                                  ).hours
                                                            }h ${
                                                                  calculateDuration(
                                                                        item?.duration
                                                                  ).minutes
                                                            }m`}
                                                      </div>
                                                      <div className="line"></div>
                                                      {/* <div class="flight__refund">
                                                            REFUNDABLE
                                                      </div> */}
                                                </FlightRefund>
                                                <FlightAirlineTime>
                                                      <div>
                                                            {formatTime(
                                                                  item?.at
                                                            )}
                                                      </div>
                                                      <span>
                                                            {item?.aa?.city} (
                                                            {item?.aa?.code})
                                                      </span>
                                                      <span>
                                                            {convertDate(
                                                                  item?.at
                                                            )}
                                                      </span>
                                                      <span>
                                                            {item?.aa?.terminal}
                                                      </span>
                                                </FlightAirlineTime>
                                          </FlightDetailAirline>
                                          {items?.sI?.length > 1 &&
                                                items?.sI.length !==
                                                      index + 1 && (
                                                      <FlightLayoverContainer>
                                                            <FlightLayoverLine />
                                                            <FlightLayoverContent>
                                                                  <FlightLayoverTitle
                                                                        fontWeight="600"
                                                                        color="#224082"
                                                                  >
                                                                        Plane
                                                                        Change
                                                                  </FlightLayoverTitle>
                                                                  <FlightLayoverTitle
                                                                        fontSize="0.875rem"
                                                                        color="#555"
                                                                  >
                                                                        <span>
                                                                              Layover
                                                                              at
                                                                              {` `}
                                                                              {
                                                                                    item
                                                                                          ?.aa
                                                                                          ?.city
                                                                              }
                                                                              ,
                                                                              {` ${
                                                                                    calculateTimeDifference(
                                                                                          item?.at,
                                                                                          items
                                                                                                ?.sI[
                                                                                                index +
                                                                                                      1
                                                                                          ]
                                                                                                ?.dt
                                                                                    )
                                                                                          .hours
                                                                              }h ${
                                                                                    calculateTimeDifference(
                                                                                          item?.at,
                                                                                          items
                                                                                                ?.sI[
                                                                                                index +
                                                                                                      1
                                                                                          ]
                                                                                                ?.dt
                                                                                    )
                                                                                          .minutes
                                                                              }m`}
                                                                        </span>
                                                                  </FlightLayoverTitle>
                                                            </FlightLayoverContent>
                                                      </FlightLayoverContainer>
                                                )}
                                    </>
                              ))}
                        </FlightDetailInfo>
                  </FlightDetailContainer>
            </>
      );
};

export default FlightDetails;
