import React, { useContext } from "react";
import {
      BreadcrumbContainer,
      DashboardBreadcrumb,
      DashboardContainer,
      DashboardContent,
      DashboardMainContainer,
} from "./style";
import { Breadcrumb } from "react-bootstrap";
import { MenuList } from "./menus";
import UserContext from "../../context/UserContext";

const DashboardLayout = ({ title, children }) => {
      const profileCtx = useContext(UserContext);

      return (
            <>
                  <DashboardContainer>
                        <BreadcrumbContainer>
                              <Breadcrumb>
                                    <Breadcrumb.Item href="#">
                                          Home
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="/my-account">
                                          My Account
                                    </Breadcrumb.Item>
                              </Breadcrumb>
                              <h2>{title}</h2>
                        </BreadcrumbContainer>
                        <DashboardMainContainer>
                              <DashboardBreadcrumb className="p-0">
                                    <MenuList />
                              </DashboardBreadcrumb>
                              <DashboardContent className="p-0">
                                    {children}
                              </DashboardContent>
                        </DashboardMainContainer>
                  </DashboardContainer>
            </>
      );
};

export default DashboardLayout;
