import styled from "styled-components";

export const TicketContainer = styled.div`
      padding-top: ${(props) => props.padding || "164px"};
`;

export const FlightIcon = styled.tr`
      svg {
            width: 24px;
            height: 24px;
      }

      strong {
            margin-left: 10px;
      }
`;

export const ThirdContent = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;

      .canvas {
            height: 70px;
      }

      span {
            font-size: 14px;
            color: #666;
      }

      .third-content__baggage {
            font-size: 14px;

            div {
                  line-height: 1.3;
            }
      }

      .third-content__pax {
            font-size: 14px;
      }
`;

export const FlightInfo = styled.td`
      display: flex;
      gap: 10px;
`;

export const FlightTable = styled.table`
      th {
            padding: 10px;
            text-align: left;
            border: none;
            align-items: center;
      }

      td {
            padding: ${(props) => (props.length === 1 ? "10px" : "5px 10px")};
            padding-bottom: ${(props) => props.length !== 1 && "0"};
            text-align: left;
            border: none;
            vertical-align: middle;

            &:first-child {
                  padding-top: ${(props) => props.length !== 1 && "0"};
            }
      }

      tr {
            &:last-child {
                  td {
                        padding-bottom: 10px;
                  }
            }
      }

      .ticket__number {
            font-size: 15px;
      }
`;

export const CardDescription = styled.div`
      padding-left: 10px;
      line-height: 2;
      margin-top: 10px;

      .card-title {
            margin-bottom: 5px;
      }

      ul {
            padding: 0;
            margin-left: 12px;

            li {
                  font-size: 14px;
                  line-height: 1.5;
            }
      }
`;
