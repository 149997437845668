import styled from "styled-components";
import { Swiper } from "swiper/react";

export const BannerSwiper = styled(Swiper)`
      .swiper-pagination {
            margin-top: 20%;
      }
`;

export const BannerImage = styled.div`
      height: 240px;

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
      }
`;

export const BannerTitle = styled.div`
      text-align: center;
      font-size: 20px;
      color: ${(props) => props.theme.primary};
`;
