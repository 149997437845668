import React from "react";
import { Col, FormControl, FormGroup, FormLabel } from "react-bootstrap";

const FormInput = ({
      title,
      type,
      required,
      handleChange,
      classes,
      disabled,
      value,
      isValidate,
      isValid,
      setIsValid,
}) => {
      const handlePhoneNumberChange = (event) => {
            const { value } = event.target;

            if (isValidate === true) {
                  if (/^\d{0,10}$/.test(value)) {
                        handleChange(value);

                        // Validate if it has exactly 10 digits
                        setIsValid(value.length === 10);
                  }
            } else {
                  handleChange(value);
            }
      };
      return (
            <>
                  <Col lg={classes || 3}>
                        <FormGroup>
                              <FormLabel>
                                    {title}
                                    {required && (
                                          <span className="text-danger">
                                                {" "}
                                                *
                                          </span>
                                    )}
                              </FormLabel>
                              <FormControl
                                    type={type || "text"}
                                    required={required}
                                    disabled={disabled}
                                    value={value}
                                    onChange={handlePhoneNumberChange}
                              />
                              {isValidate && !isValid && value.length > 0 && (
                                    <p style={{ color: "red" }}>
                                          Phone number must be exactly 10
                                          digits.
                                    </p>
                              )}
                        </FormGroup>
                  </Col>
            </>
      );
};

export default FormInput;
