import React, { useEffect, useState } from "react";
import { TravellerModal } from "../../../components/Frontend/flight_reviews/FlightReviewDetail/TravellerReviewDetail/style";
import { ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import { FlightModal } from "../../../components/Frontend/checkout/PriceSummaryBox/PriceFlightModal/style";
import axios from "axios";
import {
      FlightTripAirline,
      FlightTripAirlineTime,
      FlightTripAirlineTitle,
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
      FlightTripDetailTitle,
} from "../../../components/Frontend/search/FlightTripDetails/style";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";

// const farePrice
const splitDetails = (policyString) => {
      const policyList = policyString.split("__nls__");
      return policyList;
};

const BookingRule = ({
      bookingId,
      fareId,
      leavingFrom,
      destination,
      refundStatus,
}) => {
      const [show, setShow] = useState(false);
      const [fareDetails, setFareDetails] = useState(false);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/farerule`,
                        {
                              id: bookingId,
                              flowType: "BOOKING_DETAIL",
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setFareDetails(response.data?.fareRule);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [bookingId]);

      return (
            <>
                  <TravellerModal fontSize="16px" onClick={() => setShow(true)}>
                        Fare Rule
                  </TravellerModal>
                  <FlightModal
                        show={show}
                        size="lg"
                        top="18px"
                        onHide={() => setShow(false)}
                  >
                        <ModalHeader closeButton>
                              Fare Rules Details
                        </ModalHeader>
                        <ModalBody>
                              <FlightTripDetailContainer>
                                    <FlightTripDetailCategory>{`${leavingFrom} - ${destination}`}</FlightTripDetailCategory>
                                    {!loading ? (
                                          <>
                                                <FlightTripDetailInfo>
                                                      {refundStatus ? (
                                                            <>
                                                                  <FlightTripDetailAirline>
                                                                        {fareDetails ? (
                                                                              <>
                                                                                    <FlightTripAirlineTime width="25%">
                                                                                          <div>
                                                                                                Type
                                                                                          </div>
                                                                                          <span>
                                                                                                Date
                                                                                                Change
                                                                                          </span>
                                                                                    </FlightTripAirlineTime>
                                                                                    <FlightTripAirline width="20%">
                                                                                          <FlightTripAirlineTitle>
                                                                                                <div>
                                                                                                      Amount
                                                                                                </div>
                                                                                                <span>
                                                                                                      ₹{" "}
                                                                                                      {fareDetails[
                                                                                                            `${leavingFrom}-${destination}`
                                                                                                      ]
                                                                                                            ?.fr
                                                                                                            ?.DATECHANGE
                                                                                                            ?.DEFAULT
                                                                                                            ?.amount
                                                                                                            ? convertAmount(
                                                                                                                    convertFloat(
                                                                                                                          fareDetails[
                                                                                                                                `${leavingFrom}-${destination}`
                                                                                                                          ]
                                                                                                                                ?.fr
                                                                                                                                ?.DATECHANGE
                                                                                                                                ?.DEFAULT
                                                                                                                                ?.amount
                                                                                                                    )
                                                                                                              )
                                                                                                            : convertAmount(
                                                                                                                    convertFloat(
                                                                                                                          fareDetails[
                                                                                                                                `${leavingFrom}-${destination}`
                                                                                                                          ]
                                                                                                                                ?.fr
                                                                                                                                ?.DATECHANGE
                                                                                                                                ?.BEFORE_DEPARTURE
                                                                                                                                ?.amount
                                                                                                                    )
                                                                                                              )}
                                                                                                </span>
                                                                                          </FlightTripAirlineTitle>
                                                                                    </FlightTripAirline>
                                                                                    <FlightTripAirlineTime width="55%">
                                                                                          <div>
                                                                                                Policy
                                                                                          </div>
                                                                                          <span>
                                                                                                {fareDetails[
                                                                                                      `${leavingFrom}-${destination}`
                                                                                                ]
                                                                                                      ?.fr
                                                                                                      ?.DATECHANGE
                                                                                                      ?.DEFAULT
                                                                                                      ?.policyInfo
                                                                                                      ? splitDetails(
                                                                                                              fareDetails[
                                                                                                                    `${leavingFrom}-${destination}`
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.DATECHANGE
                                                                                                                    ?.DEFAULT
                                                                                                                    ?.policyInfo
                                                                                                        )?.map(
                                                                                                              (
                                                                                                                    policy,
                                                                                                                    index
                                                                                                              ) =>
                                                                                                                    policy && (
                                                                                                                          <li
                                                                                                                                key={
                                                                                                                                      index
                                                                                                                                }
                                                                                                                          >
                                                                                                                                {
                                                                                                                                      policy
                                                                                                                                }
                                                                                                                          </li>
                                                                                                                    )
                                                                                                        )
                                                                                                      : fareDetails[
                                                                                                              `${leavingFrom}-${destination}`
                                                                                                        ]
                                                                                                              ?.fr
                                                                                                              ?.DATECHANGE
                                                                                                              ?.BEFORE_DEPARTURE
                                                                                                              ?.policyInfo}
                                                                                          </span>
                                                                                    </FlightTripAirlineTime>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <FlightTripAirlineTime width="100%">
                                                                                          <span className="text-danger">
                                                                                                No
                                                                                                Refundable
                                                                                          </span>
                                                                                    </FlightTripAirlineTime>
                                                                              </>
                                                                        )}
                                                                  </FlightTripDetailAirline>

                                                                  {fareDetails && (
                                                                        <FlightTripDetailAirline marginTop="1.5rem">
                                                                              <FlightTripAirlineTime width="25%">
                                                                                    <span>
                                                                                          CANCELLATION
                                                                                    </span>
                                                                              </FlightTripAirlineTime>
                                                                              <FlightTripAirline width="20%">
                                                                                    <FlightTripAirlineTitle>
                                                                                          <span>
                                                                                                {`₹ ${
                                                                                                      fareDetails[
                                                                                                            `${leavingFrom}-${destination}`
                                                                                                      ]
                                                                                                            ?.fr
                                                                                                            ?.CANCELLATION
                                                                                                            ?.DEFAULT
                                                                                                            ?.amount
                                                                                                            ? convertAmount(
                                                                                                                    convertFloat(
                                                                                                                          fareDetails[
                                                                                                                                `${leavingFrom}-${destination}`
                                                                                                                          ]
                                                                                                                                ?.fr
                                                                                                                                ?.CANCELLATION
                                                                                                                                ?.DEFAULT
                                                                                                                                ?.amount
                                                                                                                    )
                                                                                                              )
                                                                                                            : convertAmount(
                                                                                                                    convertFloat(
                                                                                                                          fareDetails[
                                                                                                                                `${leavingFrom}-${destination}`
                                                                                                                          ]
                                                                                                                                ?.fr
                                                                                                                                ?.CANCELLATION
                                                                                                                                ?.BEFORE_DEPARTURE
                                                                                                                                ?.amount
                                                                                                                    )
                                                                                                              )
                                                                                                }`}
                                                                                          </span>
                                                                                    </FlightTripAirlineTitle>
                                                                              </FlightTripAirline>
                                                                              <FlightTripAirlineTime width="55%">
                                                                                    <span>
                                                                                          {fareDetails[
                                                                                                `${leavingFrom}-${destination}`
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.CANCELLATION
                                                                                                ?.DEFAULT
                                                                                                ?.policyInfo
                                                                                                ? splitDetails(
                                                                                                        fareDetails[
                                                                                                              `${leavingFrom}-${destination}`
                                                                                                        ]
                                                                                                              ?.fr
                                                                                                              ?.CANCELLATION
                                                                                                              ?.DEFAULT
                                                                                                              ?.policyInfo
                                                                                                  )?.map(
                                                                                                        (
                                                                                                              policy,
                                                                                                              index
                                                                                                        ) =>
                                                                                                              policy && (
                                                                                                                    <li
                                                                                                                          key={
                                                                                                                                index
                                                                                                                          }
                                                                                                                    >
                                                                                                                          {
                                                                                                                                policy
                                                                                                                          }
                                                                                                                    </li>
                                                                                                              )
                                                                                                  )
                                                                                                : fareDetails[
                                                                                                        `${leavingFrom}-${destination}`
                                                                                                  ]
                                                                                                        ?.fr
                                                                                                        ?.CANCELLATION
                                                                                                        ?.BEFORE_DEPARTURE
                                                                                                        ?.policyInfo}
                                                                                    </span>
                                                                              </FlightTripAirlineTime>
                                                                        </FlightTripDetailAirline>
                                                                  )}
                                                            </>
                                                      ) : (
                                                            <>
                                                                  <FlightTripAirlineTime width="100%">
                                                                        <span className="text-danger">
                                                                              No
                                                                              Refundable
                                                                        </span>
                                                                  </FlightTripAirlineTime>
                                                            </>
                                                      )}
                                                </FlightTripDetailInfo>
                                                {fareDetails &&
                                                      fareId?.fd?.ADULT?.rT && (
                                                            <FlightTripDetailAirline
                                                                  className="mt-2"
                                                                  flexDirection="column"
                                                            >
                                                                  <FlightTripDetailTitle flexDirection="column">
                                                                        Provider
                                                                        Cancellation
                                                                        Fee:
                                                                  </FlightTripDetailTitle>
                                                                  <div className="text-muted">
                                                                        109 for
                                                                        domestic
                                                                        (per
                                                                        person)
                                                                  </div>
                                                                  <div className="text-muted">
                                                                        218 for
                                                                        international
                                                                        (per
                                                                        person)
                                                                  </div>
                                                            </FlightTripDetailAirline>
                                                      )}
                                          </>
                                    ) : (
                                          <>
                                                <FlightTripDetailInfo>
                                                      <div className="w-100 text-center mb-3">
                                                            <Spinner />
                                                      </div>
                                                </FlightTripDetailInfo>
                                          </>
                                    )}
                              </FlightTripDetailContainer>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default BookingRule;
