import React, { useEffect, useState } from "react";
import {
      FlightModalButton,
      FlightModalContainer,
      FlightModalContent,
} from "./style";
import "react-day-picker/dist/style.css";
import FlightModalItems from "./FlightModalItems";
import FlightModalDate from "./FlightModalDate";
import FlightModalDepart from "./FlightModalDepart";
import FlightModalType from "./FlightModalType";
import FlightModalTraveller from "./FlightModalTraveller";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const FlightModal = ({ searchDetail, searchDetails }) => {
      const [modifySearch, setModifySearch] = useState();
      const [modifyStatus, setModifyStatus] = useState(false);
      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      useEffect(() => {
            setModifySearch(searchDetail);
      }, []);

      const handleChange = (data, inputName) => {
            setModifySearch((prevDetails) => ({
                  ...prevDetails,
                  [inputName]: data,
            }));
      };

      useEffect(() => {
            if (isEqual(searchDetail, modifySearch)) {
                  setModifyStatus(false);
            } else {
                  setModifyStatus(true);
            }
      }, [modifySearch]);

      const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            const data = {
                  leavingFrom: modifySearch?.leavingFrom,
                  destination: modifySearch?.destination,
                  depart: modifySearch?.departDate,
                  returnDate:
                        modifySearch?.returnStatus === "false"
                              ? ""
                              : modifySearch?.returnDate,
                  adult: modifySearch?.adult,
                  children: modifySearch?.children,
                  className: modifySearch?.cabinClass,
                  infants: modifySearch?.infants,
                  returnStatus: modifySearch?.returnStatus,
                  pft: modifySearch?.pft || "REGULAR",
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/search?${queryString}`);
                  window.location.reload();
            }, 3000);
      };

      return (
            <>
                  <FlightModalContainer>
                        <FlightModalContent>
                              <FlightModalItems
                                    searchDetail={modifySearch}
                                    handleChange={handleChange}
                              />

                              <FlightModalDepart
                                    modifySearch={modifySearch}
                                    searchDetail={searchDetail}
                                    searchDetails={searchDetails}
                                    handleChange={handleChange}
                              />

                              <FlightModalDate
                                    searchDetail={modifySearch}
                                    handleChange={handleChange}
                              />

                              <FlightModalTraveller
                                    searchDetail={modifySearch}
                                    handleChange={handleChange}
                              />

                              <FlightModalType
                                    searchDetail={modifySearch}
                                    handleChange={handleChange}
                              />

                              <FlightModalButton
                                    width="17%"
                                    status={modifyStatus}
                                    type="button"
                                    onClick={handleSubmit}
                              >
                                    {loading ? <Spinner /> : "Modify Search"}
                              </FlightModalButton>
                        </FlightModalContent>
                  </FlightModalContainer>
            </>
      );
};

export default FlightModal;
