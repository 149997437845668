import React from "react";
import { TooltipBox, TooltipWrapper } from "./style";

const CheckError = ({ children, errorStatus, errorMessage, placement }) => {
      return (
            <>
                  <TooltipWrapper>
                        {children}
                        <TooltipBox placement={placement} visible={errorStatus}>
                              {errorMessage}
                        </TooltipBox>
                  </TooltipWrapper>
            </>
      );
};

export default CheckError;
