import React, { useState, useRef } from "react";
import {
      ChevronIcon,
      CustomSelectContainer,
      SelectButton,
      SelectList,
      SelectListItem,
} from "../CustomSelect/styles";

const CustomClass = ({
      options,
      searchDetail,
      handleChange,
}) => {
      const [isOpen, setIsOpen] = useState(false);
      const selectRef = useRef();

      const handleSelectClick = () => {
            setIsOpen(!isOpen);
      };

      const handleOptionClick = (option) => {
            handleChange(option?.value, "cabinClass");
            setIsOpen(false);
      };

      const handleClickOutside = (event) => {
            if (
                  selectRef.current &&
                  !selectRef.current.contains(event.target)
            ) {
                  setIsOpen(false);
            }
      };

      React.useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, []);

      const currentItem = options?.find(
            (item) => item?.value === searchDetail?.cabinClass
      );

      return (
            <CustomSelectContainer ref={selectRef}>
                  <SelectButton onClick={handleSelectClick} className="d-flex">
                        <div className="selected">
                              {currentItem ? currentItem.label : ''}
                        </div>
                        <ChevronIcon>
                              <i className="bx bx-chevron-down"></i>
                        </ChevronIcon>
                  </SelectButton>

                  {isOpen && (
                        <SelectList className="options-list" isOpen={isOpen}>
                              {options.map((option, index) => (
                                    <SelectListItem
                                          key={index}
                                          className="option"
                                          onClick={() =>
                                                handleOptionClick(option)
                                          }
                                    >
                                          {option.label}
                                    </SelectListItem>
                              ))}
                        </SelectList>
                  )}
            </CustomSelectContainer>
      );
};

export default CustomClass;
