import React from "react";
import { FlightIcon, FlightInfo } from "../style";
import { FlightInfoLogo } from "../../../components/Dashboard/FlightInfo/styles";
import { calculateTimeDifference, formatTime } from "../../../hooks/CurrentData";
import { convertDate } from "../OnewayTicket";
import { PlaneLandingIcon, PlaneTookOffIcon } from "../../../icons";

const TicketFlightDetail = ({ tripInfos }) => {
      return (
            <>
                  <div className="card mb-3">
                        <div className="">
                              <div className="card-title-h">
                                    <h5 className="card-title mb-0">
                                          FLIGHT DETAILS
                                    </h5>
                                    <p className="card-subtitle mb-0">
                                          ALL TIMINGS MENTIONED ARE IN 24HRS
                                          FORMAT AND LOCAL AIRPORT TIMINGS AT
                                          THE DEPARTURE/ARRIVAL AIRPORT.
                                    </p>
                              </div>
                              <table className="flight-details-table">
                                    <thead>
                                          <tr>
                                                <th>Flight</th>
                                                <th>Depart</th>
                                                <th>Arrive</th>
                                                <th>Duration/Stops</th>
                                                <th>Status</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {tripInfos[0]?.sI?.map(
                                                (item, index) => (
                                                      <>
                                                            {tripInfos[0]?.sI
                                                                  ?.length >
                                                                  1 && (
                                                                  <>
                                                                        <FlightIcon className="no-border">
                                                                              <td colSpan="5">
                                                                                    {index ===
                                                                                    0 ? (
                                                                                          <PlaneTookOffIcon />
                                                                                    ) : (
                                                                                          <PlaneLandingIcon />
                                                                                    )}
                                                                                    <strong>
                                                                                          {index ===
                                                                                          0
                                                                                                ? "ONWARD "
                                                                                                : "RETURN "}
                                                                                          JOURNEY
                                                                                    </strong>
                                                                              </td>
                                                                        </FlightIcon>
                                                                  </>
                                                            )}
                                                            <tr>
                                                                  <FlightInfo className="flight-info">
                                                                        <FlightInfoLogo>
                                                                              <img
                                                                                    src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                                    alt={
                                                                                          item
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.name
                                                                                    }
                                                                              />
                                                                        </FlightInfoLogo>
                                                                        <div className="details">
                                                                              <strong>
                                                                                    {`${item?.fD?.aI?.code}-${item?.fD?.fN}`}
                                                                              </strong>
                                                                              <br />
                                                                              <span className="text-muted">
                                                                                    {
                                                                                          item
                                                                                                ?.bI
                                                                                                ?.tI[
                                                                                                index
                                                                                          ]
                                                                                                ?.fd
                                                                                                ?.cc
                                                                                    }
                                                                              </span>

                                                                              <span
                                                                                    className={`refundable ${
                                                                                          item
                                                                                                ?.bI
                                                                                                ?.tI[
                                                                                                index
                                                                                          ]
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.rT
                                                                                                ? "text-success"
                                                                                                : "text-muted"
                                                                                    }`}
                                                                              >
                                                                                    {item
                                                                                          ?.bI
                                                                                          ?.tI[
                                                                                          index
                                                                                    ]
                                                                                          ?.fd
                                                                                          ?.ADULT
                                                                                          ?.rT
                                                                                          ? "Refundable"
                                                                                          : "Non Refundable"}
                                                                              </span>
                                                                        </div>
                                                                  </FlightInfo>
                                                                  <td className="details">
                                                                        <strong>
                                                                              {
                                                                                    item
                                                                                          ?.da
                                                                                          ?.city
                                                                              }{" "}
                                                                              (
                                                                              {
                                                                                    item
                                                                                          ?.da
                                                                                          ?.code
                                                                              }
                                                                              )
                                                                        </strong>
                                                                        <br />
                                                                        <strong>
                                                                              {formatTime(
                                                                                    item?.dt
                                                                              )}{" "}
                                                                        </strong>
                                                                        {convertDate(
                                                                              item?.dt
                                                                        )}
                                                                        <br />
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.terminal
                                                                        }
                                                                  </td>
                                                                  <td className="details">
                                                                        <strong>
                                                                              {
                                                                                    item
                                                                                          ?.aa
                                                                                          ?.city
                                                                              }{" "}
                                                                              (
                                                                              {
                                                                                    item
                                                                                          ?.aa
                                                                                          ?.code
                                                                              }
                                                                              )
                                                                        </strong>
                                                                        <br />
                                                                        <strong>
                                                                              {formatTime(
                                                                                    item?.at
                                                                              )}{" "}
                                                                        </strong>
                                                                        {convertDate(
                                                                              item?.at
                                                                        )}
                                                                        <br />
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.terminal
                                                                        }
                                                                  </td>
                                                                  <td className="details">
                                                                        <strong>
                                                                              {`${
                                                                                    calculateTimeDifference(
                                                                                          item?.dt,
                                                                                          item?.at
                                                                                    )
                                                                                          .hours
                                                                              }:${
                                                                                    calculateTimeDifference(
                                                                                          item?.dt,
                                                                                          item?.at
                                                                                    )
                                                                                          .minutes
                                                                              }`}
                                                                        </strong>
                                                                  </td>
                                                                  <td className="status-info">
                                                                        Confirmed
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )
                                          )}
                                    </tbody>
                              </table>
                        </div>
                  </div>
            </>
      );
};

export default TicketFlightDetail;
