import React from "react";

const PersonalInfoIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        account_circle
                  </span>
            </>
      );
};

export default PersonalInfoIcon;
