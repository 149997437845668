import styled from "styled-components";

export const CommonButtonContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: ${(props) => props.justifyContent};

      a,
      button {
            padding: 0.6rem 1rem;
            background: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
            text-transform: capitalize;
            font-size: 16px;
            border-radius: 30px;
            text-align: center;
            transition: all 0.3s ease-in;
            border: none;
            min-width: ${(props) => (props.width ? props.width : "150px")};
            padding: ${(props) => props.padding};
            font-size: ${(props) => props.fontSize};

            &:hover {
                  background: radial-gradient(
                        160.53% 539.54% at 11.79% -14.47%,
                        #224082 0%,
                        #070e1c 100%
                  );
                  color: ${(props) => props.theme.white};
            }
      }

      .spinner-border {
            width: 16px !important;
            height: 16px !important;
            border-width: 1px;
            margin-left: 10px;
      }
`;
