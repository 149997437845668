import styled from "styled-components";

export const DepartureListWrapper = styled.ul`
      display: ${(props) => props.display || "flex"};
      justify-content: space-between;
      gap: 10px;
      grid-template-columns: ${(props) => props.display && "repeat(4, 1fr)"};
`;

export const DepartureListItem = styled.li`
      position: relative;
      display: flex;
      flex-direction: column;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 2.5px;
      min-width: 22%;

      div:last-child {
            font-size: 10px;
            margin-top: -5px;
            font-weight: 600;
      }

      img {
            width: 40px;
            height: 40px;
            object-fit: contain;
      }

      input {
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: 1;
            position: absolute;
      }

      &.active {
            background-color: ${(props) => props.theme.primary};

            .text-muted {
                  color: ${(props) => props.theme.white} !important;
            }
      }
`;
