import React, { useEffect, useState } from "react";
import {
      DashboardContainer,
      DashboardContent,
      DashboardFlightRanges,
      DashboardFlightWrapper,
      DashboardMenu,
      DashboardMenuContent,
      FilterContainer,
} from "../dashboard/styles";
import { arrivals, departures, stops } from "../../data/dashboard";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FlightLoader from "../../components/Common/FlightLoader";
import FlightModal from "./FlightModal";
import {
      FlightHeader,
      FlightHeaderContent,
      FlightHeaderInfo,
      FlightModalInfo,
} from "./style";
import {
      OnewayFlight,
      RoundtripFlight,
} from "../../components/Frontend/search";
import toast from "react-hot-toast";
import OnewayFilter from "../../components/Frontend/flight/OnewayFilter";
import { RoundtripFilter } from "../../components/Frontend/flight";
import {
      RoundtripFilterItem,
      RoundtripFilterContainer,
} from "../../components/Frontend/flight/RoundtripFilter/style";
import { convertDate } from "../../hooks/CurrentData";

const FlightSearch = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);

      const [searchDetail, setSearchDetail] = useState({
            leavingFrom: searchParams.get("leavingFrom"),
            destination: searchParams.get("destination"),
            departDate: searchParams.get("depart"),
            adult: searchParams.get("adult"),
            infants: searchParams.get("infants"),
            children: searchParams.get("children"),
            returnDate: searchParams.get("returnDate"),
            returnStatus: searchParams.get("returnStatus"),
            pft: searchParams.get("pft"),
            cabinClass: searchParams.get("className"),
      });

      const [loading, setLoading] = useState(false);
      const [flights, setFlights] = useState([]);
      const [returnAirlines, setReturnAirlines] = useState([]);
      const [filteredFlights, setFilteredFlights] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));
      const [netFare, setNetFare] = useState(false);
      const [fullFare, setFullFare] = useState(false);
      const [searchDetails, setSearchDetails] = useState([]);
      const [totalFlights, setTotalFlights] = useState(0);
      const [returnFlights, setReturnFlights] = useState([]);
      const [returnFilterFlights, setReturnFilterFlights] = useState([]);

      let searchData;
      let pftDetail;
      if (
            searchDetail?.pft === "STUDENT" ||
            searchDetail?.pft === "SENIOR_CITIZEN"
      ) {
            if (
                  searchDetail?.children === "0" &&
                  searchDetail?.infants === "0"
            ) {
                  pftDetail = {
                        pft: searchDetail?.pft,
                  };
            } else {
                  toast.error(
                        `You cannot select ${searchDetail?.pft} with child/infant.`
                  );
            }
      } else {
            pftDetail = {
                  isDirectFlight: true,
                  isConnectingFlight: true,
            };
      }
      if (searchDetail?.returnStatus === "true") {
            searchData = {
                  searchQuery: {
                        cabinClass: searchDetail?.cabinClass,
                        paxInfo: {
                              ADULT: searchDetail?.adult,
                              CHILD: searchDetail?.children,
                              INFANT: searchDetail?.infants,
                        },
                        routeInfos: [
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    travelDate: searchDetail?.departDate,
                              },
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    travelDate: searchDetail?.returnDate,
                              },
                        ],
                        searchModifiers: pftDetail,
                  },
            };
      } else {
            searchData = {
                  searchQuery: {
                        cabinClass: searchDetail?.cabinClass,
                        paxInfo: {
                              ADULT: searchDetail?.adult,
                              CHILD: searchDetail?.children,
                              INFANT: searchDetail?.infants,
                        },
                        routeInfos: [
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    travelDate: searchDetail?.departDate,
                              },
                        ],
                        searchModifiers: pftDetail,
                  },
            };
      }

      const handleData = async () => {
            setLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/air-search-all`,
                        searchData,
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.status?.success === true) {
                                    if (
                                          response.data.searchResult?.tripInfos
                                                ?.ONWARD
                                    ) {
                                          const flightData =
                                                response.data.searchResult
                                                      ?.tripInfos?.ONWARD;
                                          const economyFlights =
                                                flightData &&
                                                flightData
                                                      .filter((item) =>
                                                            item.totalPriceList.some(
                                                                  (price) =>
                                                                        price.fd
                                                                              .ADULT
                                                                              .cc ===
                                                                        searchDetail?.cabinClass
                                                            )
                                                      )
                                                      ?.sort((a, b) => {
                                                            const priceA =
                                                                  Math.min(
                                                                        ...a.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );
                                                            const priceB =
                                                                  Math.min(
                                                                        ...b.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );

                                                            return (
                                                                  priceA -
                                                                  priceB
                                                            );
                                                      });
                                          setFlights(economyFlights);
                                          setFilteredFlights(economyFlights);
                                          setTotalFlights(
                                                economyFlights
                                                      ? economyFlights.length
                                                      : 0
                                          );
                                          if (
                                                searchDetail?.returnStatus ===
                                                "true"
                                          ) {
                                                const flightReturnData =
                                                      response.data.searchResult
                                                            ?.tripInfos?.RETURN;
                                                const economyReturnFlights =
                                                      flightReturnData
                                                            .filter((item) =>
                                                                  item.totalPriceList.some(
                                                                        (
                                                                              price
                                                                        ) =>
                                                                              price
                                                                                    .fd
                                                                                    .ADULT
                                                                                    .cc ===
                                                                              searchDetail?.cabinClass
                                                                  )
                                                            )
                                                            ?.sort((a, b) => {
                                                                  const priceA =
                                                                        Math.min(
                                                                              ...a.totalPriceList
                                                                                    .filter(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      .fd
                                                                                                      .ADULT
                                                                                                      .cc ===
                                                                                                searchDetail?.cabinClass
                                                                                    )
                                                                                    .map(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      ?.fd
                                                                                                      ?.ADULT
                                                                                                      ?.fC
                                                                                                      ?.TF ||
                                                                                                Infinity
                                                                                    )
                                                                        );
                                                                  const priceB =
                                                                        Math.min(
                                                                              ...b.totalPriceList
                                                                                    .filter(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      .fd
                                                                                                      .ADULT
                                                                                                      .cc ===
                                                                                                searchDetail?.cabinClass
                                                                                    )
                                                                                    .map(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      ?.fd
                                                                                                      ?.ADULT
                                                                                                      ?.fC
                                                                                                      ?.TF ||
                                                                                                Infinity
                                                                                    )
                                                                        );

                                                                  return (
                                                                        priceA -
                                                                        priceB
                                                                  );
                                                            });

                                                setReturnFlights(
                                                      economyReturnFlights
                                                );
                                                setReturnFilterFlights(
                                                      economyReturnFlights
                                                );
                                                setTotalFlights(
                                                      totalFlights +
                                                            economyReturnFlights?.length
                                                );
                                          }
                                    }
                              }
                        }, 1000);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations`,
                        {
                              leaving_from_code: searchDetail?.leavingFrom,
                              destination_code: searchDetail?.destination,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSearchDetails(response.data.details);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            handleData();
            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");
            localStorage.removeItem("totalAmount");
      }, []);

      const totalTraveller = () => {
            return (
                  parseInt(searchDetail?.adult) +
                  parseInt(searchDetail?.children) +
                  parseInt(searchDetail?.infants)
            );
      };

      return (
            <>
                  {!loading ? (
                        <DashboardContainer
                              padding="130px"
                              flexDirection="column"
                        >
                              <FlightModal
                                    searchDetail={searchDetail}
                                    searchDetails={searchDetails}
                              />
                              <div className="d-flex" style={{ gap: "20px" }}>
                                    <DashboardMenu className="">
                                          <DashboardMenuContent>
                                                <FilterContainer>
                                                      {searchDetail?.returnStatus ===
                                                      "false" ? (
                                                            <OnewayFilter
                                                                  lists={
                                                                        departures
                                                                  }
                                                                  returnStatus={
                                                                        false
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  flights={
                                                                        flights
                                                                  }
                                                                  stopLists={
                                                                        stops
                                                                  }
                                                                  arrivalLists={
                                                                        arrivals
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredFlights
                                                                  }
                                                                  searchDetails={
                                                                        searchDetails
                                                                  }
                                                                  returnAirlines={
                                                                        returnAirlines
                                                                  }
                                                                  setReturnAirlines={
                                                                        setReturnAirlines
                                                                  }
                                                            />
                                                      ) : (
                                                            <RoundtripFilterContainer defaultActiveKey="Oneway">
                                                                  <RoundtripFilterItem
                                                                        eventKey="Oneway"
                                                                        title="Oneway"
                                                                  >
                                                                        <OnewayFilter
                                                                              lists={
                                                                                    departures
                                                                              }
                                                                              searchDetail={
                                                                                    searchDetail
                                                                              }
                                                                              returnStatus={
                                                                                    true
                                                                              }
                                                                              flights={
                                                                                    flights
                                                                              }
                                                                              stopLists={
                                                                                    stops
                                                                              }
                                                                              arrivalLists={
                                                                                    arrivals
                                                                              }
                                                                              setFilteredFlights={
                                                                                    setFilteredFlights
                                                                              }
                                                                              searchDetails={
                                                                                    searchDetails
                                                                              }
                                                                              returnAirlines={
                                                                                    returnAirlines
                                                                              }
                                                                              setReturnAirlines={
                                                                                    setReturnAirlines
                                                                              }
                                                                        />
                                                                  </RoundtripFilterItem>
                                                                  <RoundtripFilterItem
                                                                        eventKey="RoundTrip"
                                                                        title="Round Trip"
                                                                  >
                                                                        <RoundtripFilter
                                                                              searchDetail={
                                                                                    searchDetail
                                                                              }
                                                                              lists={
                                                                                    departures
                                                                              }
                                                                              flights={
                                                                                    returnFlights
                                                                              }
                                                                              stopLists={
                                                                                    stops
                                                                              }
                                                                              arrivalLists={
                                                                                    arrivals
                                                                              }
                                                                              setFilteredFlights={
                                                                                    setReturnFilterFlights
                                                                              }
                                                                              searchDetails={
                                                                                    searchDetails
                                                                              }
                                                                              returnAirlines={
                                                                                    returnAirlines
                                                                              }
                                                                              setReturnAirlines={
                                                                                    setReturnAirlines
                                                                              }
                                                                        />
                                                                  </RoundtripFilterItem>
                                                            </RoundtripFilterContainer>
                                                      )}
                                                </FilterContainer>
                                          </DashboardMenuContent>
                                    </DashboardMenu>

                                    <DashboardContent>
                                          <FlightHeader>
                                                <FlightHeaderInfo>
                                                      {searchDetail?.returnStatus ===
                                                            "false" && (
                                                            <h5>
                                                                  {
                                                                        filteredFlights?.length
                                                                  }{" "}
                                                                  Flight From{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.leaving_from
                                                                              ?.city
                                                                  }{" "}
                                                                  To{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.destination
                                                                              ?.city
                                                                  }
                                                            </h5>
                                                      )}
                                                </FlightHeaderInfo>
                                                <FlightHeaderContent>
                                                      {totalTraveller() > 1 && (
                                                            <div
                                                                  className={`header__content-item ${
                                                                        fullFare
                                                                              ? "active"
                                                                              : ""
                                                                  }`}
                                                                  onClick={() =>
                                                                        setFullFare(
                                                                              !fullFare
                                                                        )
                                                                  }
                                                            >
                                                                  {netFare
                                                                        ? "Hide"
                                                                        : "Show"}{" "}
                                                                  Full Fare
                                                            </div>
                                                      )}
                                                      <div
                                                            className={`header__content-item ${
                                                                  netFare
                                                                        ? "active"
                                                                        : ""
                                                            }`}
                                                            onClick={() =>
                                                                  setNetFare(
                                                                        !netFare
                                                                  )
                                                            }
                                                      >
                                                            {netFare
                                                                  ? "Hide"
                                                                  : "Show"}{" "}
                                                            Net Fare
                                                      </div>
                                                </FlightHeaderContent>
                                          </FlightHeader>
                                          <DashboardFlightRanges>
                                                <DashboardFlightWrapper>
                                                      {searchDetail?.returnStatus ===
                                                      "false" ? (
                                                            <>
                                                                  <OnewayFlight
                                                                        flights={
                                                                              filteredFlights
                                                                        }
                                                                        setFilteredFlights={
                                                                              setFilteredFlights
                                                                        }
                                                                        netFare={
                                                                              netFare
                                                                        }
                                                                        searchDetail={
                                                                              searchDetail
                                                                        }
                                                                        fullFare={
                                                                              fullFare
                                                                        }
                                                                  />
                                                            </>
                                                      ) : (
                                                            <RoundtripFlight
                                                                  filteredFlights={
                                                                        filteredFlights
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredFlights
                                                                  }
                                                                  returnFilterFlights={
                                                                        returnFilterFlights
                                                                  }
                                                                  setReturnFilterFlights={
                                                                        setReturnFilterFlights
                                                                  }
                                                                  netFare={
                                                                        netFare
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  fullFare={
                                                                        fullFare
                                                                  }
                                                            />
                                                      )}
                                                </DashboardFlightWrapper>
                                          </DashboardFlightRanges>
                                    </DashboardContent>
                              </div>
                        </DashboardContainer>
                  ) : (
                        <DashboardContainer>
                              <div className="col-lg-12">
                                    <FlightLoader />
                                    <FlightModalInfo>
                                          <div className="main__title">
                                                {`${searchDetail?.leavingFrom} - ${searchDetail?.destination}`}
                                          </div>
                                          <div className="main__subtitle">
                                                {`${convertDate(
                                                      searchDetail?.departDate
                                                )} ${
                                                      searchDetail?.returnStatus ===
                                                      "true"
                                                            ? "to " +
                                                              convertDate(
                                                                    searchDetail?.returnDate
                                                              )
                                                            : ""
                                                }`}
                                          </div>
                                    </FlightModalInfo>
                              </div>
                        </DashboardContainer>
                  )}
            </>
      );
};

export default FlightSearch;
