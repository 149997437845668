import React, { useEffect, useRef, useState } from "react";
import {
      FlightModalDestination,
      FlightModalItem,
      FlightModalOptionContent,
} from "../style";

import { addDays, format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { CloseIcon } from "../../../icons";
import {
      FlightModalReturn,
      FlightModalReturnDate,
      FlightReturnCancel,
} from "./style";

const FlightModalDate = ({ searchDetail, handleChange }) => {
      const [status, setStatus] = useState(false);
      const [singleTrip, setSingleTrip] = useState(false);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const newDate = addDays(searchDetail?.departDate, 1);

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE, dd MMM");
      };

      const convertNumberDate = (date) => {
            return format(date, "yyyy-MM-dd");
      };

      const handleDate = (item) => {
            setStatus(false);
            if (item) {
                  handleChange(convertNumberDate(item), "departDate");
                  if (convertNumberDate(item) >= searchDetail?.returnDate) {
                        handleChange(
                              convertNumberDate(
                                    addDays(convertNumberDate(item), 1)
                              ),
                              "returnDate"
                        );
                  }
            }
      };

      const handleRoundtripDate = (item) => {
            setStatus(false);
            if (item) {
                  handleChange("true", "returnStatus");
                  handleChange(convertNumberDate(item), "returnDate");
            }
      };

      const handleSingleTrip = () => {
            setSingleTrip(true);
            setStatus(true);
      };

      const handleRoundtrip = () => {
            setStatus(true);
            setSingleTrip(false);
      };

      const clearTrip = () => {
            setStatus(false);
            setSingleTrip(false);
            handleChange("false", "returnStatus");
            handleChange("", "returnDate");
      };

      return (
            <>
                  <FlightModalItem width="24%" ref={formGroupRef}>
                        <FlightModalDestination
                              width="calc(40%)"
                              onClick={handleSingleTrip}
                        >
                              <div>
                                    {searchDetail?.departDate &&
                                          convertDate(
                                                convertNumberDate(
                                                      searchDetail?.departDate
                                                )
                                          )}
                              </div>
                        </FlightModalDestination>

                        <FlightModalDestination
                              className="text-center"
                              width="8%"
                        >
                              |
                        </FlightModalDestination>
                        <FlightModalReturn width="calc(60% - 10px)">
                              <FlightModalReturnDate onClick={handleRoundtrip}>
                                    <FlightModalDestination width="100%">
                                          <div>
                                                {searchDetail?.returnStatus ===
                                                "true"
                                                      ? convertDate(
                                                              searchDetail?.returnDate
                                                        )
                                                      : "-"}
                                          </div>
                                    </FlightModalDestination>
                              </FlightModalReturnDate>
                              {searchDetail?.returnStatus === "true" && (
                                    <FlightReturnCancel onClick={clearTrip}>
                                          <CloseIcon />
                                    </FlightReturnCancel>
                              )}
                        </FlightModalReturn>
                        {status && (
                              <FlightModalOptionContent ref={locationRef}>
                                    {singleTrip ? (
                                          <DayPicker
                                                mode="single"
                                                selected={
                                                      searchDetail?.departDate
                                                }
                                                numberOfMonths={2}
                                                onSelect={handleDate}
                                                disabled={{
                                                      before: new Date(),
                                                }}
                                          />
                                    ) : (
                                          <DayPicker
                                                mode="single"
                                                numberOfMonths={2}
                                                selected={{
                                                      from: searchDetail?.departDate,
                                                      to:
                                                            searchDetail?.returnDate ||
                                                            newDate,
                                                }}
                                                onSelect={handleRoundtripDate}
                                                disabled={{
                                                      before: searchDetail?.departDate,
                                                }}
                                          />
                                    )}
                              </FlightModalOptionContent>
                        )}
                  </FlightModalItem>
            </>
      );
};

export default FlightModalDate;
