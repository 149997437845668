import styled from "styled-components";
import { MenuItemContainer } from "../../components/layout/header/MenuItem/styles";

export const HeaderContainer = styled.header`
      padding: ${({ scrolled }) => (scrolled ? "0" : "0 3rem")};
      z-index: 99;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transition: 0.3s all ease-in;
`;

export const HeaderWrapper = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${(props) => props.guest && "#ffffff99"};
      background-color: ${({ scrolled }) =>
            scrolled ? "#ffffff" : "#ffffff99"};
      border-radius: ${({ scrolled }) => (scrolled ? "0" : "10px")};
      margin-top: ${({ scrolled }) => (scrolled ? "0" : "20px")};
      padding: ${({ scrolled }) =>
            scrolled ? "15px calc(3rem + 20px)" : "15px 20px"};
      /* border: 1px solid #f0f0f0; */
      box-shadow: ${(props) =>
            props.guest && "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"};
`;

export const HeaderLogo = styled.div`
      display: block;
      /* padding: 2rem 0px; */

      img {
            width: 120px;
            height: auto;
            object-fit: contain;
      }
`;
export const HeaderNavigation = styled.nav``;

export const HeaderList = styled.ul`
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      list-style-type: none;
`;
export const HeaderContent = styled.div`
      gap: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const ProfileContent = styled.div`
      display: flex;
      align-items: center;

      min-width: 360px;
      ${"" /* background-color: ${(props) => props.theme.primary}; */}
      color: ${(props) => props.theme.white};
      border-radius: 40px;

      img {
            width: 16px;
            height: 16px;
      }
`;
export const ProfileWallet = styled.div`
      display: flex;
      align-items: center;
      font-weight: 300;
      position: relative;
      width: 50%;
      border-right: 1px solid ${(props) => props.theme.white};
      padding: 0.5rem 1rem;
      &:hover {
            cursor: pointer;
      }
`;
export const ProfileWalletValue = styled.div`
      width: calc(100% - 44px);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;

      &:hover {
            cursor: pointer;
      }
`;
export const ProfileWalletIcon = styled.div`
      position: relative;
      border: 1px solid ${(props) => props.theme.white};
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;

      &:hover {
            cursor: pointer;
      }
      // export const Separator = styled.div
`;
// 	height: 30px;
// 	width: 1px;
// 	background-color: ${(props) => props.theme.white};
// `;
export const User = styled.div`
      cursor: pointer;
      padding: 0.5rem 1rem;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 300;
      }
`;

export const UserBox = styled.div`
      min-width: 100%;
      display: ${(props) => (props.show ? "inline-flex" : "none")};
      position: absolute;
      background-color: ${(props) => props.backgroundColor || "white"};
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in;
      box-shadow: 0px 4px 23.4px 6px #00000040;
      border-radius: 5px;
      margin-top: 10px;
`;

export const UserMenu = styled.ul`
      margin-bottom: 0;
      padding-left: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: ${(props) => props.flexDirection};
      width: 100%;
      margin: 10px;
      margin-bottom: 10px;

      li {
            width: 100%;
      }

      ${MenuItemContainer} {
            text-transform: capitalize;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            border-bottom: 1px dashed ${(props) => props.theme.primary};

            &:last-child {
                  border-bottom: 0;
            }

            a {
                  color: ${(props) => props.theme.primary} !important;
                  display: block;
                  border-radius: 0 !important;

                  &:hover {
                        color: ${(props) => props.theme.white} !important;
                        background-color: ${(props) =>
                              props.theme.primary} !important;
                        border-radius: 0 !important;
                  }
            }
      }
`;

export const HeaderProfile = styled.div`
      background: ${(props) => props.theme.primary};
      border-radius: 50rem;
      position: relative;

      &:hover {
            background: linear-gradient(203.8deg, #224082, #57688d);
      }
`;

export const HeaderProfileContainer = styled.div`
      position: relative;
      min-width: 100%;
      display: flex;
      align-items: center;
`;

export const WalletInput = styled.div`
      width: 100%;
      input {
            background-color: #f0f0f0;
            width: 100%;
            padding: 7px 7px;
            padding-left: 20px;
            border: none;
            outline: none;
      }
`;

export const WalletIcon = styled.button`
      padding: 8px 10px;
      border: 0;
      margin-top: 10px;
      width: 100%;
      color: ${(props) => props.theme.white};
      cursor: pointer;
      display: flex;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.primary};
`;

export const WalletContainer = styled.div`
      background: ${(props) => props.theme.white};
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
`;
