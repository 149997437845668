import React from "react";

const MinusIcon = ({ width, height, color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={height || "24px"}
                        viewBox="0 -960 960 960"
                        width={width || "24px"}
                        fill={color || "#333"}
                  >
                        <path d="M200-440v-80h560v80H200Z" />
                  </svg>
            </>
      );
};

export default MinusIcon;
