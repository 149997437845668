import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import {
      LoginAdditional,
      LoginContainer,
      LoginDetail,
      LoginDetailContent,
} from "../styles";
import { Navigate } from "react-router-dom";
import InfoModal from "../InfoModal";
import BannerSlider from "../BannerSlider";
import { PreloaderContainer } from "../../../components/Common/Preloader/styles";

const AuthLayout = ({ children }) => {
      const login = localStorage.getItem("isLoggedIn");

      if (login) {
            return <Navigate to="/" />;
      }

      return (
            <div className="position-relative">
                  <PreloaderContainer position="absolute">
                        <InfoModal />
                        <LoginContainer style={{ height: "100vh" }}>
                              <Container className="h-100" fluid>
                                    <Row className="h-100">
                                          <Col lg={6}>
                                                <LoginAdditional className="h-100">
                                                      <Row className="justify-content-center h-100">
                                                            <LoginDetailContent
                                                                  lg={9}
                                                            >
                                                                  <BannerSlider />
                                                            </LoginDetailContent>
                                                      </Row>
                                                </LoginAdditional>
                                          </Col>
                                          <LoginDetail lg={6}>
                                                <LoginDetailContent lg={7}>
                                                      {children}
                                                </LoginDetailContent>
                                          </LoginDetail>
                                    </Row>
                              </Container>
                        </LoginContainer>
                  </PreloaderContainer>
            </div>
      );
};

export default AuthLayout;
