import { NavItem, NavLink } from "react-bootstrap";
import styled from "styled-components";

export const AccountItem = styled(NavItem)`
      &:first-child {
            border-top: 1px dashed ${(props) => props.theme.primary};
      }
`;

export const AccountLink = styled(NavLink)`
      border-left: 0 solid ${(props) => props.theme.white};
      border-radius: 0 !important;
      color: #333 !important;
      transition: all 0.3s ease-in-out;
      display: flex;
      gap: 10px;
      align-items: center;
      border-bottom: 1px dashed ${(props) => props.theme.primary};

      &.active {
            background-color: rgba(159, 157, 157, 0.2) !important;
            border-left: 2px solid ${(props) => props.theme.primary};
            color: ${(props) => props.theme.primary} !important;
            border-radius: 0;
            
            ${AccountItem} {
                  border-top: 0 !important;
            }
      }
      
      &:hover {
            color: ${(props) => props.theme.white} !important;
            background-color: ${(props) => props.theme.primary} !important;
            border-radius: 0;
      }
`;
