import React from "react";
import { PriceSummary } from "../../../../../frontend/checkout/styles";
import { FlightDetailInfo } from "../../../../Dashboard/FlightDetails/style";
import { FlightReviewTable } from "../../../flight_reviews/FlightReviewDetail/style";
import { PriceAirlineDetailContainer } from "../PriceAirlineDetail/style";
import PriceAddView from "./PriceAddView";

const PriceAddOn = () => {
      const travellerDetails = JSON.parse(localStorage.getItem("travellers"));
      const services = JSON.parse(localStorage.getItem("services"));

      function countServicesByCategory(data) {
            let serviceCount = {
                  Meal: 0,
                  Baggage: 0,
                  OtherService: 0,
                  Seat: 0,
            };

            // Loop through segments (like "BOM-GOI", "DEL-MAA")
            for (const segment in data) {
                  const passengers = data[segment]; // Get passengers (like "ADULT-1")

                  // Loop through passengers (e.g., "ADULT-1")
                  for (const passenger in passengers) {
                        const services = passengers[passenger]; // Get services (Meal, Baggage, etc.)

                        // Check each service category and increment its count if the 'name' is not empty
                        if (services.Meal.name) serviceCount.Meal++;
                        if (services.Baggage.name) serviceCount.Baggage++;
                        if (services.OtherService.name)
                              serviceCount.OtherService++;
                        if (services.Seat.code) serviceCount.Seat++;
                  }
            }

            return serviceCount;
      }

      return (
            <>
                  {services && (
                        <PriceSummary>
                              <div className="price__box mb-3">
                                    <PriceAirlineDetailContainer className="pb-0 border-top-0 pt-0 mt-0">
                                          <div className="price__heading">
                                                <div className="price__title">
                                                      Add Ons{" "}
                                                </div>
                                                <PriceAddView />
                                          </div>
                                          <FlightDetailInfo
                                                className="mt-0 pb-0 mb-0 pt-0"
                                                border="0"
                                          >
                                                {travellerDetails?.length > 0 &&
                                                      travellerDetails?.map(
                                                            (item, index) => (
                                                                  <FlightReviewTable
                                                                        border={
                                                                              false
                                                                        }
                                                                        key={
                                                                              index
                                                                        }
                                                                        className="pt-0 mt-0 pb-0 mb-0"
                                                                  >
                                                                        <div>
                                                                              {countServicesByCategory(
                                                                                    services
                                                                              )
                                                                                    ?.Meal +
                                                                                    " Meals, "}
                                                                              {countServicesByCategory(
                                                                                    services
                                                                              )
                                                                                    ?.Baggage
                                                                                    ? countServicesByCategory(
                                                                                            services
                                                                                      )
                                                                                            ?.Baggage +
                                                                                      " Baggages, "
                                                                                    : ""}
                                                                              {countServicesByCategory(
                                                                                    services
                                                                              )
                                                                                    ?.OtherService
                                                                                    ? countServicesByCategory(
                                                                                            services
                                                                                      )
                                                                                            ?.OtherService +
                                                                                      " OtherServices, "
                                                                                    : ""}
                                                                              {countServicesByCategory(
                                                                                    services
                                                                              )
                                                                                    ?.Seat
                                                                                    ? countServicesByCategory(
                                                                                            services
                                                                                      )
                                                                                            ?.Seat +
                                                                                      " Seats"
                                                                                    : ""}
                                                                        </div>
                                                                  </FlightReviewTable>
                                                            )
                                                      )}
                                          </FlightDetailInfo>
                                    </PriceAirlineDetailContainer>
                              </div>
                        </PriceSummary>
                  )}
            </>
      );
};

export default PriceAddOn;
