import { Route } from "react-router-dom";
import Login from "../frontend/login";
import PasswordReset from "../frontend/login/PasswordReset";
import Register from "../frontend/register";

export const authManages = [
      <Route path="/" exact element={<Login />} />,
      <Route path="/register" element={<Register />} />,
      <Route path="/forget-password" element={<PasswordReset />} />,
];
