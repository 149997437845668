import { Modal, ModalBody, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

export const FlightTabs = styled(Tabs)`
      .nav-item {
            min-width: ${(props) =>
                  props.number
                        ? `calc(100% / ${props.number})`
                        : "calc(100% / 2)"};

            .nav-link {
                  min-width: 100%;
                  color: ${(props) => props.theme.black};
                  border: 0;

                  &.active,
                  &:hover {
                        color: ${(props) => props.theme.primary};
                        border: 0;
                        border-bottom: 2px solid
                              ${(props) => props.theme.primary};
                  }

                  &:focus-visible,
                  &:focus {
                        box-shadow: none;
                        border-top-color: transparent;
                        border-left-color: transparent;
                        border-right-color: transparent;
                  }
            }
      }
`;

export const FlightTab = styled(Tab)``;

export const FlightModal = styled(Modal)`
      .modal-body {
            position: relative;
      }

      .btn-close {
            position: absolute;
            cursor: pointer;
            top: ${(props) => props.top || "10px"};
            right: 20px;
            z-index: 1;
            padding: 10px;
            border-radius: 50rem;
            border: 1px solid #e5e3e3;
            transition: all 0.3s ease-in;
            background-color: #f4f4f4;

            &:hover {
                  background-color: ${(props) => props.theme.primary};
                  border: 1px solid #e5e3e3;
                  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
            }

            &:focus {
                  box-shadow: none;
            }
      }
`;
