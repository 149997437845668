import styled from "styled-components";

export const MenuContainer = styled.div`
      ul {
            padding: 0;
            list-style: none;
            margin: 0;
            display: flex;
            gap: 0px;

            li {
                  border-right: 0.5px dashed ${(props) => props.theme.white};
                  background-color: ${(props) => props.theme.primary};
                  overflow: hidden;

                  a,
                  button {
                        padding: 10px 40px;
                        transition: all 0.3s ease-in-out;
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        width: 100%;
                        font-size: 14px;
                        align-items: center;
                        color: #ccc;
                        font-weight: 600;

                        span {
                              font-size: 38px;
                              color: ${(props) => props.theme.white};
                        }
                        &:hover,
                        &.active {
                              background-color: white;
                              color: ${(props) => props.theme.primary};

                              span {
                                    color: ${(props) => props.theme.primary};
                              }
                        }
                  }

                  &:first-child {
                        border-top-left-radius: 5px;
                  }

                  &:last-child {
                        border-right: 0;
                  }
            }
      }
`;
