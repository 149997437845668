import React, { useContext, useEffect, useRef, useState } from "react";
import {
      HeaderContainer,
      HeaderContent,
      HeaderList,
      HeaderLogo,
      HeaderNavigation,
      HeaderProfile,
      HeaderProfileContainer,
      HeaderWrapper,
      ProfileContent,
      ProfileWallet,
      ProfileWalletIcon,
      ProfileWalletValue,
      User,
      UserBox,
      UserMenu,
      WalletContainer,
      WalletIcon,
      WalletInput,
} from "./styles";
import { Link, Navigate } from "react-router-dom";
import MenuItem from "../../components/layout/header/MenuItem";
import UserContext from "../../context/UserContext";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { FlightIcon, HotelIcon, PackageIcon, TripIcon } from "../../icons";

const Header = () => {
      const profileCtx = useContext(UserContext);

      const [show, setShow] = useState(false);
      const selectRef = useRef();

      const handleClick = () => {
            setShow(!show);
            if (userShow === true) {
                  setUserShow(false);
            }
      };

      const [userShow, setUserShow] = useState(false);

      const handleUserClick = () => {
            setUserShow(!userShow);
            if (show === true) {
                  setShow(false);
            }
      };

      const login = localStorage.getItem("isLoggedIn");
      const token = JSON.parse(localStorage.getItem("token"));
      const tokenExpiry = localStorage.getItem("tokenExpiration");
      const tokenExpirationDate = new Date(tokenExpiry).getTime();
      const tokenExpiryFinal = parseInt(tokenExpirationDate, 10);

      function onLogoutHandler() {
            localStorage.clear();
            return <Navigate to="/" />;
      }

      useEffect(() => {
            if (token == null) {
                  onLogoutHandler();
            }
      });

      const [scrolled, setScrolled] = useState(false);

      useEffect(() => {
            const handleScroll = () => {
                  setScrolled(window.scrollY > 0);
            };

            window.addEventListener("scroll", handleScroll);

            // Cleanup the event listener on component unmount
            return () => {
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);

      useEffect(() => {
            if (!token || tokenExpiryFinal <= Date.now()) {
                  onLogoutHandler();
            }
      }, [token, tokenExpiryFinal]);

      const handleClickOutside = (event) => {
            if (
                  selectRef.current &&
                  !selectRef.current.contains(event.target)
            ) {
                  setShow(false);
                  setUserShow(false);
            }
      };

      React.useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, []);

      if (login === null) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <HeaderContainer scrolled={scrolled}>
                        <HeaderWrapper scrolled={scrolled} guest={token}>
                              <HeaderLogo>
                                    <Link to="/">
                                          <img
                                                src="/images/logo/logo.png"
                                                alt="logo"
                                                width="100%"
                                                height="100%"
                                          />
                                    </Link>
                              </HeaderLogo>
                              {!(token === null) && (
                                    <HeaderContent>
                                          <HeaderNavigation>
                                                <HeaderList>
                                                      <MenuItem
                                                            slug={"/"}
                                                            title={"flight"}
                                                            icon={
                                                                  <FlightIcon />
                                                            }
                                                      />
                                                      <MenuItem
                                                            slug={"#"}
                                                            title={"hotel"}
                                                            disabled={true}
                                                            icon={<HotelIcon />}
                                                      />
                                                      <MenuItem
                                                            slug={"#"}
                                                            title={"packages"}
                                                            icon={
                                                                  <PackageIcon />
                                                            }
                                                            disabled={true}
                                                      />
                                                </HeaderList>
                                          </HeaderNavigation>
                                          <HeaderProfileContainer>
                                                <HeaderProfile>
                                                      <ProfileContent>
                                                            <ProfileWallet
                                                                  onClick={
                                                                        handleClick
                                                                  }
                                                            >
                                                                  <ProfileWalletIcon>
                                                                        <img
                                                                              src="/images/svg/wallet.svg"
                                                                              alt="wallet"
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  </ProfileWalletIcon>
                                                                  <ProfileWalletValue>
                                                                        {profileCtx
                                                                              ?.profile
                                                                              ?.balance
                                                                              ? convertAmount(
                                                                                      convertFloat(
                                                                                            profileCtx
                                                                                                  ?.profile
                                                                                                  ?.balance
                                                                                      )
                                                                                )
                                                                              : 0}
                                                                  </ProfileWalletValue>
                                                            </ProfileWallet>
                                                            <User
                                                                  onClick={
                                                                        handleUserClick
                                                                  }
                                                            >
                                                                  <p>User</p>
                                                            </User>
                                                      </ProfileContent>
                                                      <UserBox show={show}>
                                                            <UserMenu
                                                                  flexDirection="column"
                                                                  className="list-inline rounded-pill"
                                                            >
                                                                  <WalletContainer className=" rounded-pill">
                                                                        <WalletInput>
                                                                              <input
                                                                                    type="text"
                                                                                    placeholder="₹ 100"
                                                                                    className="rounded-pill"
                                                                              />
                                                                        </WalletInput>
                                                                  </WalletContainer>
                                                                  <WalletIcon>
                                                                        <i className="bx bx-plus"></i>{" "}
                                                                        Add
                                                                        Money
                                                                  </WalletIcon>
                                                            </UserMenu>
                                                      </UserBox>
                                                      <UserBox show={userShow}>
                                                            <UserMenu
                                                                  className="list-inline"
                                                                  flexDirection="column"
                                                            >
                                                                  <MenuItem
                                                                        slug={
                                                                              "/my-account"
                                                                        }
                                                                        title={
                                                                              "my account"
                                                                        }
                                                                  />
                                                                  <MenuItem
                                                                        onClick={() =>
                                                                              onLogoutHandler()
                                                                        }
                                                                        slug="/"
                                                                        title={
                                                                              "log out"
                                                                        }
                                                                  />
                                                            </UserMenu>
                                                      </UserBox>
                                                </HeaderProfile>
                                          </HeaderProfileContainer>
                                    </HeaderContent>
                              )}
                        </HeaderWrapper>
                  </HeaderContainer>
            </>
      );
};

export default Header;
