import React from "react";
import {
      PackageCard,
      PackageContent,
      PackageImage,
      PackageText,
      PackageTitle,
      PackageWrapper,
} from "./styles";

const PackageItem = ({ data }) => {
      return (
            <>
                  <PackageCard to="#">
                        <PackageWrapper>
                              <PackageImage>
                                    <img
                                          className=""
                                          src={data.image}
                                          width="100%"
                                          height="100%"
                                          alt={data.title}
                                    />
                              </PackageImage>
                              <PackageContent>
                                    <PackageTitle>{data.title}</PackageTitle>
                                    <PackageText>₹ {data.price}</PackageText>
                              </PackageContent>
                              <PackageContent className="mt-0">
                                    <PackageTitle
                                          className="text-muted"
                                          fontSize="14px"
                                    >
                                          Packages
                                    </PackageTitle>
                                    <PackageText
                                          className="text-muted"
                                          fontSize="14px"
                                    >
                                          Starting Price
                                    </PackageText>
                              </PackageContent>
                        </PackageWrapper>
                  </PackageCard>
            </>
      );
};

export default PackageItem;
