import React from "react";

const BoltIcon = ({ height, width, color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={height || "24px"}
                        viewBox="0 -960 960 960"
                        width={width || "24px"}
                        fill={color || "#333"}
                  >
                        <path d="m480-336 128-184H494l80-280H360v320h120v144ZM400-80v-320H280v-480h400l-80 280h160L400-80Zm80-400H360h120Z" />
                  </svg>
            </>
      );
};

export default BoltIcon;
