import styled from "styled-components";

export const MenuItemContainer = styled.li`
      font-size: 17px;
      text-transform: capitalize;
      /* padding: 2rem 0; */

      a {
            color: ${(props) => (props.active ? "#fff" : "#222")};
            transition: all 0.3s ease-in-out;
            background: transparent;
            padding: 10px 20px;
            border-radius: 50rem;
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
                  fill: ${(props) => (props.active ? "#fff" : "#222")};
            }

            &:hover,
            &.active,
            &.disabled {
                  background: ${(props) =>
                        props.disabled ? "#f0f0f0" : "#f5f5f5"};
                  padding: 10px 20px;
                  border-radius: 50rem;
                  color: ${(props) =>
                        props.disabled
                              ? "#7c7f82"
                              : props.theme.primary} !important;

                  svg {
                        fill: ${(props) =>
                              props.disabled
                                    ? "#7c7f82"
                                    : props.theme.primary} !important;
                  }
            }
      }
`;
