import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const UserContext = createContext({
      profile: [],
      loading: false,
});

export const UserContextProvider = (props) => {
      const [loading, setLoading] = useState(false);
      const [profile, setProfile] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/profile`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setProfile(response.data.user);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const context = {
            profile: profile,
            loading: loading,
      };

      return (
            <>
                  <UserContext.Provider value={context}>
                        {props.children}
                  </UserContext.Provider>
            </>
      );
};

export default UserContext;
