import styled from "styled-components";

export const CountdownContainer = styled.div`
      width: 100%;
      /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
      color: ${(props) => props.theme.primary};
      /* background-color: ${(props) => props.theme.primary}; */
      z-index: 999;

      div {
            font-size: 18px;
      }
`;

export const CountdownContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      padding-top: 0;
`;

export const CountdownModalContent = styled.div`
      padding: 20px 0;
`;
