import React from "react";
import Select from "react-select";
import { Col, FormGroup, FormLabel } from "react-bootstrap";

const FormSelect = ({
      title,
      classes,
      options,
      required,
      setData,
      disabled,selected
}) => {
      const handleChange = (value) => {
            if (value) {
                  setData(value.value);
            } else {
                  setData("");
            }
      };

      return (
            <>
                  <Col lg={classes || 3}>
                        <FormGroup>
                              <FormLabel>
                                    {title}
                                    {required && (
                                          <span className="text-danger">
                                                {" "}
                                                *
                                          </span>
                                    )}
                              </FormLabel>
                              <Select
                                    isClearable={true}
                                    onChange={handleChange}
                                    options={options}
                                    value={selected}
                                    isDisabled={disabled}
                              />
                        </FormGroup>
                  </Col>
            </>
      );
};

export default FormSelect;
