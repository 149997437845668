import { Col } from "react-bootstrap";
import styled from "styled-components";

export const AccountContainer = styled.div`
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 20px;
`;

export const AccountItem = styled(Col)`
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 20px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 13px;
      width: calc(33.33% - 16px);

      div {
            margin: auto;
      }

      span {
            text-transform: uppercase;
      }

      p {
            font-weight: 600;
            margin-bottom: 0;
      }
`;
