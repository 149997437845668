import React, { useEffect, useState } from "react";
import { CheckoutContainer } from "../checkout/styles";
import { DashboardContainer } from "../dashboard/styles";
import { Col, Row, Spinner } from "react-bootstrap";
import PriceSummaryBox from "../../components/Frontend/checkout/PriceSummaryBox";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
      CheckoutReview,
      CountdownTimer,
} from "../../components/Frontend/flight_reviews";
import { optionInfo } from "../../data/dashboard";
import FlightReviewOptions from "../../components/Frontend/flight_reviews/FlightReviewOptions";
import { FlightReviewContent } from "./style";

const FlightReview = () => {
      const { optionId } = useParams();
      const { reviewId } = useParams();

      const splitArray = reviewId.split(",");

      const [showData, setShowData] = useState(false);
      const [statusShow, setStatusShow] = useState(false);
      const [loading, setLoading] = useState(false);
      const [priceInfo, setPriceInfo] = useState([]);
      const [bookingId, setBookingId] = useState("");
      const [tripInfos, setTripInfos] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [options, setOptions] = useState(optionInfo);
      const [currentOption, setCurrentOption] = useState({
            id: optionInfo.find((item) => item.id === optionId).id,
            index: optionInfo.find((item) => item.id === optionId).index,
      });
      const [conditions, setConditions] = useState([]);
      const [modalStatus, setModalStatus] = useState(false);

      const [serviceList, setServiceList] = useState([]);
      const [destinationDetails, setDestinationDetails] = useState([]);

      // Default Amount
      const [totalAmount, setTotalAmount] = useState(0);

      const loadData = async () => {
            setLoading(true);
            let data;
            if (splitArray?.length > 1) {
                  data = {
                        priceIds: [splitArray[0], splitArray[1]],
                  };
            } else {
                  data = {
                        priceIds: [splitArray[0]],
                  };
            }

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/review`,
                        data,
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.status.success === true) {
                              setShowData(true);
                              setStatusShow(true);
                              localStorage.setItem(
                                    "reviewTime",
                                    response.data.conditions.st
                              );

                              setPriceInfo(
                                    response.data.totalPriceInfo.totalFareDetail
                              );
                              setTotalAmount(
                                    response.data.totalPriceInfo.totalFareDetail
                                          .fC?.TF
                              );
                              setTripInfos(response.data.tripInfos);
                              setSearchQuery(response.data.searchQuery);
                              setBookingId(response.data.bookingId);
                              setConditions(response.data.conditions);
                        }
                  })
                  .catch((error) => {
                        setStatusShow(true);
                        if (error.response.data.status.success === false) {
                              setShowData(false);
                              setModalStatus(true);
                        }
                  });
            setLoading(false);
      };

      useEffect(() => {
            const updateOptions = options.map((item, i) => {
                  if (i <= currentOption.index) {
                        return { ...item, status: true };
                  }
                  return { ...item, status: false };
            });
            setOptions(updateOptions);
            loadData();
      }, []);

      useEffect(() => {
            const storedServices = localStorage.getItem("services");
            if (storedServices) {
                  setServiceList(JSON.parse(storedServices));
            }
      }, []);

      return (
            <>
                  <CheckoutContainer>
                        <DashboardContainer
                              padding="130px"
                              flexDirection="column"
                        >
                              <CheckoutReview
                                    setCurrentOption={setCurrentOption}
                                    options={options}
                                    currentOption={currentOption}
                                    setOptions={setOptions}
                                    reviewId={reviewId}
                              />
                              <Row className="w-100">
                                    {!loading ? (
                                          <>
                                                <Col lg={8}>
                                                      {!loading && showData ? (
                                                            <FlightReviewOptions
                                                                  bookingId={
                                                                        bookingId
                                                                  }
                                                                  conditions={
                                                                        conditions
                                                                  }
                                                                  currentOption={
                                                                        currentOption
                                                                  }
                                                                  options={
                                                                        options
                                                                  }
                                                                  tripInfos={
                                                                        tripInfos
                                                                  }
                                                                  setOptions={
                                                                        setOptions
                                                                  }
                                                                  setCurrentOption={
                                                                        setCurrentOption
                                                                  }
                                                                  optionId={
                                                                        optionId
                                                                  }
                                                                  reviewId={
                                                                        reviewId
                                                                  }
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  setTotalAmount={
                                                                        setTotalAmount
                                                                  }
                                                                  totalAmount={
                                                                        totalAmount
                                                                  }
                                                                  setServiceList={
                                                                        setServiceList
                                                                  }
                                                                  serviceList={
                                                                        serviceList
                                                                  }
                                                                  destinationDetails={
                                                                        destinationDetails
                                                                  }
                                                                  setDestinationDetails={
                                                                        setDestinationDetails
                                                                  }
                                                            />
                                                      ) : null}
                                                </Col>
                                                <Col lg={4}>
                                                      <FlightReviewContent>
                                                            {!loading &&
                                                            statusShow ? (
                                                                  <CountdownTimer
                                                                        conditions={
                                                                              conditions
                                                                        }
                                                                        modalStatus={
                                                                              modalStatus
                                                                        }
                                                                        setModalStatus={
                                                                              setModalStatus
                                                                        }
                                                                  />
                                                            ) : null}
                                                            <PriceSummaryBox
                                                                  priceInfo={
                                                                        priceInfo
                                                                  }
                                                                  tripInfos={
                                                                        tripInfos
                                                                  }
                                                                  totalAmount={
                                                                        totalAmount
                                                                  }
                                                                  setTotalAmount={
                                                                        setTotalAmount
                                                                  }
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  serviceList={
                                                                        serviceList
                                                                  }
                                                                  currentOption={
                                                                        currentOption
                                                                  }
                                                            />
                                                            <div
                                                                  className="support mb-2"
                                                                  style={{
                                                                        visibility:
                                                                              "hidden",
                                                                  }}
                                                            >
                                                                  Flight support
                                                                  : +91
                                                                  8766601888
                                                            </div>
                                                      </FlightReviewContent>
                                                </Col>
                                          </>
                                    ) : (
                                          <>
                                                <Col
                                                      lg={12}
                                                      className="text-center mt-5 pt-5"
                                                >
                                                      <Spinner />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </DashboardContainer>
                  </CheckoutContainer>
            </>
      );
};

export default FlightReview;
