import React, { useEffect, useRef, useState } from "react";
import { FormIcon, FormWrapper } from "../styles";
import { Form } from "react-bootstrap";
import {
      AirlineCode,
      AirlineContent,
      AirlineInfo,
      HotelLocation,
} from "./style";
import axios from "axios";
import toast from "react-hot-toast";
import CheckError from "../CheckError";

const HotelInput = ({
      controlId,
      label,
      type,
      name,
      placeholder,
      width,
      padding,
      marginRight,
      icon,
      backgroundNone,
      inputPadding,
      border,
      svg,
      destination,
      setDestination,
      required,
      options,
      seletedValue,
      setDestinations,
      leavingFrom,
}) => {
      const [status, setStatus] = useState(false);
      const [inputValue, setInputValue] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);
      const [errorStatus, setErrorStatus] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const handleDestination = (item) => {
            setStatus(false);
            if (seletedValue) {
                  setDestination(item);
                  setInputValue("");
            } else {
                  setDestination(item.title);
            }
      };

      const handleTyped = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations?search=${inputValue}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            handleTyped();
      }, [inputValue]);

      useEffect(() => {
            if (leavingFrom && destination) {
                  if (leavingFrom?.code === destination?.code) {
                        setErrorStatus(true);
                        setErrorMessage("Please select different to and from");
                  } else {
                        setErrorStatus(false);
                  }
            } else {
                  setErrorStatus(false);
            }
      }, [destination]);

      return (
            <>
                  <FormWrapper
                        width={width}
                        padding={padding}
                        marginRight={marginRight}
                        backgroundNone={backgroundNone}
                        inputPadding={inputPadding}
                        border={border}
                  >
                        <Form.Group
                              controlId={controlId}
                              onClick={() => setStatus(true)}
                              ref={formGroupRef}
                        >
                              {label && <Form.Label>{label}</Form.Label>}
                              {!errorStatus ? (
                                    <Form.Control
                                          type={type}
                                          name={name}
                                          onInput={(event) =>
                                                setInputValue(
                                                      event.target.value
                                                )
                                          }
                                          onClick={() => setDestination("")}
                                          value={
                                                (inputValue && inputValue) ||
                                                (destination &&
                                                      `${destination.city}, ${destination.code}`)
                                          }
                                          placeholder={placeholder}
                                          required={required}
                                    />
                              ) : (
                                    <CheckError
                                          errorStatus={errorStatus}
                                          errorMessage={errorMessage}
                                    >
                                          <Form.Control
                                                type={type}
                                                name={name}
                                                onInput={(event) =>
                                                      setInputValue(
                                                            event.target.value
                                                      )
                                                }
                                                onClick={() =>
                                                      setDestination("")
                                                }
                                                value={
                                                      (inputValue &&
                                                            inputValue) ||
                                                      (destination &&
                                                            `${destination.city}, ${destination.code}`)
                                                }
                                                placeholder={placeholder}
                                                required={required}
                                          />
                                    </CheckError>
                              )}
                        </Form.Group>
                        {!(type === "icon") && (
                              <FormIcon>
                                    {icon && svg ? (
                                          icon
                                    ) : (
                                          <img src={icon} alt="" />
                                    )}
                              </FormIcon>
                        )}
                        {status && (
                              <HotelLocation ref={locationRef}>
                                    <ul>
                                          {options?.length > 0 &&
                                                options.map((item, index) => (
                                                      <li
                                                            onClick={() =>
                                                                  handleDestination(
                                                                        item
                                                                  )
                                                            }
                                                            key={index}
                                                      >
                                                            <AirlineContent>
                                                                  <AirlineInfo>
                                                                        <div className="airline__destination">
                                                                              {
                                                                                    item.city
                                                                              }
                                                                              {
                                                                                    ", "
                                                                              }
                                                                              {
                                                                                    item.country
                                                                              }
                                                                        </div>
                                                                  </AirlineInfo>
                                                                  <AirlineCode>
                                                                        {
                                                                              item.code
                                                                        }
                                                                  </AirlineCode>
                                                            </AirlineContent>
                                                            <div className="airline__name text-muted">
                                                                  {item.name}
                                                            </div>
                                                      </li>
                                                ))}
                                    </ul>
                              </HotelLocation>
                        )}
                  </FormWrapper>
            </>
      );
};

export default HotelInput;
