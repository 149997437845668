import React from "react";
import { AccountItem } from "./style";

const AccountInfoItem = ({ classes, title, value }) => {
      return (
            <>
                  <AccountItem md={classes || 4}>
                        <div>
                              <span className="text-muted">{title}</span>
                              <p>{value}</p>
                        </div>
                  </AccountItem>
            </>
      );
};

export default AccountInfoItem;
