import styled from "styled-components";

export const BannerSection = styled.div`
      position: relative;
      z-index: 0;
`;

export const BannerContainer = styled.div`
      max-width: 100%;
      height: 95vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 0 0 80px 80px;
      position: relative;
      /* padding: 14rem 0; */
`;
export const BannerWrapper = styled.div`
      display: flex;
      flex-direction: column;
      gap: 5rem;
      position: absolute;
      bottom: 10%;
      width: 100%;

      @media screen and (max-width: 1400px) {
            bottom: 4%;
      }
`;
export const BannerContent = styled.div`
      h2 {
            font-size: 42px;
            color: ${(props) => props.theme.white};
            font-weight: 400;
      }
`;
