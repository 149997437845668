import styled from "styled-components";

export const CommonTitleContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2.5rem;

      h2 {
            font-size: 28px;
            font-weight: 400;
            text-transform: capitalize;
      }
`;
