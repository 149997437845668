import styled from "styled-components";

export const InfoModalContent = styled.div`
      position: absolute;
      display: none;
      /* height: 180px; */
      width: 320px;
      left: auto;
      padding: 10px;
      transition: all 0.3s ease-in-out;
      border-radius: 5px;
      right: 0;
      z-index: 99999;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      h4 {
            border-bottom: 1px dashed ${(props) => props.theme.primary};
            padding-bottom: 5px;
            font-size: 20px;
      }

      a {
            color: ${(props) => props.theme.primary};
      }
`;

export const InfoModalContainer = styled.div`
      position: absolute;
      top: 20px;
      right: 20px;

      svg {
            fill: ${(props) => props.theme.primary};
            height: 32px;
            width: 32px;
      }

      &:hover {
            ${InfoModalContent} {
                  display: block;
            }
      }
`;
