import { AccordionBody } from "react-bootstrap";
import styled from "styled-components";

export const CheckoutContainer = styled.div`
      background: #f4f4f4;
`;

export const FlightDetails = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      background: #fff;
      border-radius: 10px;
      padding: 30px 30px;

      .accordion-item {
            margin-bottom: 10px;
            border: 0;
      }

      h2 {
            font-size: 17px;
            color: ${(props) => props.theme.primary};
      }
      .depart__icon {
            /* margin-left: -20px; */
            font-size: 13px;
            background: ${(props) => props.theme.primary};
            display: inline-block;
            color: ${(props) => props.theme.white};
            border-bottom-right-radius: 15px;
            padding: 5px 10px;
      }
      .title {
            color: #1a1a1a;
            font-size: 19px;
            font-weight: 400;
            line-height: 28.57px;
            text-align: left;
            span {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: #6a6868;
            }
      }

      .flight__title {
            font-size: 24px;
            font-weight: 400;
            line-height: 34.29px;
            text-align: left;
            &.flight {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 22.86px;
                  text-align: left;
            }
      }
      .flight__content {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            text-align: left;
      }
      .flight__duration {
            font-size: 11px;
            font-weight: 400;
            line-height: 17.14px;
            text-align: center;
            color: #6a6868;
      }
      hr {
            color: #b0aeae;
      }
      .line {
            display: block;
            width: 100%;
            height: 1px;
            background: #b6afafee;
      }
      .flight__refund {
            color: #2dca1c;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            border: 2px solid #2dca1c;
            width: 130px;
            border-radius: 45px;
            border: 1px 0px 0px 0px;
            padding: 5px 15px;
            min-width: max-content;
      }
      .rules {
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 18.57px;
            text-align: left;
            color: #a8a8a8;
      }

      .accordion-button:not(.collapsed) {
            background-color: transparent;
      }

      .accordion-button:focus {
            box-shadow: none;
      }
`;

export const TravllersDetails = styled.div``;

export const PriceSummary = styled.div`
      .price__heading {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
      }

      .support {
            color: ${(props) => props.theme.primary};
      }
      .price__box {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
      }
      .price__title {
            font-size: 18px;
            font-weight: 400;
            text-align: left;
      }
      .price__people {
            font-size: 13px;
            font-weight: 400;
            text-align: left;
      }
      .price__item {
            padding: 7px 0px;
            border-bottom: 1px solid #e5e3e3;

            &:last-child {
                  border-bottom: 0;
            }
      }
      .total {
            padding: 7px 0px 0px 0px;
            color: ${(props) => props.theme.primary} !important;
      }
      .total__title {
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            text-align: left;
      }
`;

export const PriceTravellerItem = styled.div`
      display: flex;
      gap: 2.5px;
      align-items: center;

      div {
            font-size: 18px;
      }
`;

export const TravellerAccordionForm = styled.div`
      /* margin-bottom: 1.5rem; */
      padding: ${(props) => props.padding || "10px"};
`;

export const TravellerAccordionBody = styled(AccordionBody)`
      margin-bottom: 1.5rem;
      padding: ${(props) => props.padding || "10px"};
`;

export const TravellersForm = styled.div`
      padding: ${(props) => props.padding || "10px"};
`;
