import styled from "styled-components";

export const PriceAirlineDetailContainer = styled.div`
      /* border-bottom: 1px dashed ${(props) => props.theme.primary}; */
      border-top: 1px dashed ${(props) => props.theme.primary};
      padding: 10px 0;

      .price__heading {
            justify-content: space-between;

            span {
                  font-size: 13px;
                  color: #224082;
            }
      }

      .price__modal {
            text-decoration: underline;
            cursor: pointer;
            color: ${(props) => props.theme.primary};

            &:hover {
                  text-decoration: none;
            }
      }
`;
