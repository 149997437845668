import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { FormImage } from "../../forms";
import { FilterButton } from "../../bank_deposits/style";
import axios from "axios";
import toast from "react-hot-toast";

const ProfileUpload = () => {
      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  image: image,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/details`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setTimeout(() => {
                                    window.location.reload();
                              }, 3000);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row className="g-3">
                              <FormImage
                                    classes={12}
                                    setImage={setImage}
                                    imageType=".png,.jpg,.jpeg"
                                    setImageUrl={setImageUrl}
                                    imageUrl={imageUrl}
                              />
                              <Col lg={12}>
                                    <FilterButton type="submit">
                                          {loading ? <Spinner /> : "SUBMIT"}
                                    </FilterButton>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProfileUpload;
