import React, { useEffect, useState } from "react";
import { CardDescription, TicketContainer } from "./style";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { policies } from "../../data/policy";
import { format } from "date-fns";
import TicketFlightDetail from "./TicketFlightDetail";
import TicketPassengerDetail from "./TicketPassengerDetail";
import axios from "axios";

const formatDateTime = (dateString) => {
      const date = new Date(dateString);

      // Format date parts
      const day = String(date.getDate()).padStart(2, "0"); // Day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (0-based index, so +1)
      const year = date.getFullYear(); // Year

      // Format time parts
      const hours = String(date.getHours()).padStart(2, "0"); // Hours
      const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes
      const seconds = String(date.getSeconds()).padStart(2, "0"); // Seconds

      // Combine into desired format
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const TicketLayout = ({
      order,
      tripInfos,
      travellerInfos,
      totalPriceInfo,
      markupPrice,
      ref,
      bookingId,
}) => {
      const [loading, setLoading] = useState(false);
      const [profile, setProfile] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProfile(response.data.agent);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <TicketContainer padding="0px" ref={ref}>
                        <div className="container my-4" id="pdf-content">
                              <h2 className="main-title text-center">
                                    Ticket Confirmation
                              </h2>
                              <div className="main-card">
                                    <div className="card-body-first mb-2">
                                          <div className="company-info">
                                                <h5 className="card-title">
                                                      {
                                                            profile?.company
                                                                  ?.company_name
                                                      }
                                                </h5>
                                                <p className="card-text">
                                                      {
                                                            profile?.company
                                                                  ?.office_address
                                                      }
                                                      ,{" "}
                                                      {profile?.company?.state}{" "}
                                                      - {profile?.pincode}
                                                      <br />
                                                      <strong>
                                                            Email:{" "}
                                                            {
                                                                  order
                                                                        ?.deliveryInfo
                                                                        ?.emails
                                                            }
                                                      </strong>
                                                      <br />
                                                      <strong>
                                                            Mobile No:{" "}
                                                            {
                                                                  order
                                                                        ?.deliveryInfo
                                                                        ?.contacts
                                                            }
                                                      </strong>
                                                </p>
                                          </div>

                                          <div className="pnr-section">
                                                <p className="airline-name">
                                                      {
                                                            tripInfos[0]?.sI[0]
                                                                  ?.fD?.aI?.name
                                                      }
                                                </p>
                                                <img
                                                      src={`/images/AirlinesLogo/${tripInfos[0]?.sI[0]?.fD?.aI?.code}.png`}
                                                      alt="IndiGo Logo"
                                                      className="airline-logo"
                                                />
                                                <div className="pnr-info">
                                                      <h6 className="pnr">
                                                            {
                                                                  travellerInfos[0]
                                                                        ?.pnrDetails[
                                                                        `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                                                                  ]
                                                            }
                                                            {/* {console.log(`${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`)} */}
                                                      </h6>
                                                      <p className="pnr-label">
                                                            Airline PNR
                                                      </p>
                                                </div>
                                          </div>

                                          <div className="reference-info">
                                                <p>
                                                      Reference Number:{" "}
                                                      <strong>
                                                            {bookingId}
                                                      </strong>
                                                </p>
                                                <p>
                                                      Issued On:{" "}
                                                      <strong>
                                                            {order &&
                                                                  formatDateTime(
                                                                        order?.createdOn
                                                                  )}
                                                      </strong>
                                                </p>
                                                {/* <img
                                                      src="/barcode.jpg"
                                                      alt="QR Code"
                                                      className="qr-code"
                                                /> */}
                                          </div>
                                    </div>

                                    {/* second table */}
                                    <TicketFlightDetail tripInfos={tripInfos} />

                                    {/* third table */}
                                    <TicketPassengerDetail
                                          order={order}
                                          tripInfos={tripInfos}
                                          travellerInfos={travellerInfos}
                                    />

                                    <div className=" mb-4">
                                          <div className="card-body">
                                                <h5 className="card-title">
                                                      Payment Details
                                                </h5>
                                                <div className="payment-details">
                                                      <div className="payment-row">
                                                            <span>
                                                                  Base Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.BF
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      <div className="payment-row">
                                                            <span>
                                                                  Taxes and Fees
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.TAF +
                                                                                    parseFloat(
                                                                                          markupPrice
                                                                                    )
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      {totalPriceInfo?.fC
                                                            ?.SSRP && (
                                                            <div className="payment-row">
                                                                  <span>
                                                                        Add Ons
                                                                  </span>
                                                                  <span className="price">
                                                                        ₹{" "}
                                                                        {convertAmount(
                                                                              convertFloat(
                                                                                    totalPriceInfo
                                                                                          ?.fC
                                                                                          ?.SSRP
                                                                              )
                                                                        )}
                                                                  </span>
                                                            </div>
                                                      )}
                                                      <div className="payment-row gross-fare">
                                                            <span>
                                                                  Gross Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.TF +
                                                                                    parseFloat(
                                                                                          markupPrice
                                                                                    )
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <CardDescription>
                                          <div className="card-body">
                                                <h5 className="card-title">
                                                      Additional Information
                                                </h5>
                                                <ul className="card-desc-content">
                                                      {policies?.map(
                                                            (item, index) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </li>
                                                            )
                                                      )}
                                                      <li>
                                                            <strong>
                                                                  Disclaimer:
                                                            </strong>{" "}
                                                            No meal will be
                                                            served on flights
                                                            that have less than
                                                            2 hours of flight
                                                            duration.
                                                      </li>
                                                </ul>
                                          </div>
                                    </CardDescription>
                                    {/* {isButtonVisible && (
                                          <button
                                                className="btn-print my-4"
                                                onClick={handlePrint}
                                          >
                                                Print
                                          </button>
                                    )} */}
                              </div>
                        </div>
                  </TicketContainer>
            </>
      );
};

export default TicketLayout;
