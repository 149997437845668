import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
      FormContainer,
      FormExtra,
      SearchFormAction,
      SearchFormWrapper,
} from "./styles";
import UnoButton from "../../../form/UnoButton";
import CustomButton from "../../../Common/CustomButton";
import ToggleButton from "../../../Common/CommonToogle";
import getCurrentDate from "../../../../hooks/CurrentData";
import { useNavigate } from "react-router-dom";
import HotelInput from "../../../form/HotelInput";
import FlightTraveler from "../FlightTraveler";
import axios from "axios";
import toast from "react-hot-toast";
import FlightInput from "../../../form/FlightInput";
import CheckTooltip from "../../../form/CheckTooltip";
import FormDate from "../../../form/FormDate";
import FormDateRange from "../../../form/FormDateRange";

const SearchForm = () => {
      const [leavingFrom, setLeavingFrom] = useState("");
      const [destination, setDestination] = useState("");
      const [depart, setDepart] = useState(getCurrentDate());
      const [returnDate, setReturnDate] = useState("");
      const [adult, setAdult] = useState(1);
      const [children, setChildren] = useState(0);
      const [className, setClassName] = useState({
            value: "ECONOMY",
            label: "Economy",
      });
      const [infants, setInfants] = useState(0);
      const [pft, setPft] = useState("REGULAR");
      const [nonStop, setNonStop] = useState(false);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const [loadingData, setLoadingData] = useState(false);
      const [destinations, setDestinations] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            if (pft === "SENIOR_CITIZEN" || pft === "STUDENT") {
                  setInfants(0);
                  setChildren(0);
                  toast.error(`You cannot select ${pft} with child/infant`);
            }

            const data = {
                  leavingFrom: leavingFrom?.code,
                  destination: destination?.code,
                  depart: depart,
                  returnDate: returnDate,
                  adult: adult,
                  children: children,
                  className: className?.value,
                  infants: infants,
                  returnStatus: active,
                  pft: pft || "REGULAR",
                  nonStop: nonStop,
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/search?${queryString}`);
            }, 3000);
      };

      const [active, setActive] = useState(false);
      const [changeDestination, setChangeDestination] = useState(false);

      return (
            <SearchFormWrapper wrap={true}>
                  <ToggleButton active={active} setActive={setActive} />

                  <Form onSubmit={handleSubmit}>
                        <FormContainer>
                              <HotelInput
                                    width="18%"
                                    label="From"
                                    options={destinations}
                                    setDestinations={setDestinations}
                                    required={true}
                                    seletedValue={true}
                                    destination={leavingFrom}
                                    setDestination={setLeavingFrom}
                                    leavingFrom={destination}
                                    placeholder="Select Origin City"
                              />
                              <UnoButton
                                    leavingFrom={leavingFrom}
                                    setLeavingFrom={setLeavingFrom}
                                    destination={destination}
                                    setDestination={setDestination}
                                    setChangeDestination={setChangeDestination}
                                    changeDestination={changeDestination}
                              />
                              <FlightInput
                                    width="18%"
                                    label="To"
                                    required={true}
                                    options={destinations}
                                    seletedValue={true}
                                    destination={destination}
                                    setDestinations={setDestinations}
                                    setDestination={setDestination}
                                    leavingFrom={leavingFrom}
                                    placeholder="Select Destination City"
                              />
                              <FormDate
                                    label="Depart"
                                    required={true}
                                    value={depart}
                                    defaultValue={new Date()}
                                    onChange={setDepart}
                                    returnDate={returnDate}
                                    setReturnDate={setReturnDate}
                                    width="13%"
                                    returnStatus={true}
                              />
                              <FormDateRange
                                    label="Return"
                                    required={active}
                                    defaultValue={depart}
                                    value={returnDate}
                                    onChange={setReturnDate}
                                    width="13%"
                                    returnStatus={active}
                              />
                              <FlightTraveler
                                    label="Travellers & Class"
                                    children={children}
                                    setChildren={setChildren}
                                    adult={adult}
                                    setAdult={setAdult}
                                    infant={infants}
                                    setInfant={setInfants}
                                    className={className}
                                    setClassName={setClassName}
                                    width="15%"
                                    state={true}
                              />
                              <SearchFormAction>
                                    <label style={{ visibility: "hidden" }}>
                                          Submit
                                    </label>
                                    <CustomButton
                                          type={"submit"}
                                          className="mt-3"
                                          loading={loading}
                                          title={"Search Flights"}
                                    />
                              </SearchFormAction>
                        </FormContainer>
                        <FormExtra
                              justifyContent="space-between"
                              border={`1px dashed`}
                        >
                              <FormExtra width="auto">
                                    <CheckTooltip
                                          placement="bottom"
                                          errorMessage="Only students above 12
                                                      years of age are eligible
                                                      for special fares and/or
                                                      additional baggage
                                                      allowances. Carrying valid
                                                      student ID cards and
                                                      student visas (where
                                                      applicable) is mandatory,
                                                      else the passenger may be
                                                      denied boarding or asked
                                                      to pay for extra baggage."
                                    >
                                          <div>
                                                <Form.Check
                                                      type="checkbox"
                                                      label="Student"
                                                      className="mt-2"
                                                      checked={
                                                            pft === "STUDENT"
                                                                  ? true
                                                                  : false
                                                      }
                                                      value="STUDENT"
                                                      disabled={
                                                            infants !== 0 ||
                                                            children !== 0
                                                                  ? true
                                                                  : false
                                                      }
                                                      onChange={(event) =>
                                                            setPft(
                                                                  event.target
                                                                        .checked
                                                                        ? event
                                                                                .target
                                                                                .value
                                                                        : ""
                                                            )
                                                      }
                                                />
                                          </div>
                                    </CheckTooltip>

                                    <CheckTooltip
                                          placement="bottom"
                                          errorMessage="Only senior citizens above the age of 60 years can avail this special fare. It is mandatory to produce proof of Date of Birth at the airport, without which prevailing fares will be charged."
                                    >
                                          <div>
                                                <Form.Check
                                                      type="checkbox"
                                                      label="Senior Citizen"
                                                      value="SENIOR_CITIZEN"
                                                      className="mt-2"
                                                      disabled={
                                                            infants !== 0 ||
                                                            children !== 0
                                                                  ? true
                                                                  : false
                                                      }
                                                      checked={
                                                            pft ===
                                                            "SENIOR_CITIZEN"
                                                                  ? true
                                                                  : false
                                                      }
                                                      onChange={(event) =>
                                                            setPft(
                                                                  event.target
                                                                        .checked
                                                                        ? event
                                                                                .target
                                                                                .value
                                                                        : ""
                                                            )
                                                      }
                                                />
                                          </div>
                                    </CheckTooltip>
                              </FormExtra>
                              <Form.Check
                                    type="checkbox"
                                    label="Non-Stop"
                                    className="mt-2"
                                    onChange={(event) =>
                                          setNonStop(event.target.checked)
                                    }
                              />
                        </FormExtra>
                  </Form>
            </SearchFormWrapper>
      );
};

export default SearchForm;
